<template>
  <el-dialog v-model="show" title="Edit Coupon" @opened="onOpened">
    <el-form ref="updateCouponInfoForm" :model="updateCouponInfo" label-width="120px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Name" prop="name" required>
				<el-input v-model="updateCouponInfo.name"></el-input>
			</el-form-item>
			<el-form-item label="Description" prop="description" required>
				<el-input v-model="updateCouponInfo.description"></el-input>
			</el-form-item>
			<el-form-item label="Type" prop="type" required>
				<el-radio-group v-model="updateCouponInfo.type">
					<el-radio v-for="option in CouponTypeOptions.options" :key="option.label" :label="option.value">{{ option.label }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item v-if="updateCouponInfo.type === Type.SPECIFIC" label="Recharge Card" prop="rechargeCardId" required>
				<el-select v-model="updateCouponInfo.rechargeCardId">
					<el-option v-for="rechargeCard in rechargeCards" :key="rechargeCard.id" :label="rechargeCard.name" :value="rechargeCard.id"></el-option>
				</el-select>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateCouponInfo">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import couponAPI from '../../api/coupon'
import rechargeCardAPI from '../../api/rechargeCard'
import { CouponTypeOptions } from '../../types//common/option/couponOptions'
import { Result } from '../../types/common/result'
import { Coupon, Type, UpdateCouponInfo, UpdateCouponInfoImpl } from '../../types/coupon'
import { RechargeCard } from '../../types/rechargeCard'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	coupon: Coupon
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

let updateCouponInfo = reactive<UpdateCouponInfo>(new UpdateCouponInfoImpl(props.coupon))

const updateCouponInfoForm = ref<InstanceType<typeof ElForm>>()

const doUpdateCouponInfo = function () {
	updateCouponInfoForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			couponAPI.updateInfo(updateCouponInfo).then((response: AxiosResponse<Result<Coupon>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const rechargeCards = ref<RechargeCard[]>()
const doFindAllRechargeCard = function () {
	rechargeCardAPI.findAll().then((response: AxiosResponse<Result<RechargeCard[]>>) => {
		const result = response.data
		if (result.success) {
			rechargeCards.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const onOpened = function () {
	doFindAllRechargeCard()
}
</script>