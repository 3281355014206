import { getFullMobile } from "../utils/mobileUtils"

export interface Agent {
	id: string
	name?: string
	region?: string
	countryCode?: string
	mobile?: string
	fullMobile?: string
	locale?: string
	zoneId?: string
	promoteCode?: string
	balance?: number
	totalEarning?: number
	totalWithdraw?: number
	poster?: string
	locked?: boolean
	remark?: string
	createdDateTime?: Date

	parentAgentId?: string
	parentAgentName?: string
}

export class AgentImpl implements Agent {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface NanoAgent {
	id: string
	name: string
}

export interface CreateAgent {
	name?: string
	region?: string
	countryCode?: string
	mobile?: string
	fullMobile?: string
	locale?: string
	zoneId?: string
}

export class CreateAgentImpl implements CreateAgent {

}

export interface ToggleAgentLocked {
	id: string
}

export class ToggleAgentLockedImpl implements ToggleAgentLocked {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface UpdateAgentDetailInfo {
	id: string
	name?: string
	region?: string
	countryCode?: string
	mobile?: string
	fullMobile?: string
}

export class UpdateAgentDetailInfoImpl implements UpdateAgentDetailInfo {
	id: string
	name?: string
	region?: string
	countryCode?: string
	mobile?: string
	fullMobile?: string

	constructor(agent: Agent) {
		this.id = agent.id
		this.name = agent.name
		this.region = agent.region
		this.countryCode = agent.countryCode
		this.mobile = agent.mobile
		this.fullMobile = getFullMobile(agent.countryCode, agent.mobile)
	}
}

export interface UpdateAgentRemark {
	id: string
	remark?: string
}

export class UpdateAgentRemarkImpl implements UpdateAgentRemark {
	id: string
	remark?: string

	constructor(agent: Agent) {
		this.id = agent.id
		this.remark = agent.remark
	}
}

export interface GenerateAgentPoster {
  id: string
}

export class GenerateAgentPosterImpl implements GenerateAgentPoster {
  id: string

  constructor(id: string) {
    this.id = id
  }
}