<template>
  <el-dialog v-model="show" title="Create Coupon" @opened="onOpened">
    <el-form ref="createCouponForm" :model="createCoupon" label-width="120px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Name" prop="name" required>
				<el-input v-model="createCoupon.name"></el-input>
			</el-form-item>
			<el-form-item label="Description" prop="description" required>
				<el-input v-model="createCoupon.description"></el-input>
			</el-form-item>
			<el-form-item label="Type" prop="type" required>
				<el-radio-group v-model="createCoupon.type">
					<el-radio v-for="option in CouponTypeOptions.options" :key="option.label" :label="option.value">{{ option.label }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item v-if="createCoupon.type === Type.SPECIFIC" label="Recharge Card" prop="rechargeCardId" required>
				<el-select v-model="createCoupon.rechargeCardId">
					<el-option v-for="rechargeCard in rechargeCards" :key="rechargeCard.id" :label="rechargeCard.name" :value="rechargeCard.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Value" prop="value" required>
				<el-input-number v-model="createCoupon.value" :precision="0" :step="1" :min="1" :max="1000" style="width: 190px" />
			</el-form-item>
			<el-form-item label="Period (Day)" prop="period" required>
				<el-input-number v-model="createCoupon.period" :precision="0" :step="1" :min="1" :max="365" style="width: 190px" />
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doCreateCoupon">Create</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import couponAPI from '../../api/coupon'
import rechargeCardAPI from '../../api/rechargeCard'
import { CouponTypeOptions } from '../../types/common/option/couponOptions'
import { Result } from '../../types/common/result'
import { Coupon, CreateCoupon, CreateCouponImpl, Type } from '../../types/coupon'
import { RechargeCard } from '../../types/rechargeCard'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const createCoupon = reactive<CreateCoupon>(new CreateCouponImpl())

const createCouponForm = ref<InstanceType<typeof ElForm>>()

const doCreateCoupon = function () {
	createCouponForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			couponAPI.create(createCoupon).then((response: AxiosResponse<Result<Coupon>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					createCouponForm.value?.resetFields()
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				message.value = error.message
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const rechargeCards = ref<RechargeCard[]>()
const doFindAllRechargeCard = function () {
	rechargeCardAPI.findAll().then((response: AxiosResponse<Result<RechargeCard[]>>) => {
		const result = response.data
		if (result.success) {
			rechargeCards.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const onOpened = function () {
	doFindAllRechargeCard()
}
</script>