<template>
  <el-dialog v-model="show" title="Finish Arrangement">
    <el-form ref="finishArrangementForm" :model="finishArrangement" label-width="100px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Star" prop="star" required>
				<el-rate v-model="finishArrangement.star" id="star" name="star" required></el-rate>
			</el-form-item>
			<el-form-item label="Score" prop="lessonTestScore" required v-if="arrangement.lessonType === LessonType.TEST">
				<el-input-number v-model="finishArrangement.lessonTestScore" :min="0" :max="arrangement.lessonTotalTestScore" style="margin-right: 10px;" />
				<span>Total Score: {{arrangement.lessonTotalTestScore}}</span>
			</el-form-item>
			<el-form-item label="Remark" prop="remark">
				<el-input v-model="finishArrangement.remark" :rows="5" type="textarea" />
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doFinishArrangement">Finish</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import arrangementAPI from '../../api/arrangement'
import { Arrangement, FinishArrangement, FinishArrangementImpl } from '../../types/arrangement'
import { Result } from '../../types/common/result'
import { Type as LessonType } from '../../types/lesson'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	arrangement: Arrangement
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const finishArrangement = reactive<FinishArrangement>(new FinishArrangementImpl(props.arrangement))

const finishArrangementForm = ref<InstanceType<typeof ElForm>>()

const doFinishArrangement = function () {
	finishArrangementForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			arrangementAPI.finish(finishArrangement).then((response: AxiosResponse<Result<Arrangement>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>