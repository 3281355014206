<template>
  <el-dialog v-model="show" title="Reject Withdraw">
    <el-form ref="rejectWithdrawForm" :model="rejectWithdraw" label-width="150px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Remark" prop="remark" required>
				<el-input v-model="rejectWithdraw.remark"></el-input>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doRejectWithdraw">Reject</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElAlert, ElButton, ElDialog, ElForm, ElFormItem, ElInput, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import withdrawAPI from '../../api/withdraw'
import { Result } from '../../types/common/result'
import { RejectWithdraw, RejectWithdrawImpl, Withdraw } from '../../types/withdraw'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	withdraw: Withdraw
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
	(e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const rejectWithdraw = reactive<RejectWithdraw>(new RejectWithdrawImpl(props.withdraw))
const rejectWithdrawForm = ref<InstanceType<typeof ElForm>>()

const doRejectWithdraw = function () {
	rejectWithdrawForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			withdrawAPI.reject(rejectWithdraw).then((response: AxiosResponse<Result<Withdraw>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>