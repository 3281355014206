import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { SimpleTeacher, Teacher, ToggleTeacherLocked, ToggleTeacherCertified, ToggleTeacherVendor, CreateTeacher, ToggleTeacherReady, ToggleTeacherHidden, CreateSupportTeacher, UpdateTeacherWageBase, UpdateTeacherDetailInfo, UpdateTeacherFee, UpdateTeacherZoomAccount, NanoTeacher } from "../types/teacher"
import service from "./api-service"

const api = '/teacher'

const teacherAPI = {
  uploadAvatar: import.meta.env.VITE_API_URL + api + '/uploadAvatar',
  uploadShow: import.meta.env.VITE_API_URL + api + '/uploadShow',

  list (query: Query) {
    return service.post<Result<ListObject<SimpleTeacher>>>(api + '/list', query)
  },

  findAll () {
    return service.get<Result<NanoTeacher[]>>(api + '/findAll')
  },
  
  findById (id: string) {
    return service.get<Result<Teacher>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  findReady () {
    return service.get<Result<Teacher[]>>(api + '/findReady')
  },

  create (createTeacher: CreateTeacher) {
    return service.post<Result<Teacher>>(api, createTeacher)
  },

  updateDetailInfo (updateTeacherDetailInfo: UpdateTeacherDetailInfo) {
    return service.put<Result<Teacher>>(api + '/updateDetailInfo', updateTeacherDetailInfo)
  },

  updateZoomAccount (updateTeacherZoomAccount: UpdateTeacherZoomAccount) {
    return service.put<Result<Teacher>>(api + '/updateZoomAccount', updateTeacherZoomAccount)
  },

  createSupport (createSupportTeacher: CreateSupportTeacher) {
    return service.post<Result<Teacher>>(api + '/support', createSupportTeacher)
  },

  toggleCertified (toggleTeacherCertified: ToggleTeacherCertified) {
    return service.put<Result<Teacher>>(api + '/toggleCertified', toggleTeacherCertified)
  },

  toggleVendor (toggleTeacherVendor: ToggleTeacherVendor) {
    return service.put<Result<Teacher>>(api + '/toggleVendor', toggleTeacherVendor)
  },

  toggleReady (toggleTeacherReady: ToggleTeacherReady) {
    return service.put<Result<Teacher>>(api + '/toggleReady', toggleTeacherReady)
  },

  toggleHidden (toggleTeacherHidden: ToggleTeacherHidden) {
    return service.put<Result<Teacher>>(api + '/toggleHidden', toggleTeacherHidden)
  },

  toggleLocked (toggleTeacherLocked: ToggleTeacherLocked) {
    return service.put<Result<Teacher>>(api + '/toggleLocked', toggleTeacherLocked)
  },

  updateWageBase (updateTeacherWageBase: UpdateTeacherWageBase) {
    return service.put<Result<Teacher>>(api + '/updateWageBase', updateTeacherWageBase)
  },

  updateFee (updateTeacherFee: UpdateTeacherFee) {
    return service.put<Result<Teacher>>(api + '/updateFee', updateTeacherFee)
  }
}

export default teacherAPI