<template>
  <div class="timeSlots">
    <it-time-slot v-for="(timeSlot, index) in timeSlots" :key="index" :time-slot="timeSlot" @click="doToggleTimeSlotBooked(timeSlot)"></it-time-slot>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import teachingTimeSlotsAPI from '../../../api/teachingTimeSlots'
import { Result } from '../../../types/common/result'
import { Status, TimeSlot, ToggleTimeSlotBookedImpl } from '../../../types/teachingTimeSlots'
import { cloneFrom } from '../../../utils/clone'
import ItTimeSlot from './ItTimeSlot.vue'

const props = defineProps<{
  teachingTimeSlotsId: string,
  timeSlots: TimeSlot[],
  enrollmentId?: string
}>()

const doToggleTimeSlotBooked = function(timeSlot: TimeSlot) {
  if (props.enrollmentId) {
    if (timeSlot.status === Status.AVAILABLE || timeSlot.status === Status.BOOKED) {
      const toggleTimeSlotBooked = new ToggleTimeSlotBookedImpl(props.teachingTimeSlotsId, props.enrollmentId, timeSlot.dayOfWeek, timeSlot.index)
      teachingTimeSlotsAPI.toggleTimeSlotBooked(toggleTimeSlotBooked).then((response: AxiosResponse<Result<TimeSlot>>) => {
        const result = response.data
        if (result.success) {
          cloneFrom(timeSlot, result.data)
        } else {
          ElMessage.error(result.message)
        }						
      }).catch((error: AxiosError) => {
        ElMessage.error(error.message)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.timeSlots {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 10px;
  column-gap: 10px;
}
</style>
