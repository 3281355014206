import { Result } from "../types/common/result"
import { StudentAvatar, CreateStudentAvatar } from "../types/studentAvatar"
import service from "./api-service"

const api = '/studentAvatar'

const studentAvatarAPI = {
  uploadImage: import.meta.env.VITE_API_URL + api + '/uploadImage',

  findAll () {
    return service.get<Result<StudentAvatar[]>>(api + '/findAll')
  },
  
  findById (id: string) {
    return service.get<Result<StudentAvatar>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  create (createStudentAvatar: CreateStudentAvatar) {
    return service.post<Result<StudentAvatar>>(api, createStudentAvatar)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default studentAvatarAPI