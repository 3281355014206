import { Type } from "../../../coupon"
import { Filters } from "./index"

export interface CouponFilters extends Filters {
	published?: boolean
	type?: Type
}

export class CouponFiltersImpl implements CouponFilters {
	published?: boolean
	type?: Type

	reset(): void {
		this.published = undefined
		this.type = undefined
	}
}