export interface RechargeCard {
	id: string
	name?: string
	price?: number
	discountPrice?: number
	lessons?: number
	recommended?: boolean
	hot?: boolean
	published?: boolean
	stripeProductId?: string
}

export class RechargeCardImpl implements RechargeCard {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface CreateRechargeCard {
	name?: string
	lessons?: number
	price?: number
	discountPrice?: number
	recommended?: boolean
	hot?: boolean
}

export class CreateRechargeCardImpl implements CreateRechargeCard {
	recommended = false
	hot = false
}

export interface UpdateRechargeCardInfo {
	id: string
	name?: string
	lessons?: number
	price?: number
	discountPrice?: number
	recommended?: boolean
	hot?: boolean
}

export class UpdateRechargeCardInfoImpl implements UpdateRechargeCardInfo {
	id: string
	name?: string
	lessons?: number
	price?: number
	discountPrice?: number
	recommended?: boolean
	hot?: boolean

	constructor(rechargeCard: RechargeCard) {
		this.id = rechargeCard.id
		this.name = rechargeCard.name
		this.lessons = rechargeCard.lessons
		this.price = rechargeCard.price
		this.discountPrice = rechargeCard.discountPrice
		this.recommended = rechargeCard.recommended
		this.hot = rechargeCard.hot
	}
}

export interface ToggleRechargeCardPublished {
	id: string
}

export class ToggleRechargeCardPublishedImpl implements ToggleRechargeCardPublished {
	id: string

	constructor(id: string) {
		this.id = id
	}
}