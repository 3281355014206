<template>
  <el-dialog v-model="show" title="Update Enrollment" @opened="onOpened">
    <el-form ref="updateEnrollmentInfoForm" :model="updateEnrollmentInfo" label-width="180px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Latest Booked Lesson" prop="latestBookedLessonId" required>
				<el-select v-model="updateEnrollmentInfo.latestBookedLessonId" filterable style="width: 100%">
					<el-option v-for="lesson in lessons" :key="lesson.id" :label="lesson.fullName" :value="lesson.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Latest Finished Lesson" prop="latestFinishedLessonId" required>
				<el-select v-model="updateEnrollmentInfo.latestFinishedLessonId" filterable style="width: 100%">
					<el-option v-for="lesson in lessons" :key="lesson.id" :label="lesson.fullName" :value="lesson.id"></el-option>
				</el-select>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateEnrollmentInfo">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import enrollmentAPI from '../../api/enrollment'
import lessonAPI from '../../api/lesson'
import { Result } from '../../types/common/result'
import { Enrollment, UpdateEnrollmentInfo, UpdateEnrollmentInfoImpl } from '../../types/enrollment'
import { Lesson } from '../../types/lesson'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	enrollment: Enrollment
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

// Find Lessons
const lessons = ref<Lesson[]>()
const doFindLessons = function (courseId: string) {
	lessonAPI.findByCourseId(courseId).then((response: AxiosResponse<Result<Lesson[]>>) => {
		const result = response.data
		if (result.success) {
			lessons.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const updateEnrollmentInfo = reactive<UpdateEnrollmentInfo>(new UpdateEnrollmentInfoImpl(props.enrollment))
const updateEnrollmentInfoForm = ref<InstanceType<typeof ElForm>>()

const doUpdateEnrollmentInfo = function () {
	updateEnrollmentInfoForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			enrollmentAPI.updateInfo(updateEnrollmentInfo).then((response: AxiosResponse<Result<Enrollment>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const onOpened = function () {
	doFindLessons(props.enrollment.courseId!)
}
</script>