<template>
  <div style="display: flex; flex-direction: column; align-items: center;">
    <el-image :src="studentAvatar.url" fit="contain" style="width: 100px; height: 100px;"></el-image>

    <el-popconfirm title="Are you sure to delete the student avatar?" @confirm="doDeleteStudentAvatar">
      <template #reference>
        <el-button text type="primary">delete</el-button>
      </template>
    </el-popconfirm>
  </div>
</template>

<script setup lang="ts">
import { StudentAvatar } from '../../types/studentAvatar';

const props = defineProps<{
  studentAvatar: StudentAvatar
}>()

const emit = defineEmits<{
  (e: 'delete', id: string): void
}>()

const doDeleteStudentAvatar = function () {
  emit('delete', props.studentAvatar.id)
}
</script>
