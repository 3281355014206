import { Option, OptionImpl } from "."

export class RechargeCardRecommendedOptions {
	static options: Option[] = [
		new OptionImpl('Recommended', true),
		new OptionImpl('Normal', false)
	]
}

export class RechargeCardHotOptions {
	static options: Option[] = [
		new OptionImpl('Hot', true),
		new OptionImpl('Normal', false)
	]
}

export class RechargeCardPublishedOptions {
	static options: Option[] = [
		new OptionImpl('Published', true),
		new OptionImpl('Draft', false)
	]
}