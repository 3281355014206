<template>
  <el-container class="info">
    <el-aside v-loading="loading" class="aside">
      <it-info-items title="Basic Info">
        <template #actions>
          <el-button text type="primary" class="action-button" @click="showUpdateStaffInfoDialog = true">Edit</el-button>
        </template>
        <it-info-item label="Name">{{ staff.name }}</it-info-item>
        <it-info-item label="Email">{{ staff.email }}</it-info-item>
        <it-info-item label="Role">{{ StaffFilter.role(staff.role) }}</it-info-item>
        <it-info-item label="Status">
          <div>{{ StaffFilter.locked(staff.locked) }}</div>
          <el-popconfirm :title="staff.locked? 'Are you sure to unlock the staff?' : 'Are you sure to lock the staff?'" @confirm="doToggleStaffLocked">
            <template #reference>
              <el-button text type="primary" class="action-button">{{ staff.locked? 'Unlock' : 'Lock' }}</el-button>
            </template>
          </el-popconfirm>
        </it-info-item>
      </it-info-items>
      <el-popconfirm title="Are you sure to delete the staff?" @confirm="doDeleteStaff">
        <template #reference>
          <el-button type="danger" style="width: 100%;">Delete</el-button>
        </template>
      </el-popconfirm>
    </el-aside>
    <el-main class="main">
      <el-tabs v-model="tab">
        
      </el-tabs>
    </el-main>
  </el-container>

  <update-staff-info-dialog v-if="showUpdateStaffInfoDialog" v-model:visible="showUpdateStaffInfoDialog" :staff="staff" @success="onUpdateStaffInfoSuccess"></update-staff-info-dialog>
  
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import staffAPI from '../../api/staff'
import UpdateStaffInfoDialog from '../../components/staff/UpdateStaffInfoDialog.vue'
import ItInfoItem from '../../components/ui/ItInfoItem.vue'
import ItInfoItems from '../../components/ui/ItInfoItems.vue'
import { StaffFilter } from '../../filters/staffFilter'
import { Result } from '../../types/common/result'
import { Staff, StaffImpl, ToggleStaffLocked, ToggleStaffLockedImpl } from '../../types/staff'
import { cloneFrom } from '../../utils/clone'

const route = useRoute()
const staffId = route.params.id as string
const router = useRouter()

const loading = ref<boolean>(false)

const tab = ref<string>('parents')

const staff = reactive<Staff>(new StaffImpl(staffId))

const doFindStaff = function() {
  loading.value = true
  staffAPI.findById(staffId).then((response: AxiosResponse<Result<Staff>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(staff, result.data)
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Edit staff info
const showUpdateStaffInfoDialog = ref<boolean>(false)
const onUpdateStaffInfoSuccess = function () {
  ElMessage.success('Success to edit')
  doFindStaff()
}

// Lock/Unlock staff
const doToggleStaffLocked = function () {
  const toggleStaffLocked: ToggleStaffLocked = new ToggleStaffLockedImpl(staffId)
  loading.value = true
  staffAPI.toggleLocked(toggleStaffLocked).then((response: AxiosResponse<Result<Staff>>) => {
    const result = response.data
    if (result.success) {
      const staff = result.data
      ElMessage.success(staff.locked? 'Success to lock' : 'Success to unlock')
      doFindStaff()
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Delete staff
const doDeleteStaff = function () {
  loading.value = true
  staffAPI.deleteById(staffId).then((response: AxiosResponse<Result<void>>) => {
    const result = response.data
    if (result.success) {
      ElMessage.success('Success to delete')
      router.go(-1)
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  doFindStaff()
})
</script>