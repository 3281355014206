import { Filters } from "./index"

export interface AgentFilters extends Filters {
	locked?: boolean
}

export class AgentFiltersImpl implements AgentFilters {
	locked?: boolean

	reset(): void {
		this.locked = undefined
	}
}