import axios, { AxiosRequestConfig, AxiosInstance } from "axios"

const config: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_AUTH_URL,
  timeout: 10000,
  responseType: 'json',
  auth: {
    username: 'api-service',
    password: 'secret'
  },
  params: {
    realm: 'staff',
    type: 'password'
  }
}

const service: AxiosInstance = axios.create(config)

export default service