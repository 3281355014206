import { Level } from "../types/teacher"
import { toFirstUpperCase } from "../utils/stringUtils"

const displayNames = new Intl.DisplayNames(
  ['en'], {type: 'region'}
)

export class TeacherFilter {
  static locked (locked?: boolean): string {
    if (locked == undefined) return ''
    else return locked? 'Locked' : 'Normal'
  }

  static ready (ready?: boolean): string {
    if (ready == undefined) return ''
    else return ready? 'Ready' : 'Preparing'
  }

  static hidden (hidden?: boolean): string {
    if (hidden == undefined) return ''
    else return hidden? 'Hidden' : 'Visible'
  }

  static complete (complete?: boolean): string {
    if (complete == undefined) return ''
    else return complete? 'Complete' : 'Uncomplete'
  }

  static certified (certified?: boolean): string {
    if (certified == undefined) return ''
    else return certified? 'Certified' : 'Uncertified'
  }

  static vendor (vendor?: boolean): string {
    if (vendor == undefined) return ''
    else return vendor? 'Vendor' : 'Common'
  }

  static fullName (firstName?: string, lastName?: string): string {
    return firstName + ' ' + lastName
  }

  static level (level?: Level): string {
    return toFirstUpperCase(level?.toString())
  }

  static country (country?: string): string {
    if (country) {
      return displayNames.of(country) ?? ''
    } else {
      return ''
    }
  }
}