import { Type } from "../../../parent"
import { Filters } from "./index"

export interface ParentFilters extends Filters {
	type?: Type
	locked?: boolean
	courseServiceId?: string
}

export class ParentFiltersImpl implements ParentFilters {
	type?: Type
	locked?: boolean
	courseServiceId?: string

	reset(): void {
		this.type = undefined
		this.locked = undefined
		this.courseServiceId = undefined
	}
}