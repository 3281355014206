<template>
  <div>
    <board v-if="isLogin"></board>
    <login v-else></login>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from './store';
import Board from './views/Board.vue';
import Login from './views/Login.vue';

const store = useStore()

const isLogin = computed(() => {
  return store.getters.isLogin
})
</script>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
  background-color: whitesmoke;
}

.query {
  margin-top: 15px;
}

.link {
  padding: 10px;
}

.message {
  margin-bottom: 10px;
}

.form-message {
  margin-bottom: 20px;
}

.table {
  width: 100%;
}

.action-button {
  padding: 0 5px;
  line-height: 0;
  min-height: 34px;
}

.group-button {
  flex: auto;
}

.info {
  min-height: 100vh;

  .aside {
    width: 400px;
    border-right: 1px solid gainsboro;
    min-height: 100vh;
    padding: 0 20px 20px 0;
  }
  .main {
    padding: 0 0 20px 20px;
  }
}

.drawer {
  width: 100% !important;
}

.el-drawer__header {
  margin-bottom: 0;
  padding: 18px 20px;
  border-bottom: 1px solid gainsboro;
}

.el-form-item.is-error .el-input-group__prepend .el-select .el-input {
	border: 1px solid var(--el-color-danger);
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.el-form--inline .el-form-item {
  margin-right: 10px;
}
</style>