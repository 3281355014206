import { AcquiredCoupon, CreateAcquiredCoupon, CreateAcquiredCouponForAll } from "../types/acquiredCoupon"
import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import service from "./api-service"

const api = '/acquiredCoupon'

const acquiredCouponAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<AcquiredCoupon>>>(api + '/list', query)
  },

  create (createAcquiredCoupon: CreateAcquiredCoupon) {
    return service.post<Result<AcquiredCoupon>>(api, createAcquiredCoupon)
  },

  createForAll (createAcquiredCouponForAll: CreateAcquiredCouponForAll) {
    return service.post<Result<void>>(api + '/forAll', createAcquiredCouponForAll)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default acquiredCouponAPI