<template>
  <el-dialog v-model="show" title="Edit Parent" @opened="onOpened">
    <el-form ref="updateParentDetailInfoForm" :model="updateParentDetailInfo" label-width="100px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Name" prop="name" required>
				<el-input v-model="updateParentDetailInfo.name"></el-input>
			</el-form-item>
			<el-form-item label="Nickname" prop="nickname" required>
				<el-input v-model="updateParentDetailInfo.nickname"></el-input>
			</el-form-item>
			<el-form-item label="Mobile" prop="fullMobile">
				<el-input v-model="updateParentDetailInfo.mobile" class="input-with-select" @blur="onMobileInputBlur">
					<template #prepend>
						<el-select v-model="updateParentDetailInfo.region" placeholder="Country Code" style="width: 190px;" filterable @change="onRegionSelectChange">
							<el-option v-for="phoneCountryCode in phoneCountryCodes" :key="phoneCountryCode.region" :label="phoneCountryCode.country" :value="phoneCountryCode.region"></el-option>
						</el-select>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item label="Email" prop="email">
				<el-input v-model="updateParentDetailInfo.email"></el-input>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateParentDetailInfo">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import mobileAPI from '../../api/mobile'
import parentAPI from '../../api/parent'
import { Result } from '../../types/common/result'
import { PhoneCountryCode } from '../../types/mobile'
import { Parent, UpdateParentDetailInfo, UpdateParentDetailInfoImpl } from '../../types/parent'
import { getCountryCodeFromRegion, getFullMobile } from '../../utils/mobileUtils'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	parent: Parent
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

// phone country code
const phoneCountryCodes = ref<PhoneCountryCode[]>()
const doFindAllPhoneCountryCodes = function () {
	mobileAPI.findAllCountryCode().then((response: AxiosResponse<Result<PhoneCountryCode[]>>) => {
		const result = response.data
		if (result.success) {
			phoneCountryCodes.value = result.data
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

let updateParentDetailInfo = reactive<UpdateParentDetailInfo>(new UpdateParentDetailInfoImpl(props.parent))

const updateParentDetailInfoForm = ref<InstanceType<typeof ElForm>>()

const doUpdateParentDetailInfo = function () {
	updateParentDetailInfoForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			parentAPI.updateDetailInfo(updateParentDetailInfo).then((response: AxiosResponse<Result<Parent>>) => {
				const result: Result<Parent> = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const onRegionSelectChange = function () {
	setFullMobile()
}

const onMobileInputBlur = function () {
	setFullMobile()
}

const setFullMobile = function () {
	updateParentDetailInfo.countryCode = getCountryCodeFromRegion(phoneCountryCodes.value, updateParentDetailInfo.region)
	updateParentDetailInfo.fullMobile = getFullMobile(updateParentDetailInfo.countryCode, updateParentDetailInfo.mobile)
}

const onOpened = function () {
	doFindAllPhoneCountryCodes()
}
</script>