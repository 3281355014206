import { Category, Method, Status, Type } from "../../../recharge"
import { Filters } from "./index"

export interface RechargeFilters extends Filters {
	parentId?: string
	status?: Status
	type?: Type
	category?: Category
	method?: Method
	rechargeCardId?: string
}

export class RechargeFiltersImpl implements RechargeFilters {
	parentId?: string
	status?: Status
	type?: Type
	category?: Category
	method?: Method
	rechargeCardId?: string

	reset(): void {
		this.parentId = undefined
		this.status = undefined
		this.type = undefined
		this.category = undefined
		this.method = undefined
		this.rechargeCardId = undefined
	}
}