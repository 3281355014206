<template>
  <el-dialog v-model="show" title="Create Vacation" @opened="onOpened">
    <el-form ref="createVacationForm" :model="createVacation" label-width="80px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Teacher" prop="teacherId" required>
				<el-select v-model="createVacation.teacherId" filterable style="width: 220px;" @change="onSelectTeacher">
					<el-option v-for="teacher in teachers" :key="teacher.id" :label="teacher.nickname" :value="teacher.id"></el-option>
				</el-select>
				<span v-if="teacher" style="margin-left: 10px;">Time Zone: {{teacher.zoneId}}</span>
			</el-form-item>
			<el-form-item label="Date" prop="dateTime" required>
				<!-- <el-date-picker v-model="createVacation.dateTime" :clearable="false" type="date"></el-date-picker> -->
			</el-form-item>
			<el-form-item label="Remark" prop="remark">
				<el-input v-model="createVacation.remark" type="textarea" :rows="5"></el-input>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doCreateVacation">Create</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref, toRef } from 'vue'
import teacherAPI from '../../api/teacher'
import vacationAPI from '../../api/vacation'
import { Result } from '../../types/common/result'
import { Teacher } from '../../types/teacher'
import { CreateVacation, CreateVacationImpl, Vacation } from '../../types/vacation'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	teacher?: Teacher
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

// Find Teachers
const selectedTeacher = toRef(props, 'teacher')
const teachers = ref<Teacher[]>()
const doFindTeachers = function () {
	teacherAPI.findReady().then((response: AxiosResponse<Result<Teacher[]>>) => {
		const result = response.data
		if (result.success) {
			teachers.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}
const onSelectTeacher = function () {
	selectedTeacher.value = teachers.value?.filter(teacher => teacher.id === createVacation.teacherId)[0]
}

const createVacation = reactive<CreateVacation>(new CreateVacationImpl(selectedTeacher.value?.id))

const createVacationForm = ref<InstanceType<typeof ElForm>>()

const doCreateVacation = function () {
	createVacationForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true

			// 转换到老师时区
			createVacation.dateTime = createVacation.dateTime!.setZone(selectedTeacher.value?.zoneId).set({hour: 0, minute: 0, second: 0, millisecond: 0})

			console.log(createVacation)

			vacationAPI.create(createVacation).then((response: AxiosResponse<Result<Vacation>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					createVacationForm.value?.resetFields()
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				message.value = error.message
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const onOpened = function () {
	doFindTeachers()
}
</script>