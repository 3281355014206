<template>
  <el-dialog v-model="show" title="Edit Recharge Card">
    <el-form ref="updateRechargeCardInfoForm" :model="updateRechargeCardInfo" label-width="150px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Name" prop="name" required>
				<el-input v-model="updateRechargeCardInfo.name"></el-input>
			</el-form-item>
			<el-form-item label="Lessons" prop="lessons" required>
				<el-input-number v-model="updateRechargeCardInfo.lessons" :step="1" :min="1" :max="1000" />
			</el-form-item>
			<el-form-item label="Price ($)" prop="price" required>
				<el-input-number v-model="updateRechargeCardInfo.price" :precision="2" :step="1" :min="0" :max="10000" />
			</el-form-item>
			<el-form-item label="Discount Price ($)" prop="discountPrice" required>
				<el-input-number v-model="updateRechargeCardInfo.discountPrice" :precision="2" :step="1" :min="0" :max="10000" />
			</el-form-item>
			<el-form-item label="Recommended" prop="recommended" required>
				<el-switch v-model="updateRechargeCardInfo.recommended" />
			</el-form-item>
			<el-form-item label="Hot" prop="hot" required>
				<el-switch v-model="updateRechargeCardInfo.hot" />
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateRechargeCardInfo">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import rechargeCardAPI from '../../api/rechargeCard'
import { Result } from '../../types/common/result'
import { RechargeCard, UpdateRechargeCardInfo, UpdateRechargeCardInfoImpl } from '../../types/rechargeCard'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	rechargeCard: RechargeCard
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

let updateRechargeCardInfo = reactive<UpdateRechargeCardInfo>(new UpdateRechargeCardInfoImpl(props.rechargeCard))

const updateRechargeCardInfoForm = ref<InstanceType<typeof ElForm>>()

const doUpdateRechargeCardInfo = function () {
	updateRechargeCardInfoForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			rechargeCardAPI.updateInfo(updateRechargeCardInfo).then((response: AxiosResponse<Result<RechargeCard>>) => {
				const result: Result<RechargeCard> = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>