<template>
  <el-dialog v-model="show" title="Create Refund">
    <el-form ref="createRefundForm" :model="createRefund" label-width="120px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Lessons" prop="lessons" required>
				<el-input-number v-model="createRefund.lessons" :min="1"></el-input-number>
			</el-form-item>
			<el-form-item label="Currency" prop="currency" required>
				<el-radio-group v-model="createRefund.currency">
					<el-radio v-for="option in CurrencyOptions.options" :key="option.label" :label="option.value">{{ option.label }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item v-if="createRefund.currency" label="Refund" prop="refund" required>
				<el-input-number v-model="createRefund.refund" :min="1"></el-input-number>
			</el-form-item>
			<el-form-item label="Remark" prop="remark" required>
				<el-input v-model="createRefund.remark" type="textarea" :rows="5"></el-input>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doCreateRefund">Create</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElAlert, ElButton, ElDialog, ElForm, ElFormItem, ElInput, ElInputNumber } from 'element-plus'
import { reactive, ref } from 'vue'
import refundAPI from '../../api/refund'
import { CurrencyOptions } from '../../types/common/option/enumOptions'
import { Result } from '../../types/common/result'
import { CreateRefund, CreateRefundImpl, Refund } from '../../types/refund'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	parentId: string
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const createRefund = reactive<CreateRefund>(new CreateRefundImpl(props.parentId))

const createRefundForm = ref<InstanceType<typeof ElForm>>()

const doCreateRefund = function () {
	createRefundForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			refundAPI.create(createRefund).then((response: AxiosResponse<Result<Refund>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					createRefundForm.value?.resetFields()
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				message.value = error.message
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>