import { DayOfWeek } from "./enums";

export enum Status {
  CREATED = 'CREATED', // 已报名
  STARTED = 'STARTED', // 已开课
  SUSPENDED = 'SUSPENDED', // 已暂停
  FINISHED = 'FINISHED', // 已完成
  CANCELED = 'CANCELED' // 已取消
}

export enum SuspendReason {
  LEAVE = 'LEAVE', // 请假
  INSUFFICIENT = 'INSUFFICIENT', // 账户余额不足
}

export interface Arrange {
  teacherId: string
  dayOfWeek: DayOfWeek
  index: number
  startTime: Date
  endTime: Date
}

export interface Enrollment {
  id: string
  status?: Status
  suspendReason?: SuspendReason

  parentId?: string
  parentNickname?: string
  
  studentId?: string
  studentNickname?: string

  courseId?: string
  courseName?: string
  courseFullName?: string
  
  latestFinishedLessonId?: string
  latestFinishedLessonNumber?: number
  latestFinishedLessonName?: string
  latestFinishedLessonFullName?: string

  latestBookedLessonId?: string
  latestBookedLessonNumber?: number
  latestBookedLessonName?: string
  latestBookedLessonFullName?: string

  createdDateTime?: Date
  startedDateTime?: Date
  finishedDateTime?: Date
  canceledDateTime?: Date

  arranges?: Arrange[]
}

export class EnrollmentImpl implements Enrollment {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface StartEnrollment {
  id: string
}

export class StartEnrollmentImpl implements StartEnrollment {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface CreateEnrollment {
  parentId: string
  studentId?: string
  serieId?: string
  courseId?: string
}

export class CreateEnrollmentImpl implements CreateEnrollment {
  parentId: string

  constructor(parentId: string) {
    this.parentId = parentId
  }
}

export interface RecreateEnrollment {
  id: string
}

export class RecreateEnrollmentImpl implements RecreateEnrollment {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface UpdateEnrollmentInfo {
  id: string
  latestBookedLessonId?: string
  latestFinishedLessonId?: string
}

export class UpdateEnrollmentInfoImpl implements UpdateEnrollmentInfo {
  id: string
  latestBookedLessonId?: string
  latestFinishedLessonId?: string

  constructor(enrollment: Enrollment) {
    this.id = enrollment.id
    this.latestBookedLessonId = enrollment.latestBookedLessonId
    this.latestFinishedLessonId = enrollment.latestFinishedLessonId
  }
}

export interface SuspendEnrollment {
  id: string
  suspendReason?: SuspendReason
}

export class SuspendEnrollmentImpl implements SuspendEnrollment {
  id: string
  suspendReason?: SuspendReason

  constructor(enrollment: Enrollment) {
    this.id = enrollment.id
    this.suspendReason = enrollment.suspendReason
  }
}

export interface UpdateEnrollmentSuspendReason {
  id: string
  suspendReason?: SuspendReason
}

export class UpdateEnrollmentSuspendReasonImpl implements UpdateEnrollmentSuspendReason {
  id: string
  suspendReason?: SuspendReason

  constructor(enrollment: Enrollment) {
    this.id = enrollment.id
    this.suspendReason = enrollment.suspendReason
  }
}

export interface CancelEnrollment {
  id: string
}

export class CancelEnrollmentImpl implements CancelEnrollment {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface FinishEnrollment {
  id: string
}

export class FinishEnrollmentImpl implements FinishEnrollment {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface RestartEnrollment {
  id: string
}

export class RestartEnrollmentImpl implements RestartEnrollment {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface RearrangeEnrollment {
  id: string
}

export class RearrangeEnrollmentImpl implements RearrangeEnrollment {
  id: string

  constructor(id: string) {
    this.id = id
  }
}