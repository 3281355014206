<template>
  <el-dialog v-model="show" title="Edit Course Locale Introduction" @opened="onOpened">
    <el-form ref="updateCourseLocaleIntroductionForm" :model="updateCourseLocaleIntroduction">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="introduction" prop="introduction" required>
				<div style="width: 100%; height: 300px; line-height: normal;">
					<quill-editor ref="editor" v-model:content="updateCourseLocaleIntroduction.introduction" theme="snow" contentType="html"></quill-editor>
				</div>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateCourseIntroduction">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { QuillEditor } from '@vueup/vue-quill'
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import courseAPI from '../../api/course'
import { Result } from '../../types/common/result'
import { Course, LocaleCourse, UpdateCourseLocaleIntroduction, UpdateCourseLocaleIntroductionImpl } from '../../types/course'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	course: Course,
	locale?: string
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const editor = ref<InstanceType<typeof QuillEditor>>()

const doFindLocaleCourse = function () {
	courseAPI.findLocaleById(props.course.id).then((response: AxiosResponse<Result<LocaleCourse>>) => {
		const result = response.data
		if (result.success) {
			const localeCourse = result.data
			if (localeCourse.localeIntroductions) {
				localeCourse.localeIntroductions.forEach(localeIntroduction => {
					if (localeIntroduction.locale === props.locale) {
						updateCourseLocaleIntroduction.locale = props.locale
						updateCourseLocaleIntroduction.introduction = localeIntroduction.introduction
						return
					}
				})
			}
			
			if (updateCourseLocaleIntroduction.introduction) editor.value?.setHTML(updateCourseLocaleIntroduction.introduction)
		} else {
			message.value = result.message
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

const updateCourseLocaleIntroduction = reactive<UpdateCourseLocaleIntroduction>(new UpdateCourseLocaleIntroductionImpl(props.course.id))

const updateCourseLocaleIntroductionForm = ref<InstanceType<typeof ElForm>>()

const doUpdateCourseIntroduction = function () {
	updateCourseLocaleIntroductionForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			courseAPI.updateLocaleIntroduction(updateCourseLocaleIntroduction).then((response: AxiosResponse<Result<Course>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const onOpened = function () {
	doFindLocaleCourse()
}
</script>