import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Agent from '../views/agent/Agent.vue'
import Agents from '../views/agent/Agents.vue'
import StudentAvatars from '../views/avatar/StudentAvatars.vue'
import Coupon from '../views/coupon/Coupon.vue'
import Coupons from '../views/coupon/Coupons.vue'
import Course from '../views/course/Course.vue'
import Courses from '../views/course/Courses.vue'
import Series from '../views/course/Series.vue'
import AllEnrollments from '../views/enrollment/AllEnrollments.vue'
import Login from '../views/Login.vue'
import Profile from '../views/me/Profile.vue'
import NotAuthorized from '../views/NotAuthorized.vue'
import NotFound from '../views/NotFound.vue'
import Parent from '../views/parent/Parent.vue'
import Parents from '../views/parent/Parents.vue'
import AllRecharges from '../views/recharge/AllRecharges.vue'
import RechargeCards from '../views/recharge/RechargeCards.vue'
import AllRefunds from '../views/refund/AllRefunds.vue'
import AllArrangements from '../views/schedule/AllArrangements.vue'
import Monitor from '../views/schedule/Monitor.vue'
import Staff from '../views/staff/Staff.vue'
import Staffs from '../views/staff/Staffs.vue'
import ArrangementStatistic from '../views/statistic/ArrangementStatistic.vue'
import Teacher from '../views/teacher/Teacher.vue'
import Teachers from '../views/teacher/Teachers.vue'
import AllVacations from '../views/vacation/AllVacations.vue'
import Welcome from '../views/Welcome.vue'
import AllWithdraws from '../views/withdraw/AllWithdraws.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    name: 'notFound',
    path: '/404',
    component: () => NotFound
  },
  {
    name: 'notAuthorized',
    path: '/403',
    component: () => NotAuthorized
  },
  {
    name: 'welcome',
    path: '/welcome',
    meta: {
      title: 'Welcome'
    },
    component: Welcome
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      title: 'Profile'
    },
    component: () => Profile
  },
  {
    name: 'parents',
    path: '/parents',
    meta: {
      title: 'Parents'
    },
    component: Parents
  },
  {
    name: 'agents',
    path: '/agents',
    meta: {
      title: 'Agents'
    },
    component: Agents
  },
  {
    name: 'parent',
    path: '/parent/:id',
    meta: {
      title: 'Parent',
      back: true
    },
    component: Parent
  },
  {
    name: 'agent',
    path: '/agent/:id',
    meta: {
      title: 'Agent',
      back: true
    },
    component: Agent
  },
  {
    name: 'rechargeCards',
    path: '/rechargeCards',
    meta: {
      title: 'Recharge Cards'
    },
    component: RechargeCards
  },
  {
    name: 'coupons',
    path: '/coupons',
    meta: {
      title: 'Coupons'
    },
    component: Coupons
  },
  {
    name: 'coupon',
    path: '/coupon/:id',
    meta: {
      title: 'Coupon',
      back: true
    },
    component: Coupon
  },
  {
    name: 'series',
    path: '/series',
    meta: {
      title: 'Series'
    },
    component: Series
  },
  {
    name: 'courses',
    path: '/courses',
    meta: {
      title: 'Courses'
    },
    component: Courses
  },
  {
    name: 'course',
    path: '/course/:id',
    meta: {
      title: 'Course',
      back: true
    },
    component: Course
  },
  {
    name: 'staffs',
    path: '/staffs',
    meta: {
      title: 'Staffs'
    },
    component: Staffs
  },
  {
    name: 'staff',
    path: '/staff/:id',
    meta: {
      title: 'Staff',
      back: true
    },
    component: Staff
  },
  {
    name: 'studentAvatars',
    path: '/studentAvatars',
    meta: {
      title: 'Student Avatars'
    },
    component: StudentAvatars
  },
  {
    name: 'teachers',
    path: '/teachers',
    meta: {
      title: 'Teachers'
    },
    component: Teachers
  },
  {
    name: 'teacher',
    path: '/teacher/:id',
    meta: {
      title: 'Teacher',
      back: true
    },
    component: Teacher
  },
  {
    name: 'vacations',
    path: '/vacations',
    meta: {
      title: 'Vacations'
    },
    component: AllVacations
  },
  {
    name: 'enrollments',
    path: '/enrollments',
    meta: {
      title: 'Enrollments'
    },
    component: AllEnrollments
  },
  {
    name: 'arrangements',
    path: '/arrangements',
    meta: {
      title: 'Arrangements'
    },
    component: AllArrangements
  },
  {
    name: 'monitor',
    path: '/monitor',
    meta: {
      title: 'Monitor'
    },
    component: Monitor
  },
  {
    name: 'recharges',
    path: '/recharges',
    meta: {
      title: 'Recharges'
    },
    component: AllRecharges
  },
  {
    name: 'refunds',
    path: '/refunds',
    meta: {
      title: 'Refund'
    },
    component: AllRefunds
  },
  {
    name: 'withdraws',
    path: '/withdraws',
    meta: {
      title: 'Withdraws'
    },
    component: AllWithdraws
  },
  {
    name: 'arrangementStatistic',
    path: '/arrangementStatistic',
    meta: {
      title: 'Arrangement Statistic'
    },
    component: () => ArrangementStatistic
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: "is-active",
  linkExactActiveClass: "is-active",
  routes
})

export default router