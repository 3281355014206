import { Filters } from "./index"

export interface RechargeCardFilters extends Filters {
	recommended?: boolean
	hot?: boolean
	published?: boolean
}

export class RechargeCardFiltersImpl implements RechargeCardFilters {
	recommended?: boolean
	hot?: boolean
	published?: boolean

	reset(): void {
		this.recommended = undefined
		this.hot = undefined
		this.published = undefined
	}
}