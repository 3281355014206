import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { Coupon, CreateCoupon, NanoCoupon, ToggleCouponPublished, UpdateCouponInfo } from "../types/coupon"
import service from "./api-service"

const api = '/coupon'

const couponAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Coupon>>>(api + '/list', query)
  },

  findAllPublished () {
    return service.get<Result<NanoCoupon[]>>(api + '/findAllPublished')
  },
  
  findById (id: string) {
    return service.get<Result<Coupon>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  create (createCoupon: CreateCoupon) {
    return service.post<Result<Coupon>>(api, createCoupon)
  },

  updateInfo (updateCouponInfo: UpdateCouponInfo) {
    return service.put<Result<Coupon>>(api + '/updateInfo', updateCouponInfo)
  },

  togglePublished (toggleCouponPublished: ToggleCouponPublished) {
    return service.put<Result<Coupon>>(api + '/togglePublished', toggleCouponPublished)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default couponAPI