import { Filters } from "./index"

export interface EarningFilters extends Filters {
	teacherId?: string
}

export class EarningFiltersImpl implements EarningFilters {
	teacherId?: string

	reset(): void {
		this.teacherId = undefined
	}
}