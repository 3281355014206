import { Status, CancelReason } from "../types/arrangement"
import { toFirstUpperCase } from "../utils/stringUtils"

export class ArrangementFilter {
  static status (status?: Status): string {
    return toFirstUpperCase(status?.toString())
  }

  static cancelReason (cancelReason?: CancelReason): string {
    switch (cancelReason) {
      case CancelReason.ABSENT_STUDENT:
        return 'Student Absent'
      case CancelReason.ABSENT_TEACHER:
        return 'Teacher Absent'
      case CancelReason.LEAVE_STUDENT:
        return 'Student Leave'
      case CancelReason.LEAVE_TEACHER:
        return 'Teacher Leave'
      case CancelReason.OTHERS:
        return 'Others'
      default:
        return '' 
    }
  }

  static cancelConfirmed (cancelConfirmed?: boolean): string {
    if (cancelConfirmed == undefined) return ''
    else return cancelConfirmed? 'Confirmed' : 'Confirming'
  }

  static trial (trial?: boolean): string {
    if (trial == undefined) return ''
    else return trial? 'Trial' : 'Formal'
  }

  static demo (demo?: boolean): string {
    if (demo == undefined) return ''
    else return demo? 'Demo' : ''
  }
}