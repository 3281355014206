import { Filters } from "./index"

export interface ModuleFilters extends Filters {
	courseId: string
}

export class ModuleFiltersImpl implements ModuleFilters {
	courseId: string

	constructor(courseId: string) {
		this.courseId = courseId
	}
	
	reset(): void {

	}
}