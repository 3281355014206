import axios, { AxiosHeaders, AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosResponseHeaders, InternalAxiosRequestConfig } from "axios"
import { ElMessage } from 'element-plus'
import router from "../router"
import storage from '../storage'
import { store } from "../store"

const responseTransformer = function (data: any, headers?: AxiosResponseHeaders): any {
  return JSON.parse(data, (key: string, value: any) => {
    if (key === 'birthday' 
				|| key === 'createdDateTime' || key === 'completedDateTime' || key === 'voidedDateTime' || key === 'startDateTime' 
				|| key === 'dateTime' || key === 'date' 
				|| key === 'startDate' || key === 'endDate' 
				|| key === 'startTime' || key === 'endTime'
				|| key === 'signupDateTime' || key === 'rechargeDateTime'
				|| key === 'acquiredDate' || key === 'expiredDate' ) {
			if (value) {
				return new Date(value)
			} else {
				return value
			}
    } else if (key === 'timeSlots') {
			return new Map(Object.entries(value))
		} else {
      return value
    }
  })
}

const config: AxiosRequestConfig = {
	baseURL: import.meta.env.VITE_API_URL,
	timeout: 10000,
	responseType: 'json',
	headers: { 'Access-Control-Allow-Origin': '*' },
	transformResponse: [responseTransformer]
}

const service: AxiosInstance = axios.create(config)

// request拦截器
service.interceptors.request.use(
	(config: InternalAxiosRequestConfig<any>) => {
		if (store.getters.isLogin && config.headers) {
			config.headers = config.headers as AxiosHeaders
			const auth = 'Bearer ' + storage.getAccessToken()
  		config.headers.set('Authorization', auth)
		}

		// 如果是get或者delete请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
		if (config.method === 'get' || config.method === 'delete') {
			config.paramsSerializer = {
				indexes: null // by default: false
			}
		}

		return config
	},
	(error: any) => Promise.reject(error)
)

// response拦截器
service.interceptors.response.use(
	(response: AxiosResponse) => Promise.resolve(response),
	(error: any) => {
		const response = error.response
		if (response) {
			if (response.status === 401) { // 未认证
				router.push({
					name: 'login'
				})
			} else if (response.status === 403) { // 无访问权限
				router.push({
					name: 'notAuthorized'
				})
			} else {
				ElMessage({
					showClose: true,
					message: error.message,
					type: 'error'
				})
			}
		}
		return Promise.reject(error)
	}
)

export default service