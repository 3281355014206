<template>
  <el-dialog v-model="show" title="Edit Parent Remark">
    <el-form ref="updateParentRemarkForm" :model="updateParentRemark">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Remark" prop="remark">
				<el-input v-model="updateParentRemark.remark" type="textarea" rows="5" maxlength="100" show-word-limit clearable></el-input>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateParentRemark">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import parentAPI from '../../api/parent'
import { Result } from '../../types/common/result'
import { Parent, UpdateParentRemark, UpdateParentRemarkImpl } from '../../types/parent'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	parent: Parent
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

let updateParentRemark = reactive<UpdateParentRemark>(new UpdateParentRemarkImpl(props.parent))

const updateParentRemarkForm = ref<InstanceType<typeof ElForm>>()

const doUpdateParentRemark = function () {
	updateParentRemarkForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			parentAPI.updateRemark(updateParentRemark).then((response: AxiosResponse<Result<Parent>>) => {
				const result: Result<Parent> = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>