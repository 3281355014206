import { Option, OptionImpl } from "."
import { Type } from "../../coupon"

export class CouponPublishedOptions {
	static options: Option[] = [
		new OptionImpl('Published', true),
		new OptionImpl('Draft', false)
	]
}

export class CouponTypeOptions {
	static options: Option[] = [
		new OptionImpl('General', Type.GENERAL),
		new OptionImpl('Specific', Type.SPECIFIC)
	]
}