import { Option, OptionImpl } from "."
import { Type } from "../../course"

export class CourseTypeOptions {
	static options: Option[] = [
		new OptionImpl('VIP', Type.VIP),
		new OptionImpl('Group', Type.GROUP)
	]
}

export class CourseTrialOptions {
	static options: Option[] = [
		new OptionImpl('Trial', true),
		new OptionImpl('Formal', false)
	]
}