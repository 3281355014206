<template>
  <div class="content">
		<slot></slot>

		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item>
					<el-date-picker v-model="filters.date" type="date" placeholder="Date" />
				</el-form-item>
				<el-form-item v-if="!teacher">
					<el-select v-model="filters.teacherId" placeholder="Teacher" style="width: 150px;" filterable>
						<el-option v-for="teacher in teachers" :key="teacher.id" :label="teacher.nickname" :value="teacher.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-button-group>
				<el-button text type="primary" @click="showCreateVacationDialog = true">Create Vacation</el-button>
			</el-button-group>
		</div>

		<el-table :data="vacations" v-loading="loading"  border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<!-- <el-table-column label="GMT Time" width="120">
				<template #default="scope">
					<div>{{DateTimeFilter.date(scope.row.startDateTime, 'GMT')}}</div>
					<div>{{DateTimeFilter.time(scope.row.startDateTime, 'GMT')}}</div>
				</template>
			</el-table-column> -->
			<el-table-column label="Teacher Time" width="120">
				<template #default="scope">
					<div>{{DateTimeFilter.date(scope.row.date, scope.row.teacherZoneId)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Student Time" width="120">
				<template #default="scope">
					<div>{{DateTimeFilter.date(scope.row.date, scope.row.parentZoneId)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Teacher" width="120">
				<template #default="scope">
					<div>{{scope.row.teacherNickname}}</div>
					<div>{{scope.row.teacherFullName}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Remark" prop="remark" />
			<el-table-column label="Actions" fixed="right" width="100">
				<template #default="scope">
					<el-button-group>
						<el-popconfirm title="Are you sure to delete the vacation?" @confirm="doDeleteVacation(scope.row.id)">
							<template #reference>
								<el-button text type="primary">Delete</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>
		
		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />

		<create-vacation-dialog v-if="showCreateVacationDialog" v-model:visible="showCreateVacationDialog" :teacher="teacher" @success="onCreateVacationSuccess"></create-vacation-dialog>
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import teacherAPI from '../../api/teacher'
import vacationAPI from '../../api/vacation'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { Query, QueryImpl } from '../../types/common/query'
import { VacationFilters, VacationFiltersImpl } from '../../types/common/query/filters/vacationFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { VacationSearcherImpl } from '../../types/common/query/searcher/vacationSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { NanoTeacher, Teacher } from '../../types/teacher'
import { Vacation } from '../../types/vacation'
import CreateVacationDialog from './CreateVacationDialog.vue'

const props = defineProps<{
  teacher?: Teacher
}>()

const loading = ref<boolean>(false)

const vacations = ref<Vacation[]>()

onMounted(() => {
	doListVacation()

	if (props.teacher) {
		// do nothing
	} else {
		doFindAllTeachers()
	}
})

// Query vacation
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<VacationFilters>(new VacationFiltersImpl())
const searcher = reactive<Searcher>(new VacationSearcherImpl())
const sorts = [new SortImpl('date', Direction.DESC), new SortImpl('teacherId', Direction.DESC)]

// Find teachers
const teachers = ref<NanoTeacher[]>()
const doFindAllTeachers = function () {
	teacherAPI.findAll().then((response: AxiosResponse<Result<NanoTeacher[]>>) => {
		const result = response.data
		if (result.success) {
			teachers.value = result.data
		} else {
			ElMessage.error(result.message)
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const doListVacation = function () {
	if (props.teacher) filters.teacherId = props.teacher.id

	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	vacationAPI.list(query).then((response: AxiosResponse<Result<ListObject<Vacation>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			vacations.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListVacation()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListVacation()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListVacation()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListVacation()
}

// Create vacation
const showCreateVacationDialog = ref<boolean>(false)
const onCreateVacationSuccess = function () {
	ElMessage.success('Success to book')
	doListVacation()
}

// Delete vacation
const doDeleteVacation = function (vacation: Vacation) {
	loading.value = true
	vacationAPI.deleteById(vacation.id).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete')
			doListVacation()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}
</script>