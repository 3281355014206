import { Result } from "../types/common/result"
import { Recording } from "../types/zoom"
import service from "./api-service"

const api = '/zoom'

const zoomAPI = {  
  findRecordingByMeetingId (meetingId: number) {
    return service.get<Result<Recording>>(api + '/findRecordingByMeetingId', { 
      params: {
        meetingId: meetingId
      }
    })
  },
}

export default zoomAPI