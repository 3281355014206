export enum Type {
	SPECIFIC = 'SPECIFIC',
	GENERAL = 'GENERAL'
}

export interface NanoCoupon {
  id: string
  name: string
}

export interface Coupon {
  id: string
  published?: boolean
  name?: string
	description?: string
  type?: Type
  rechargeCardId?: string
	rechargeCardName?: string
  value?: number
  period?: number
}

export class CouponImpl implements Coupon {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface CreateCoupon {
	name?: string
	description?: string
	type?: Type
	rechargeCardId?: string
	value?: number
	period?: number
}

export class CreateCouponImpl implements CreateCoupon {
	name?: string
	description?: string
	type?: Type
	rechargeCardId?: string
	value?: number
	period?: number
}

export interface UpdateCouponInfo {
	id: string
  name?: string
	description?: string
	type?: Type
	rechargeCardId?: string
}

export class UpdateCouponInfoImpl implements UpdateCouponInfo {
	id: string
  name?: string
	description?: string
	type?: Type
	rechargeCardId?: string

	constructor(coupon: Coupon) {
		this.id = coupon.id
		this.name = coupon.name
		this.description = coupon.description
		this.type = coupon.type
		this.rechargeCardId = coupon.rechargeCardId
	}
}

export interface ToggleCouponPublished {
	id: string
}

export class ToggleCouponPublishedImpl implements ToggleCouponPublished {
	id: string

	constructor(id: string) {
		this.id = id
	}
}