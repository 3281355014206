import { Filters } from "./index"

export interface ConsumeFilters extends Filters {
	parentId?: string
	studentId?: string
}

export class ConsumeFiltersImpl implements ConsumeFilters {
	parentId?: string
	studentId?: string

	reset(): void {
		this.parentId = undefined
		this.studentId = undefined
	}
}