import { CancelReason, Status } from "../../../arrangement"
import { DateType } from "../../../enums"
import { Filters } from "./index"

export interface ArrangementFilters extends Filters {
	teacherId?: string
	parentId?: string
	studentId?: string
	courseId?: string
	dateType: DateType
	startDateTime?: Date
	status?: Status
	cancelReason?: CancelReason
	trial?: boolean
}

export class ArrangementFiltersImpl implements ArrangementFilters {
	teacherId?: string
	parentId?: string
	studentId?: string
	courseId?: string
	dateType: DateType
	startDateTime?: Date
	status?: Status
	cancelReason?: CancelReason
	trial?: boolean

	constructor() {
		this.dateType = DateType.RANGE_DATE
	}

	reset(): void {
		this.teacherId = undefined
		this.parentId = undefined
		this.studentId = undefined
		this.courseId = undefined
		this.startDateTime = undefined
		this.status = undefined
		this.cancelReason = undefined
		this.trial = undefined
	}
}