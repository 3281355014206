<template>
  <el-dialog v-model="show" title="Edit Parent Referrer" @opened="onOpened">
    <el-form ref="updateParentReferrerForm" :model="updateParentReferrer">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Referrer" prop="referrerId">
				<el-select v-model="updateParentReferrer.referrerId" filterable clearable style="width: 100%;">
					<el-option v-for="referrer in referrers" :key="referrer.id" :label="ParentFilter.fullName(referrer)" :value="referrer.id"></el-option>
				</el-select>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateParentReferrer">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import parentAPI from '../../api/parent'
import { Result } from '../../types/common/result'
import { NanoParent, Parent, UpdateParentReferrer, UpdateParentReferrerImpl } from '../../types/parent'
import { useModelWrapper } from '../../utils/modelWrapper'
import { ParentFilter } from '../../filters/parentFilter'

const props = defineProps<{
  visible: boolean,
	parent: Parent
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

// Find Referrer
const referrers = ref<NanoParent[]>()
const doFindAllReferrer = function () {
	loading.value = true
	parentAPI.findAll().then((response: AxiosResponse<Result<NanoParent[]>>) => {
		const result = response.data
		if (result.success) {
			referrers.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

let updateParentReferrer = reactive<UpdateParentReferrer>(new UpdateParentReferrerImpl(props.parent))

const updateParentReferrerForm = ref<InstanceType<typeof ElForm>>()

const doUpdateParentReferrer = function () {
	updateParentReferrerForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			parentAPI.updateReferrer(updateParentReferrer).then((response: AxiosResponse<Result<Parent>>) => {
				const result: Result<Parent> = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const onOpened = function () {
	doFindAllReferrer()
}
</script>