<template>
  <div class="content">
		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item>
					<el-select v-model="filters.status" placeholder="Status">
						<el-option v-for="option in StudentStatusOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.gender" placeholder="Gender">
						<el-option v-for="option in GenderOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-button-group>
				<el-button text type="primary" @click="showCreateStudentDialog = true">Create Student</el-button>
			</el-button-group>
		</div>

		<el-table :data="students" v-loading="loading"  border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column label="Nickname" prop="nickname" />
			<el-table-column label="Name" prop="name" />
			<el-table-column label="Status" prop="status">
				<template #default="scope">{{StudentFilter.status(scope.row.status)}}</template>
			</el-table-column>
			<el-table-column label="Gender" prop="gender">
				<template #default="scope">{{EnumFilter.gender(scope.row.gender)}}</template>
			</el-table-column>
			<el-table-column label="Birthday" prop="birthday">
				<template #default="scope">{{DateTimeFilter.date(scope.row.birthday)}}</template>
			</el-table-column>
			<el-table-column label="Age">
				<template #default="scope">{{DateTimeFilter.age(scope.row.birthday)}}</template>
			</el-table-column>
			<el-table-column label="Actions" fixed="right" width="280">
				<template #default="scope">
					<el-button-group>
						<el-button text type="primary" @click="doShowEditStudentInfoDialog(scope.row)">Edit Info</el-button>
						<el-button text type="primary" @click="doShowEditStudentStatusDialog(scope.row)">Edit Status</el-button>
						<el-popconfirm title="Are you sure to delete the student?" @confirm="doDeleteStudent(scope.row.id)">
							<template #reference>
								<el-button text type="primary">Delete</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />
	</div>

	<create-student-dialog v-if="showCreateStudentDialog" v-model:visible="showCreateStudentDialog" :parent-id="props.parentId" @success="onCreateStudentSuccess"></create-student-dialog>
	<update-student-info-dialog v-if="showUpdateStudentInfoDialog" v-model:visible="showUpdateStudentInfoDialog" :student="selectedStudent" @success="onUpdateStudentInfoSuccess"></update-student-info-dialog>
	<update-student-status-dialog v-if="showUpdateStudentStatusDialog" v-model:visible="showUpdateStudentStatusDialog" :student="selectedStudent" @success="onUpdateStudentStatusSuccess"></update-student-status-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import studentAPI from '../../api/student'
import CreateStudentDialog from '../../components/student/CreateStudentDialog.vue'
import UpdateStudentInfoDialog from '../../components/student/UpdateStudentInfoDialog.vue'
import UpdateStudentStatusDialog from '../../components/student/UpdateStudentStatusDialog.vue'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { EnumFilter } from '../../filters/enumFilter'
import { StudentFilter } from '../../filters/studentFilter'
import { GenderOptions } from '../../types/common/option/enumOptions'
import { StudentStatusOptions } from '../../types/common/option/studentOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { StudentFilters, StudentFiltersImpl } from '../../types/common/query/filters/studentFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { StudentSearcherImpl } from '../../types/common/query/searcher/studentSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Student, StudentImpl } from '../../types/student'

const props = defineProps<{
  parentId: string
}>()

const loading = ref<boolean>(false)

const students = ref<Student[]>()

onMounted(() => {
	doListStudent()
})

// Query student
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<StudentFilters>(new StudentFiltersImpl(props.parentId))
const searcher = reactive<Searcher>(new StudentSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const doListStudent = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	studentAPI.list(query).then((response: AxiosResponse<Result<ListObject<Student>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			students.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListStudent()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListStudent()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListStudent()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListStudent()
}

const selectedStudent = ref<Student>(new StudentImpl(''))

// Create student
const doShowCreateStudentDialog = function () {
	showCreateStudentDialog.value = true
}
const showCreateStudentDialog = ref<boolean>(false)
const onCreateStudentSuccess = function () {
	ElMessage.success('Success to create')
	doListStudent()
}

// Edit student info
const doShowEditStudentInfoDialog = function (student: Student) {
	selectedStudent.value = student
	showUpdateStudentInfoDialog.value = true
}
const showUpdateStudentInfoDialog = ref<boolean>(false)
const onUpdateStudentInfoSuccess = function () {
	ElMessage.success('Success to edit')
	doListStudent()
}

// Edit student status
const doShowEditStudentStatusDialog = function (student: Student) {
	selectedStudent.value = student
	showUpdateStudentStatusDialog.value = true
}
const showUpdateStudentStatusDialog = ref<boolean>(false)
const onUpdateStudentStatusSuccess = function () {
	ElMessage.success('Success to edit')
	doListStudent()
}

const doDeleteStudent = function (id: string) {
	loading.value = true
	studentAPI.deleteById(id).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete')
			doListStudent()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}
</script>

<style scoped>
.time {
	display: flex;
	justify-content: space-between;
}
</style>