import { Login, Logined, RefreshToken } from "../types/auth"
import service from "./auth-service"
import qs from "qs"

const api = '/oauth/token'

const authAPI = {
  login (login: Login) {
    return service.post<Logined>(api, qs.stringify(login))
  },

  refreshToken (refreshToken: RefreshToken) {
    return service.post<string>(api, qs.stringify(refreshToken))
  }
}

export default authAPI