import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { Recommendation } from "../types/recommendation"
import service from "./api-service"

const api = '/recommendation'

const recommendationAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Recommendation>>>(api + '/list', query)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default recommendationAPI