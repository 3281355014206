import { Option, OptionImpl } from "."
import { DateTimeFilter } from "../../../filters/dateTimeFilter"
import { getPrevMonth } from "../../../utils/dateTimeUtils"
import { toFirstUpperCase } from "../../../utils/stringUtils"
import { Status } from "../../recruitment"

export class RecruitmentStatusOptions {
	static options: Option[] = [
    new OptionImpl(toFirstUpperCase(Status.SIGNUP), Status.SIGNUP),
		new OptionImpl(toFirstUpperCase(Status.RECHARGE), Status.RECHARGE)
	]
}

export class RecruitmentSignupDateTimeOptions {
  static options: Option[] = [
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(0)), getPrevMonth(0).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(1)), getPrevMonth(1).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(2)), getPrevMonth(2).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(3)), getPrevMonth(3).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(4)), getPrevMonth(4).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(5)), getPrevMonth(5).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(6)), getPrevMonth(6).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(7)), getPrevMonth(7).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(8)), getPrevMonth(8).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(9)), getPrevMonth(9).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(10)), getPrevMonth(10).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(11)), getPrevMonth(11).getTime()),
	]
}

export class RecruitmentRechargeDateTimeOptions {
  static options: Option[] = [
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(0)), getPrevMonth(0).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(1)), getPrevMonth(1).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(2)), getPrevMonth(2).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(3)), getPrevMonth(3).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(4)), getPrevMonth(4).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(5)), getPrevMonth(5).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(6)), getPrevMonth(6).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(7)), getPrevMonth(7).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(8)), getPrevMonth(8).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(9)), getPrevMonth(9).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(10)), getPrevMonth(10).getTime()),
		new OptionImpl(DateTimeFilter.yearMonth(getPrevMonth(11)), getPrevMonth(11).getTime()),
	]
}