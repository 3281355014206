import { Option, OptionImpl } from "."
import { toFirstUpperCase } from "../../../utils/stringUtils"
import { Status, SuspendReason } from "../../enrollment"

export class EnrollmentStatusOptions {
	static options: Option[] = [
		new OptionImpl(toFirstUpperCase(Status.CREATED), Status.CREATED),
		new OptionImpl(toFirstUpperCase(Status.STARTED), Status.STARTED),
		new OptionImpl(toFirstUpperCase(Status.SUSPENDED), Status.SUSPENDED),
		new OptionImpl(toFirstUpperCase(Status.FINISHED), Status.FINISHED),
		new OptionImpl(toFirstUpperCase(Status.CANCELED), Status.CANCELED)
	]
}

export class EnrollmentSuspendReasonOptions {
	static options: Option[] = [
		new OptionImpl(toFirstUpperCase(SuspendReason.LEAVE), SuspendReason.LEAVE),
		new OptionImpl(toFirstUpperCase(SuspendReason.INSUFFICIENT), SuspendReason.INSUFFICIENT)
	]
}