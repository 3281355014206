<template>
  <div class="content">
		<div class="actionbar">
			<div>Module List</div>
			<el-button-group>
				<el-button text type="primary" @click="showCreateModuleDialog = true">Create Module</el-button>
			</el-button-group>
		</div>

    <el-divider />

		<div class="query">
			<!-- filter -->
			<it-placeholder></it-placeholder>
			<!-- search -->
			<el-form :inline="true" :model="searcher">
				<el-form-item>
					<el-select v-model="searcher.field" placeholder="Field" style="width: 120px;">
						<el-option label="Name" value="name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-input v-model="searcher.keyword"></el-input>
				</el-form-item>
				<el-form-item style="margin-right: 0;">
					<el-button type="primary" @click="doSearch">Search</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="modules" v-loading="loading"  border class="table" row-key="id" @expand-change="onTableExpandChange">
			<el-table-column type="expand">
				<template #default="scope">
					<div v-if="scope.row.content" v-html="scope.row.content" style="margin-left: 140px;"></div>
					<div v-else style="margin: 10px 140px; color: gainsboro;">No module content</div>
				</template>
			</el-table-column>
			<el-table-column label="Number" prop="number" width="80px" />
			<el-table-column label="Module" width="100px">
				<template #default="scope">Module {{ scope.row.number + 1 }}</template>
			</el-table-column>
			<el-table-column label="Name" prop="name" />
			<el-table-column label="Actions" fixed="right" width="470">
				<template #default="scope">
					<el-button-group>
						<el-button text type="primary" @click="doShowUpdateModuleInfoDialog(scope.row)">Edit</el-button>
						<el-button text type="primary" @click="doShowUpdateModuleNumberDialog(scope.row)">Change Number</el-button>
						<el-button text type="primary" @click="doMoveModuleForward(scope.row)" :disabled="scope.row.number == 0">Move Up</el-button>
						<el-button text type="primary" @click="doMoveModuleBackward(scope.row)" :disabled="scope.row.number + 1 == pagination.total">Move Down</el-button>
						<el-popconfirm title="Are you sure to delete the module?" @confirm="doDeleteModule(scope.row.id)">
							<template #reference>
								<el-button text type="primary">Delete</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />

		<create-module-dialog v-if="showCreateModuleDialog" v-model:visible="showCreateModuleDialog" :course-id="courseId" @success="onCreateModuleSuccess"></create-module-dialog>

		<update-module-info-dialog v-if="showUpdateModuleInfoDialog" v-model:visible="showUpdateModuleInfoDialog" :module="selectedModule" @success="onUpdateModuleInfoSuccess"></update-module-info-dialog>

		<update-module-number-dialog v-if="showUpdateModuleNumberDialog" v-model:visible="showUpdateModuleNumberDialog" :module="selectedModule" @success="onUpdateModuleNumberSuccess"></update-module-number-dialog>
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import moduleAPI from '../../api/module'
import CreateModuleDialog from '../../components/module/CreateModuleDialog.vue'
import UpdateModuleInfoDialog from '../../components/module/UpdateModuleInfoDialog.vue'
import UpdateModuleNumberDialog from '../../components/module/UpdateModuleNumberDialog.vue'
import { Query, QueryImpl } from '../../types/common/query'
import { ModuleFilters, ModuleFiltersImpl } from '../../types/common/query/filters/moduleFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher/index'
import { ModuleSearcherImpl } from '../../types/common/query/searcher/moduleSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Module, ModuleImpl, MoveModuleBackwardImpl, MoveModuleForwardImpl, SimpleModule } from '../../types/module'
import ItPlaceholder from '../ui/ItPlaceholder.vue'

const props = defineProps<{
  courseId: string
}>()

const loading = ref<boolean>(false)

const modules = ref<SimpleModule[]>()

onMounted(() => {
	doListModule()
})

// Query module
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<ModuleFilters>(new ModuleFiltersImpl(props.courseId))
const searcher = reactive<Searcher>(new ModuleSearcherImpl())
const sorts = [new SortImpl('number', Direction.ASC)]

const doListModule = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	moduleAPI.list(query).then((response: AxiosResponse<Result<ListObject<SimpleModule>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			modules.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListModule()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListModule()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListModule()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListModule()
}

// Create module
const showCreateModuleDialog = ref<boolean>(false)
const onCreateModuleSuccess = function () {
	ElMessage.success('Success to create')
	pagination.gotoLastPage()
	doListModule()
}

let selectedModule = ref<Module>(new ModuleImpl(''))

// Edit module
const showUpdateModuleInfoDialog = ref<boolean>(false)
const doShowUpdateModuleInfoDialog = function (module: Module) {
	selectedModule.value = module
	showUpdateModuleInfoDialog.value = true
}
const onUpdateModuleInfoSuccess = function () {
	ElMessage.success('Success to edit')
	doListModule()
}

const showUpdateModuleNumberDialog = ref<boolean>(false)
const doShowUpdateModuleNumberDialog = function (module: Module) {
	selectedModule.value = module
	showUpdateModuleNumberDialog.value = true
}
const onUpdateModuleNumberSuccess = function () {
	ElMessage.success('Success to update number')
	doListModule()
}

// Move up module 
const doMoveModuleForward = function (module: Module) {
	loading.value = true
	const moveModuleForward = new MoveModuleForwardImpl(module)
	moduleAPI.moveForward(moveModuleForward).then((response: AxiosResponse<Result<Module>>) => {
		const result = response.data
		if (result.success) {
			doListModule()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Move down module
const doMoveModuleBackward = function (module: Module) {
	loading.value = true
	const moveModuleBackward = new MoveModuleBackwardImpl(module)
	moduleAPI.moveBackward(moveModuleBackward).then((response: AxiosResponse<Result<Module>>) => {
		const result = response.data
		if (result.success) {
			doListModule()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Delete module
const doDeleteModule = function (id: string) {
	loading.value = true
	moduleAPI.deleteById(id).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete')
			doListModule()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

const onTableExpandChange = function (module: SimpleModule, expandedModules: SimpleModule[]) {
	if (expandedModules.includes(module)) {
		moduleAPI.findById(module.id).then((response: AxiosResponse<Result<Module>>) => {
			const result = response.data
			if (result.success) {
				module.content = result.data.content
			} else {
				ElMessage.error(result.message)
			}
		}).catch((error: AxiosError) => {
			ElMessage.error(error.message)
		})
	}
}
</script>