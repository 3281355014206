import { Accent, Country } from "../../../enums"
import { Filters } from "./index"

export interface TeacherFilters extends Filters {
	country?: string
	displayCountry?: Country
	accent?: Accent
	locked?: boolean
	complete?: boolean
	qualified?: boolean
}

export class TeacherFiltersImpl implements TeacherFilters {
	country?: string
	displayCountry?: Country
	accent?: Accent
	locked?: boolean
	complete?: boolean
	qualified?: boolean

	reset(): void {
		this.country = undefined
		this.displayCountry = undefined
		this.accent = undefined
		this.locked = undefined
		this.complete = undefined
		this.qualified = undefined
	}
}