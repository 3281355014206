<template>
  <div class="content">
		<slot></slot>

		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item>
					<el-select v-model="filters.status" placeholder="Status">
						<el-option v-for="option in RecommendationStatusOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<it-placeholder></it-placeholder>
		</div>

		<el-table :data="recommendations" v-loading="loading"  border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column label="Status" prop="status" width="100">
				<template #default="scope">{{RecommendationFilter.status(scope.row.status)}}</template>
			</el-table-column>
			<el-table-column label="Referral" prop="referralName" />
			<el-table-column label="Date" prop="date">
				<template #default="scope">{{DateTimeFilter.datetime(scope.row.dateTime)}}</template>
			</el-table-column>
			<el-table-column label="Actions" fixed="right" width="100">
				<template #default="scope">
					<el-button-group>
						<el-popconfirm title="Are you sure to delete the recommendation?" @confirm="doDeleteRecommendation(scope.row.id)">
							<template #reference>
								<el-button text type="primary">Delete</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import recommendationAPI from '../../api/recommendation'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { RecommendationFilter } from '../../filters/recommendationFilter'
import { RecommendationStatusOptions } from '../../types/common/option/recommendationOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { RecommendationFilters, RecommendationFiltersImpl } from '../../types/common/query/filters/recommendationFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { RecommendationSearcherImpl } from '../../types/common/query/searcher/recommendationSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Recommendation } from '../../types/recommendation'
import ItPlaceholder from '../ui/ItPlaceholder.vue'

const props = defineProps<{
  parentId: string
}>()

const emit = defineEmits<{
  (e: 'deleted'): void
}>()

const loading = ref<boolean>(false)

const recommendations = ref<Recommendation[]>()

onMounted(() => {
	doListRecommendation()
})

// Query recommendation
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<RecommendationFilters>(new RecommendationFiltersImpl(props.parentId))
const searcher = reactive<Searcher>(new RecommendationSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const doListRecommendation = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	recommendationAPI.list(query).then((response: AxiosResponse<Result<ListObject<Recommendation>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			recommendations.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListRecommendation()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListRecommendation()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListRecommendation()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListRecommendation()
}

const doDeleteRecommendation = function (id: string) {
	loading.value = true
	recommendationAPI.deleteById(id).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete')
			doListRecommendation()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}
</script>

<style scoped>
.time {
	width: 200px;
	display: flex;
	justify-content: space-between;
}
</style>