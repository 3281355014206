import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { CreateStaff, NanoStaff, Role, Staff, ToggleStaffLocked, UpdateStaffInfo } from "../types/staff"
import service from "./api-service"

const api = '/staff'

const staffAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Staff>>>(api + '/list', query)
  },
  
  findById (id: string) {
    return service.get<Result<Staff>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  findByRole (role: Role) {
    return service.get<Result<NanoStaff[]>>(api + '/findByRole', { 
      params: {
        role: role
      }
    })
  },

  create (createStaff: CreateStaff) {
    return service.post<Result<Staff>>(api, createStaff)
  },

  updateInfo (updateStaffInfo: UpdateStaffInfo) {
    return service.put<Result<Staff>>(api + '/updateInfo', updateStaffInfo)
  },

  toggleLocked (toggleStaffLocked: ToggleStaffLocked) {
    return service.put<Result<Staff>>(api + '/toggleLocked', toggleStaffLocked)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default staffAPI