import { Teacher } from "./teacher"

export enum Status {
  REQUESTED = 'REQUESTED', // 已申请
  REJECTED = 'REJECTED', // 已拒绝
  APPROVED = 'APPROVED', // 已同意，处理中
  FINISHED = 'FINISHED', // 已完成
}

export enum TransactionStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  UNCLAIMED = 'UNCLAIMED',
  RETURNED = 'RETURNED',
  ONHOLD = 'ONHOLD',
  BLOCKED = 'BLOCKED',
  REFUNDED = 'REFUNDED',
  REVERSED = 'REVERSED'
}

export interface Withdraw {
  id: string
  status?: Status
  transactionStatus?: TransactionStatus
  value?: number
  remark?: string
  dateTime?: Date

  teacherId?: string
  teacherNickname?: string
}

export class WithdrawImpl implements Withdraw {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface ApproveWithdraw {
  id: string
}

export class ApproveWithdrawImpl implements ApproveWithdraw {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface RejectWithdraw {
  id: string
  remark?: string
}

export class RejectWithdrawImpl implements RejectWithdraw {
  id: string
  remark?: string

  constructor(withdraw: Withdraw) {
    this.id = withdraw.id
  }
}

export interface RetrieveWithdrawTransactionStatus {
  id: string
}

export class RetrieveWithdrawTransactionStatusImpl implements RetrieveWithdrawTransactionStatus {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface ManualWithdraw {
  teacherId?: string
  payPalEmail?: string
  value?: number
}

export class ManualWithdrawImpl implements ManualWithdraw {
  teacherId?: string
  payPalEmail?: string
  value?: number

  constructor(teacher?: Teacher) {
    this.teacherId = teacher?.id
    this.payPalEmail = teacher?.latestPayPalEmail
  }
}