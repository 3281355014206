<template>
  <div class="content">
		<div class="actionbar">
			<div>Course List</div>
			<el-button-group>
				<el-button text type="primary" @click="showCreateCourseDialog = true">Create Course</el-button>
			</el-button-group>
		</div>

    <el-divider />

		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item>
					<el-select v-model="filters.serieId" placeholder="Serie" filterable>
						<el-option v-for="serie in series" :key="serie.id" :label="serie.name" :value="serie.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.type" placeholder="Type">
						<el-option v-for="option in CourseTypeOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.trial" placeholder="Trial">
						<el-option v-for="option in CourseTrialOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-form :inline="true" :model="searcher">
				<el-form-item>
					<el-input v-model="searcher.keyword" class="input-with-select">
						<template #prepend>
							<el-select v-model="searcher.field" placeholder="Field" style="width: 120px;">
								<el-option label="Name" value="name"></el-option>
							</el-select>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item style="margin-right: 0;">
					<el-button type="primary" @click="doSearch">Search</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="courses" v-loading="loading"  border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column label="Serie" prop="serieName" />
			<el-table-column label="Name" prop="name" />
			<el-table-column label="Type" prop="type">
				<template #default="scope">{{CourseFilter.type(scope.row.type)}}</template>
			</el-table-column>
			<el-table-column label="Trial" prop="trial">
				<template #default="scope">{{CourseFilter.trial(scope.row.trial)}}</template>
			</el-table-column>
			<el-table-column label="Status" prop="status">
				<template #default="scope">{{CourseFilter.ready(scope.row.ready)}}</template>
			</el-table-column>
			<el-table-column label="Lesson Duration (Min)" prop="lessonDuration"></el-table-column>
			<el-table-column label="Lesson Count" prop="lessonCount"></el-table-column>
			<el-table-column label="Actions" fixed="right" width="160">
				<template #default="scope">
					<el-button-group>
						<el-button text type="primary" @click="doViewCourse(scope.row.id)">View</el-button>
						<el-popconfirm title="Are you sure to delete the course?" @confirm="doDeleteCourse(scope.row.id)">
							<template #reference>
								<el-button text type="primary">Delete</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />

		<create-course-dialog v-if="showCreateCourseDialog" v-model:visible="showCreateCourseDialog" @success="onCreateCourseSuccess"></create-course-dialog>

	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import courseAPI from '../../api/course'
import serieAPI from '../../api/serie'
import CreateCourseDialog from '../../components/course/CreateCourseDialog.vue'
import { CourseFilter } from '../../filters/courseFilter'
import { CourseTrialOptions, CourseTypeOptions } from '../../types/common/option/courseOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { CourseFilters, CourseFiltersImpl } from '../../types/common/query/filters/courseFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { CourseSearcherImpl } from '../../types/common/query/searcher/courseSearcher'
import { Searcher } from '../../types/common/query/searcher/index'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { SimpleCourse } from '../../types/course'
import { Serie } from '../../types/serie'

const loading = ref<boolean>(false)
const router = useRouter()

const series = ref<Serie[]>()
const doFindAllSerie = function () {
	serieAPI.findAll().then((response: AxiosResponse<Result<Serie[]>>) => {
		const result = response.data
		if (result.success) {
			series.value = result.data
		} else {
			ElMessage.error(result.message)
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const courses = ref<SimpleCourse[]>()

onMounted(() => {
	doFindAllSerie()
	doListCourse()
})

// Query course
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<CourseFilters>(new CourseFiltersImpl())
const searcher = reactive<Searcher>(new CourseSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.ASC)]

const doListCourse = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	courseAPI.list(query).then((response: AxiosResponse<Result<ListObject<SimpleCourse>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			courses.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListCourse()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListCourse()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListCourse()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListCourse()
}

// Create course
const showCreateCourseDialog = ref<boolean>(false)
const onCreateCourseSuccess = function () {
	ElMessage.success('Success to create')
	doListCourse()
}

// View course
const doViewCourse = function(id: string) {
	router.push({name: 'course', params: {id: id}})
}

// Delete course
const doDeleteCourse = function (id: string) {
	loading.value = true
	courseAPI.deleteById(id).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete')
			doListCourse()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}
</script>