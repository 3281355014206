import { Currency } from "./enums"

export enum Method {
	CREDIT_CARD = 'CREDIT_CARD',
	PAYPAL = 'PAYPAL',
	ALIPAY = 'ALIPAY',
	WECHAT_PAY = 'WECHAT_PAY',
	CASH = 'CASH'
}

export enum Status {
	CREATED = 'CREATED',
	COMPLETED = 'COMPLETED',
	VOIDED = 'VOIDED'
}

export interface Refund {
	id: string
	status?: Status
	method?: Method
	currency?: Currency
	refund?: number
	lessons?: number
	remark?: string
	createdDateTime?: Date
	completedDateTime?: Date
	voidedDateTime?: Date

	parentId?: string
	parentNickname?: string
	parentMobile?: string
	parentEmail?: string
}

export class RefundImpl implements Refund {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface CreateRefund {
	parentId: string
	currency?: Currency
	refund?: number
	lessons?: number
	remark?: string
}

export class CreateRefundImpl implements CreateRefund {
	parentId: string

	constructor(parentId: string) {
		this.parentId = parentId
	}
}

export interface UpdateRefundInfo {
	id: string
	remark?: string
}

export class UpdateRefundInfoImpl implements UpdateRefundInfo {
	id: string
	remark?: string

	constructor(recharge: Refund) {
		this.id = recharge.id
		this.remark = recharge.remark
	}
}