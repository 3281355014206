import { Query } from "../types/common/query";
import { ListObject, Result } from "../types/common/result";
import { AssignParentCourseService, GenerateParentPoster, NanoParent, Parent, SimpleParent, ToggleParentLocked, UpdateParentAgent, UpdateParentChargeForLesson, UpdateParentDetailInfo, UpdateParentReferrer, UpdateParentRemark, UpdateParentType } from "../types/parent";
import service from "./api-service";

const api = '/parent'

const parentAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<SimpleParent>>>(api + '/list', query)
  },
  
  findById (id: string) {
    return service.get<Result<Parent>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  findAll () {
    return service.get<Result<NanoParent[]>>(api + '/findAll')
  },

  searchByName (keyword: string) {
    return service.get<Result<SimpleParent[]>>(api + '/searchByName', { 
      params: {
        keyword: keyword
      }
    })
  },

  updateDetailInfo (updateParentDetailInfo: UpdateParentDetailInfo) {
    return service.put<Result<Parent>>(api + '/updateDetailInfo', updateParentDetailInfo)
  },

  updateChargeForLesson (updateParentChargeForLesson: UpdateParentChargeForLesson) {
    return service.put<Result<Parent>>(api + '/updateChargeForLesson', updateParentChargeForLesson)
  },

  updateType (updateParentType: UpdateParentType) {
    return service.put<Result<Parent>>(api + '/updateType', updateParentType)
  },

  updateReferrer (updateParentReferrer: UpdateParentReferrer) {
    return service.put<Result<Parent>>(api + '/updateReferrer', updateParentReferrer)
  },

  updateAgent (updateParentAgent: UpdateParentAgent) {
    return service.put<Result<Parent>>(api + '/updateAgent', updateParentAgent)
  },

  updateRemark (updateParentRemark: UpdateParentRemark) {
    return service.put<Result<Parent>>(api + '/updateRemark', updateParentRemark)
  },

  toggleLocked (toggleParentLocked: ToggleParentLocked) {
    return service.put<Result<Parent>>(api + '/toggleLocked', toggleParentLocked)
  },

  generatePoster (generateParentPoster: GenerateParentPoster) {
    return service.put<Result<Parent>>(api + '/generatePoster', generateParentPoster)
  },

  assignCourseService (assignParentCourseService: AssignParentCourseService) {
    return service.put<Result<Parent>>(api + '/assignCourseService', assignParentCourseService)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default parentAPI