import { Filters } from "./index"

export interface ChildAgentFilters extends Filters {
	parentAgentId: string
	locked?: boolean
}

export class ChildAgentFiltersImpl implements ChildAgentFilters {
	parentAgentId: string
	locked?: boolean

	constructor(parentAgentId: string) {
		this.parentAgentId = parentAgentId
	}

	reset(): void {
		this.locked = undefined
	}
}