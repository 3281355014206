import { Result } from "../types/common/result"
import service from "./api-service"

const api = '/bypass'

const bypassAPI = {
  getEmailAuthOtp (email: string) {
    return service.get<Result<string>>(api + '/getEmailAuthOtp', { 
      params: {
        email: email
      }
    })
  },

  getMobileAuthOtp (countryCode: string, mobile: string) {
    return service.get<Result<string>>(api + '/getMobileAuthOtp', { 
      params: {
        countryCode: countryCode,
        mobile: mobile
      }
    })
  }
}

export default bypassAPI