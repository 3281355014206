export interface Work {
  id: string
  lessonId: string

  hasCopy: boolean
  copy?: string

  hasDictation: boolean
  dictation?: string

  hasPractice: boolean
  hasReading: boolean

  hasWriting: boolean
  writing?: string
}

export interface CreateWork {
	lessonId: string
	copy?: string
	dictation?: string
  practice: boolean
  reading: boolean
  writing?: string
}

export class CreateWorkImpl implements CreateWork {
  lessonId: string
  practice: boolean
  reading: boolean

  constructor(lessonId: string) {
    this.lessonId = lessonId
    this.practice = true
    this.reading = false
  }
}

export interface UpdateWorkInfo {
  id: string
	copy?: string
	dictation?: string
  practice: boolean
  reading: boolean
  writing?: string
}

export class UpdateWorkInfoImpl implements UpdateWorkInfo {
	id: string
	copy?: string
	dictation?: string
  practice: boolean
  reading: boolean
  writing?: string

  constructor(work: Work) {
    this.id = work.id
    this.copy = work.copy
    this.dictation = work.dictation
    this.practice = work.hasPractice
    this.reading = work.hasReading
    this.writing = work.writing
  }
}