import { Result } from "../types/common/result"
import { CreateWork, UpdateWorkInfo, Work } from "../types/work"
import service from "./api-service"

const api = '/work'

const workAPI = { 
  findById (id: string) {
    return service.get<Result<Work>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  findByLessonId (lessonId: string) {
    return service.get<Result<Work>>(api + '/findByLessonId', { 
      params: {
        lessonId: lessonId
      }
    })
  },

  create (createWork: CreateWork) {
    return service.post<Result<Work>>(api, createWork)
  },

  updateInfo (updateWorkInfo: UpdateWorkInfo) {
    return service.put<Result<Work>>(api + '/updateInfo', updateWorkInfo)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default workAPI