import { Category, Method, Status, Type } from "../types/recharge"
import { toFirstUpperCase } from "../utils/stringUtils"

export class RechargeFilter {
  static status (status?: Status): string {
    return toFirstUpperCase(status?.toString())
  }

  static type (type?: Type): string {
    return toFirstUpperCase(type?.toString())
  }

  static category (category?: Category): string {
    return toFirstUpperCase(category?.toString())
  }

  static method (method?: Method): string {
    switch (method) {
      case Method.CARD:
        return 'Card'
      case Method.ALIPAY:
        return 'Alipay'
      case Method.WECHAT_PAY:
        return 'Wechat Pay'
      case Method.CASH:
        return 'Cash'
      case Method.OTHERS:
        return 'Others'
      default:
        return 'Undefined' 
    }
  }
}