<template>
  <div class="content">
		<div class="actionbar">
			<div>Lesson List</div>
			<el-button-group>
				<el-button text type="primary" @click="showCreateLessonDialog = true">Create Lesson</el-button>
			</el-button-group>
		</div>

    <el-divider />

		<div class="query">
			<!-- filter -->
			<it-placeholder></it-placeholder>
			<!-- search -->
			<el-form :inline="true" :model="searcher">
				<el-form-item>
					<el-select v-model="searcher.field" placeholder="Field" style="width: 120px;">
						<el-option label="Name" value="name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-input v-model="searcher.keyword"></el-input>
				</el-form-item>
				<el-form-item style="margin-right: 0;">
					<el-button type="primary" @click="doSearch">Search</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="lessons" v-loading="loading" border class="table" row-key="id" @expand-change="onTableExpandChange">
			<el-table-column type="expand">
				<template #default="scope">
					<div v-if="scope.row.content" v-html="scope.row.content" style="margin-left: 140px;"></div>
					<div v-else style="margin: 10px 140px; color: gainsboro;">No lesson content</div>
				</template>
			</el-table-column>
			<el-table-column label="Number" prop="number" width="80px" />
			<el-table-column label="Lesson" width="100px">
				<template #default="scope">Lesson {{ scope.row.number + 1 }}</template>
			</el-table-column>
			<el-table-column label="Module" prop="moduleName" />
			<el-table-column label="Name" prop="name" />
			<el-table-column label="Slide" width="60px">
				<template #default="scope">
					<a :href="scope.row.slide" target="_blank">Slide</a>
				</template>
			</el-table-column>
			<el-table-column label="Type" width="80px">
				<template #default="scope">{{LessonFilter.type(scope.row.type)}}</template>
			</el-table-column>
			<el-table-column label="Score" prop="totalTestScore" width="60px">
				<template #default="scope">
					<div style="width: 100%; text-align: center;">{{LessonFilter.totalTestScore(scope.row.totalTestScore)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Work" prop="workId" width="60px">
				<template #default="scope">
					<div style="width: 100%; text-align: center;" v-if="scope.row.workId">&check;</div>
				</template>
			</el-table-column>
			<el-table-column label="Actions" fixed="right" width="220">
				<template #default="scope">
					<!-- <el-button-group>
						<el-button text type="primary" @click="doShowUpdateLessonNumberDialog(scope.row)">Change Number</el-button>
					</el-button-group> -->
					<el-button-group>
						<el-button text type="primary" @click="doMoveLessonForward(scope.row)" :disabled="scope.row.number == 0">Move Up</el-button>
						<el-button text type="primary" @click="doMoveLessonBackward(scope.row)" :disabled="scope.row.number + 1 == pagination.total">Move Down</el-button>
					</el-button-group>
					<el-button-group>
						<el-button text type="primary" @click="doShowUpdateLessonInfoDialog(scope.row)">Edit</el-button>
						<el-button text type="primary" @click="onWorkButtonClick(scope.row)">Work</el-button>
						<el-popconfirm title="Are you sure to delete the lesson?" @confirm="doDeleteLesson(scope.row.id)">
							<template #reference>
								<el-button text type="primary">Delete</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />

		<create-lesson-dialog v-if="showCreateLessonDialog" v-model:visible="showCreateLessonDialog" :course-id="courseId" @success="onCreateLessonSuccess"></create-lesson-dialog>

		<update-lesson-info-dialog v-if="showUpdateLessonInfoDialog" v-model:visible="showUpdateLessonInfoDialog" :lesson="selectedLesson" @success="onUpdateLessonInfoSuccess"></update-lesson-info-dialog>

		<update-lesson-number-dialog v-if="showUpdateLessonNumberDialog" v-model:visible="showUpdateLessonNumberDialog" :lesson="selectedLesson" @success="onUpdateLessonNumberSuccess"></update-lesson-number-dialog>
	
		<create-work-dialog v-if="showCreateWorkDialog" v-model:visible="showCreateWorkDialog" :lesson-id="selectedLesson.id" @success="onCreateWorkSuccess"></create-work-dialog>

		<!-- <update-work-info-dialog v-if="showUpdateWorkInfoDialog" v-model:visible="showUpdateWorkInfoDialog" :work="selectedWork" @success="onUpdateWorkInfoSuccess"></update-work-info-dialog> -->
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import lessonAPI from '../../api/lesson'
import workAPI from '../../api/work'
import CreateLessonDialog from '../../components/lesson/CreateLessonDialog.vue'
import UpdateLessonInfoDialog from '../../components/lesson/UpdateLessonInfoDialog.vue'
import UpdateLessonNumberDialog from '../../components/lesson/UpdateLessonNumberDialog.vue'
import CreateWorkDialog from '../../components/work/CreateWorkDialog.vue'
import UpdateWorkInfoDialog from '../../components/work/UpdateWorkInfoDialog.vue'
import { LessonFilter } from '../../filters/lessonFilter'
import { Query, QueryImpl } from '../../types/common/query'
import { LessonFilters, LessonFiltersImpl } from '../../types/common/query/filters/lessonFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher/index'
import { LessonSearcherImpl } from '../../types/common/query/searcher/lessonSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Lesson, LessonImpl, MoveLessonBackwardImpl, MoveLessonForwardImpl, SimpleLesson } from '../../types/lesson'
import ItPlaceholder from '../ui/ItPlaceholder.vue'
import { CreateWorkImpl, Work } from '../../types/work'

const props = defineProps<{
  courseId: string
}>()

const loading = ref<boolean>(false)

const lessons = ref<SimpleLesson[]>()

onMounted(() => {
	doListLesson()
})

// Query lesson
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<LessonFilters>(new LessonFiltersImpl(props.courseId))
const searcher = reactive<Searcher>(new LessonSearcherImpl())
const sorts = [new SortImpl('number', Direction.ASC)]

const doListLesson = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	lessonAPI.list(query).then((response: AxiosResponse<Result<ListObject<SimpleLesson>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			lessons.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListLesson()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListLesson()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListLesson()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListLesson()
}

// Create lesson
const showCreateLessonDialog = ref<boolean>(false)
const onCreateLessonSuccess = function () {
	ElMessage.success('Success to create')
	pagination.gotoLastPage()
	doListLesson()
}

let selectedLesson = ref<Lesson>(new LessonImpl(''))
let selectedWork = ref<Work>()

// Edit lesson
const showUpdateLessonInfoDialog = ref<boolean>(false)
const doShowUpdateLessonInfoDialog = function (lesson: Lesson) {
	selectedLesson.value = lesson
	showUpdateLessonInfoDialog.value = true
}
const onUpdateLessonInfoSuccess = function () {
	ElMessage.success('Success to edit')
	doListLesson()
}

const onWorkButtonClick = function (lesson: Lesson) {
	if(lesson.workId) {
		workAPI.findById(lesson.workId).then((response: AxiosResponse<Result<Work>>) => {
			const result = response.data
			if (result.success) {
				doShowUpdateWorkInfoDialog(result.data)
			} else {
				ElMessage.error(result.message)
			}						
		}).catch((error: AxiosError) => {
			ElMessage.error(error.message)
		})
		
	} else {
		doShowCreateWorkDialog(lesson)
	}
}

// Create work
const showCreateWorkDialog = ref<boolean>(false)
const doShowCreateWorkDialog = function (lesson: Lesson) {
	selectedLesson.value = lesson
	showCreateWorkDialog.value = true
}
const onCreateWorkSuccess = function () {
	ElMessage.success('Success to create work')
	doListLesson()
}

// Update work
const showUpdateWorkInfoDialog = ref<boolean>(false)
const doShowUpdateWorkInfoDialog = function (work: Work) {
	selectedWork.value = work
	showUpdateWorkInfoDialog.value = true
}
const onUpdateWorkInfoSuccess = function () {
	ElMessage.success('Success to update work info')
	doListLesson()
}

// Update lesson number
const showUpdateLessonNumberDialog = ref<boolean>(false)
const doShowUpdateLessonNumberDialog = function (lesson: Lesson) {
	selectedLesson.value = lesson
	showUpdateLessonNumberDialog.value = true
}
const onUpdateLessonNumberSuccess = function () {
	ElMessage.success('Success to update number')
	doListLesson()
}

// Move up lesson 
const doMoveLessonForward = function (lesson: Lesson) {
	loading.value = true
	const moveLessonForward = new MoveLessonForwardImpl(lesson)
	lessonAPI.moveForward(moveLessonForward).then((response: AxiosResponse<Result<Lesson>>) => {
		const result = response.data
		if (result.success) {
			doListLesson()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Move down lesson
const doMoveLessonBackward = function (lesson: Lesson) {
	loading.value = true
	const moveLessonBackward = new MoveLessonBackwardImpl(lesson)
	lessonAPI.moveBackward(moveLessonBackward).then((response: AxiosResponse<Result<Lesson>>) => {
		const result = response.data
		if (result.success) {
			doListLesson()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Delete lesson
const doDeleteLesson = function (id: string) {
	loading.value = true
	lessonAPI.deleteById(id).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete')
			doListLesson()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

const onTableExpandChange = function (lesson: SimpleLesson, expandedLessons: SimpleLesson[]) {
	if (expandedLessons.includes(lesson)) {
		lessonAPI.findById(lesson.id).then((response: AxiosResponse<Result<Lesson>>) => {
			const result = response.data
			if (result.success) {
				lesson.content = result.data.content
			} else {
				ElMessage.error(result.message)
			}
		}).catch((error: AxiosError) => {
			ElMessage.error(error.message)
		})
	}
}
</script>