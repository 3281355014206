<template>
  <div class="content">
		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item>
					<el-select v-model="filters.locked" placeholder="Status">
						<el-option v-for="option in AgentLockedOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-form :inline="true" :model="searcher">
				<el-form-item>
					<el-input v-model="searcher.keyword" class="input-with-select">
						<template #prepend>
							<el-select v-model="searcher.field" placeholder="Field" style="width: 120px;">
								<el-option label="Name" value="name"></el-option>
								<el-option label="Mobile" value="mobile"></el-option>
							</el-select>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item style="margin-right: 0;">
					<el-button type="primary" @click="doSearch">Search</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="agents" v-loading="loading"  border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column label="Name" prop="name" />
			<el-table-column label="Region" prop="region" />         
			<el-table-column label="Mobile" prop="fullMobile" />
			<el-table-column label="Balance" prop="balance">
				<template #default="scope">{{NumberFilter.money(scope.row.balance)}}</template>
			</el-table-column>
			<el-table-column label="Status" prop="locked">
				<template #default="scope">{{AgentFilter.locked(scope.row.locked)}}</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import agentAPI from '../../api/agent'
import { AgentFilter } from '../../filters/agentFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { Agent } from '../../types/agent'
import { AgentLockedOptions } from '../../types/common/option/agentOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { ChildAgentFilters, ChildAgentFiltersImpl } from '../../types/common/query/filters/childAgentFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { AgentSearcherImpl } from '../../types/common/query/searcher/agentSearcher'
import { Searcher } from '../../types/common/query/searcher/index'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'

const props = defineProps<{
  parentAgentId: string
}>()

const loading = ref<boolean>(false)
const router = useRouter()

const agents = ref<Agent[]>()

onMounted(() => {
	doListAgent()
})

// Query agent
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<ChildAgentFilters>(new ChildAgentFiltersImpl(props.parentAgentId))
const searcher = reactive<Searcher>(new AgentSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const doListAgent = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	agentAPI.list(query).then((response: AxiosResponse<Result<ListObject<Agent>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			agents.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListAgent()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListAgent()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListAgent()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListAgent()
}
</script>