import { Type } from "../types/coupon"

export class CouponFilter {
    static type (type?: Type): string {
        switch (type) {
            case Type.GENERAL:
                return 'General'
            case Type.SPECIFIC:
                return 'Specific'
            default:
                return 'Undefined' 
        }
    }

    static published (published: boolean | undefined): string {
        if (published == undefined) return ''
        else return published? 'Published' : 'Draft'
    }
}