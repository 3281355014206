import { createStore, useStore as baseUseStore, Store } from 'vuex'
import { Logined } from '../types/auth'
import { State, StateImpl } from '../types/common/state'
import storage from '../storage'
import { InjectionKey } from 'vue/dist/vue.js'
import * as Sentry from '@sentry/browser'

export enum Mutations {
    LOGIN = "login",
    LOGOUT = "logout",
    TOGGLE_MENU_COLLAPSE = "toggleMenuCollapse"
}

export enum Actions {
    LOGIN = "login",
    LOGOUT = "logout",
    TOGGLE_MENU_COLLAPSE = "toggleMenuCollapse"
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore({
    strict: true,
    state: new StateImpl(),
    getters: {
        isLogin: (state: State): boolean => {
            return state.accessToken !== undefined
        }
    },
    // 同步动作
    mutations: {
        // 登录
        [Mutations.LOGIN] (state: State, logined: Logined) {
            state.staff = logined.staff
            storage.setStaff(state.staff)

            state.accessToken = logined.access_token
            storage.setAccessToken(state.accessToken)

            state.refreshToken = logined.refresh_token
            storage.setRefreshToken(state.refreshToken)

            Sentry.setUser({
                id: state.staff.id,
                email: state.staff.email,
                username: state.staff.name
            })
        },
        // 退出
        [Mutations.LOGOUT] (state: State) {
            state.staff = undefined
            state.accessToken = undefined
            state.refreshToken = undefined
            state.menuCollapse = undefined

            storage.clean()

            Sentry.configureScope(scope => scope.setUser(null))
        },
        // 设置是否打开菜单
        [Mutations.TOGGLE_MENU_COLLAPSE] (state: State) {
            state.menuCollapse = !state.menuCollapse
            storage.setMenuCollapse(state.menuCollapse)
        }
    },
    // 异步动作
    actions: {
        async [Actions.LOGIN] ({ commit }, logined: Logined) {
            commit(Mutations.LOGIN, logined)
        },
        async [Actions.LOGOUT] ({ commit }) {
            commit(Mutations.LOGOUT)
        },
        async [Actions.TOGGLE_MENU_COLLAPSE] ({ commit }) {
            commit(Mutations.TOGGLE_MENU_COLLAPSE)
        }
    }
})

export function useStore () {
    return baseUseStore(key)
}
