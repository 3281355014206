<template>
  <div class="content">
    <div class="actionbar">
			<div>Invitation Poster</div>
			<el-button-group>
				<el-button text type="primary" :loading="generating" @click="doGenerateAgentPoster">Generate Poster</el-button>
			</el-button-group>
		</div>

    <el-divider />

    <el-image v-if="agent.poster" v-loading="generating" style="width: 100%" :src="agent.poster" fit="contain" />
    <el-empty v-else description="No poster yet">
      <el-button type="primary" :loading="generating" @click="doGenerateAgentPoster">Generate</el-button>
    </el-empty>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios';
import { ElMessage } from 'element-plus';
import { onMounted, reactive, ref } from 'vue';
import agentAPI from '../../api/agent';
import { Agent, AgentImpl, GenerateAgentPosterImpl } from '../../types/agent';
import { Result } from '../../types/common/result';
import { cloneFrom } from '../../utils/clone';

const props = defineProps<{
  agentId: string
}>()

// 查询代理
const agent = reactive<Agent>(new AgentImpl(props.agentId))
const doFindAgent = function () {
  agentAPI.findById(props.agentId).then((response: AxiosResponse<Result<Agent>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(agent, result.data)
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  })
}

// 生成海报
const generating = ref<boolean>(false)
const doGenerateAgentPoster = function () {
  const generateAgentPoster = new GenerateAgentPosterImpl(agent.id)
  generating.value = true
  agentAPI.generatePoster(generateAgentPoster).then((response: AxiosResponse<Result<Agent>>) => {
    const result = response.data
    if (result.success) {
      agent.poster = result.data.poster
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() =>  {
    generating.value = false
  })
}

onMounted(() => {
  doFindAgent()
})
</script>