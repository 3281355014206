<template>
  <el-dialog v-model="show" title="Create Support Teacher" @opened="onOpened">
    <el-form ref="createSupportTeacherForm" :model="createSupportTeacher" label-width="100px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Name" required>
				<el-form-item prop="firstName" required>
					<el-input v-model="createSupportTeacher.firstName" placeholder="Firest Name" style="width: 200px;"></el-input>
				</el-form-item>
				<el-form-item prop="lastName" required>
					<el-input v-model="createSupportTeacher.lastName" placeholder="Last Name" style="width: 200px; margin-left: 10px;"></el-input>
				</el-form-item>
			</el-form-item>
			<el-form-item label="Nickname" prop="nickname" required>
				<el-input v-model="createSupportTeacher.nickname" style="width: 410px;"></el-input>
			</el-form-item>
			<el-form-item label="Email" prop="email" required>
				<el-input v-model="createSupportTeacher.email"></el-input>
			</el-form-item>
			<el-form-item label="Time Zone" prop="zoneId" required>
				<el-select v-model="createSupportTeacher.zoneId" filterable>
					<el-option v-for="(availableZoneId, index) in availableZoneIds" :key="index" :label="availableZoneId" :value="availableZoneId"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Avatar" prop="avatar" required>
				<el-upload :action="uploadTeacherAvatarUrl" :headers="headers" :multiple="false" :show-file-list="false" accept="image/*" :before-upload="beforeUploadTeacherAvatar" :on-success="onUploadTeacherAvatarSuccess" :on-error="onUploadTeacherAvatarError">
					<el-button type="primary">Upload Image</el-button>
					<template #tip>
						<div class="el-upload__tip">Image size should less than 1M</div>
					</template>
				</el-upload>
				<div class="error" v-if="teacherAvatarMessage">{{ teacherAvatarMessage }}</div>
			</el-form-item>
			<el-form-item>
				<el-image v-if="createSupportTeacher.avatar" :src="createSupportTeacher.avatar" fit="contain" style="width: 100px;" />
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doCreateSupportTeacher">Create</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import teacherAPI from '../../api/teacher'
import zoneIdAPI from '../../api/zoneId'
import { Result } from '../../types/common/result'
import { UploadHeadersImpl } from '../../types/common/upload'
import { CreateSupportTeacher, CreateSupportTeacherImpl, Teacher } from '../../types/teacher'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const availableZoneIds = ref<string[]>()
const doFindAvailableZoneIds = function () {
	zoneIdAPI.findAvailable().then((response: AxiosResponse<Result<string[]>>) => {
		const result = response.data
		if (result.success) {
			availableZoneIds.value = result.data
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const headers = reactive(new UploadHeadersImpl())

// upload teacher avatar
const uploadTeacherAvatarUrl = ref<string>(teacherAPI.uploadAvatar)

const teacherAvatarMessage = ref<string>('')
const beforeUploadTeacherAvatar = function (file: File) {
	if (file && file.size > 1024 * 1024) {
		teacherAvatarMessage.value = 'Please upload photo which size is less than 1M'
		return false
	}
}

const onUploadTeacherAvatarSuccess = function (result: Result<URL>) {
	if (result.success) {
		createSupportTeacher.avatar = result.data.toString()
	} else {
		teacherAvatarMessage.value = result.message
	}
}

const onUploadTeacherAvatarError = function (error: Error) {
	if (error) teacherAvatarMessage.value = error.message
}

// create support teacher
const createSupportTeacher = reactive<CreateSupportTeacher>(new CreateSupportTeacherImpl())
const createSupportTeacherForm = ref<InstanceType<typeof ElForm>>()

const doCreateSupportTeacher = function () {
	createSupportTeacherForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			teacherAPI.createSupport(createSupportTeacher).then((response: AxiosResponse<Result<Teacher>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const onOpened = function () {
	doFindAvailableZoneIds()
}
</script>