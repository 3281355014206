<template>
  <el-dialog v-model="show" title="Create Enrollment" @opened="onOpened">
    <el-form ref="createEnrollmentForm" :model="createEnrollment" label-width="100px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Student" prop="studentId" required>
				<el-select v-model="createEnrollment.studentId" filterable>
					<el-option v-for="student in students" :key="student.id" :label="student.nickname" :value="student.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Serie" prop="serieId" required>
				<el-select v-model="createEnrollment.serieId" filterable @change="onSelectSerie">
					<el-option v-for="serie in series" :key="serie.id" :label="serie.name" :value="serie.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Course" prop="courseId" required>
				<el-select v-model="createEnrollment.courseId" filterable>
					<el-option v-for="course in courses" :key="course.id" :label="course.name" :value="course.id"></el-option>
				</el-select>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doCreateEnrollment">Create</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import courseAPI from '../../api/course'
import enrollmentAPI from '../../api/enrollment'
import serieAPI from '../../api/serie'
import studentAPI from '../../api/student'
import { Result } from '../../types/common/result'
import { CreateEnrollment, CreateEnrollmentImpl, Enrollment } from '../../types/enrollment'
import { Serie } from '../../types/serie'
import { Student } from '../../types/student'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	parentId: string
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

// Find students
const students = ref<Student[]>()
const doFindStudent = function () {
	studentAPI.findByParentId(props.parentId).then((response: AxiosResponse<Result<Student[]>>) => {
		const result = response.data
		if (result.success) {
			students.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// Find Series
const series = ref<Serie[]>()
const doFindAllSerie = function () {
	serieAPI.findAll().then((response: AxiosResponse<Result<Serie[]>>) => {
		const result = response.data
		if (result.success) {
			series.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}
const onSelectSerie = function () {
	doFindCourse(createEnrollment.serieId!)
}

// Find Courses
const courses = ref<Serie[]>()
const doFindCourse = function (serieId: string) {
	courseAPI.findBySerieId(serieId).then((response: AxiosResponse<Result<Serie[]>>) => {
		const result = response.data
		if (result.success) {
			courses.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const createEnrollment = reactive<CreateEnrollment>(new CreateEnrollmentImpl(props.parentId))
const createEnrollmentForm = ref<InstanceType<typeof ElForm>>()

const doCreateEnrollment = function () {
	createEnrollmentForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			enrollmentAPI.create(createEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					createEnrollmentForm.value?.resetFields()
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const onOpened = function () {
	doFindStudent()
	doFindAllSerie()
}
</script>