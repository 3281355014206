<template>
  <div class="content">
		<div class="actionbar">
			<div>Serie List</div>
			<el-button-group>
				<el-button text type="primary" @click="showCreateSerieDialog = true">Create Serie</el-button>
			</el-button-group>
		</div>

    <el-divider />

		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item>
					<el-select v-model="filters.subject" placeholder="Subject">
						<el-option v-for="option in SerieSubjectOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.major" placeholder="Major">
						<el-option v-for="option in SerieMajorOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-form :inline="true" :model="searcher">
				<el-form-item>
					<el-input v-model="searcher.keyword" class="input-with-select">
						<template #prepend>
							<el-select v-model="searcher.field" placeholder="Field" style="width: 120px;">
								<el-option label="Name" value="name"></el-option>
							</el-select>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item style="margin-right: 0;">
					<el-button type="primary" @click="doSearch">Search</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="series" v-loading="loading"  border class="table" row-key="id">
			<el-table-column label="Number" prop="number" width="80px" />
			<el-table-column label="Name" prop="name" />
			<el-table-column label="Subject" prop="subject">
				<template #default="scope">{{SerieFilter.subject(scope.row.subject)}}</template>
			</el-table-column>            
			<el-table-column label="Major" prop="major">
				<template #default="scope">{{SerieFilter.major(scope.row.major)}}</template>
			</el-table-column> 
			<el-table-column label="Visibility" prop="hidden">
				<template #default="scope">{{SerieFilter.hidden(scope.row.hidden)}}</template>
			</el-table-column> 
			<el-table-column label="Actions" fixed="right" width="450">
				<template #default="scope">
					<el-button-group>
						<el-button text type="primary" @click="doShowUpdateSerieInfoDialog(scope.row)">Edit</el-button>
						<el-button text type="primary" @click="doMoveSerieForward(scope.row)" :disabled="scope.row.number == 0">Move Up</el-button>
						<el-button text type="primary" @click="doMoveSerieBackward(scope.row)" :disabled="scope.row.number + 1 == pagination.total">Move Down</el-button>
						<el-popconfirm :title="scope.row.hidden? 'Are you sure to set the serie as visible?' : 'Are you sure to set the serie as hidden?'" @confirm="doToggleSerieHidden(scope.row.id)">
							<template #reference>
								<el-button text type="primary" class="action-button">{{ scope.row.hidden? 'Set as Visible' : 'Set as Hidden' }}</el-button>
							</template>
						</el-popconfirm>
						<el-popconfirm title="Are you sure to delete the serie?" @confirm="doDeleteSerie(scope.row.id)">
							<template #reference>
								<el-button text type="primary">Delete</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model="pagination.currentPage" layout="total, prev, pager, next" />
  
		<create-serie-dialog v-if="showCreateSerieDialog" v-model:visible="showCreateSerieDialog" @success="onCreateSerieSuccess"></create-serie-dialog>

		<update-serie-info-dialog v-if="showUpdateSerieInfoDialog" v-model:visible="showUpdateSerieInfoDialog" :serie="selectedSerie" @success="onUpdateSerieInfoSuccess"></update-serie-info-dialog>
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import serieAPI from '../../api/serie'
import CreateSerieDialog from '../../components/serie/CreateSerieDialog.vue'
import UpdateSerieInfoDialog from '../../components/serie/UpdateSerieInfoDialog.vue'
import { SerieFilter } from '../../filters/serieFilter'
import { SerieMajorOptions, SerieSubjectOptions } from '../../types/common/option/serieOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { SerieFilters, SerieFiltersImpl } from '../../types/common/query/filters/serieFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher/index'
import { SerieSearcherImpl } from '../../types/common/query/searcher/serieSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { MoveSerieBackwardImpl, MoveSerieForwardImpl, Serie, SerieImpl, ToggleSerieHidden, ToggleSerieHiddenImpl } from '../../types/serie'

const loading = ref<boolean>(false)

const series = ref<Serie[]>()

onMounted(() => {
	doListSerie()
})

// Query serie
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<SerieFilters>(new SerieFiltersImpl())
const searcher = reactive<Searcher>(new SerieSearcherImpl())
const sorts = [new SortImpl('number', Direction.ASC)]

const doListSerie = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	serieAPI.list(query).then((response: AxiosResponse<Result<ListObject<Serie>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			series.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListSerie()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListSerie()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListSerie()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListSerie()
}

// Create serie
const showCreateSerieDialog = ref<boolean>(false)
const onCreateSerieSuccess = function () {
	ElMessage.success('Success to create')
	doListSerie()
}

let selectedSerie = ref<Serie>(new SerieImpl(''))

// Edit serie
const showUpdateSerieInfoDialog = ref<boolean>(false)
const doShowUpdateSerieInfoDialog = function (serie: Serie) {
	selectedSerie.value = serie
	showUpdateSerieInfoDialog.value = true
}
const onUpdateSerieInfoSuccess = function () {
	ElMessage.success('Success to edit')
	doListSerie()
}

// Set serie as Hidden/Visible
const doToggleSerieHidden = function (id: string) {
	const toggleSerieHidden: ToggleSerieHidden = new ToggleSerieHiddenImpl(id)
	loading.value = true
	serieAPI.toggleHidden(toggleSerieHidden).then((response: AxiosResponse<Result<Serie>>) => {
		const result = response.data
		if (result.success) {
			const serie = result.data
			ElMessage.success(serie.hidden? 'Success to set serie as hidden' : 'Success to set serie as visible')
			doListSerie()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

		// Move up serie 
const doMoveSerieForward = function (serie: Serie) {
	loading.value = true
	const moveSerieForward = new MoveSerieForwardImpl(serie)
	serieAPI.moveForward(moveSerieForward).then((response: AxiosResponse<Result<Serie>>) => {
		const result = response.data
		if (result.success) {
			doListSerie()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Move down serie
const doMoveSerieBackward = function (serie: Serie) {
	loading.value = true
	const moveSerieBackward = new MoveSerieBackwardImpl(serie)
	serieAPI.moveBackward(moveSerieBackward).then((response: AxiosResponse<Result<Serie>>) => {
		const result = response.data
		if (result.success) {
			doListSerie()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Delete serie
const doDeleteSerie = function (id: string) {
	loading.value = true
	serieAPI.deleteById(id).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete')
			doListSerie()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}
</script>