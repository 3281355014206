import { DateType } from "../../../enums"
import { Status } from "../../../recruitment"
import { Filters } from "./index"

export interface RecruitmentFilters extends Filters {
	agentId?: string
	parentAgentId?: string
	dateType: DateType
	signupDateTime?: number | string
	rechargeDateTime?: number | string
	status?: Status
}

export class RecruitmentFiltersImpl implements RecruitmentFilters {
	agentId?: string
	parentAgentId?: string
	dateType: DateType
	signupDateTime?: number | string
	rechargeDateTime?: number | string
	status?: Status

	constructor(agentId?: string, parentAgentId?: string) {
		this.agentId = agentId
		this.parentAgentId = parentAgentId
		this.dateType = DateType.RANGE_MONTH
		this.signupDateTime = ''
		this.rechargeDateTime = ''
	}

	reset(): void {
		this.signupDateTime = ''
		this.rechargeDateTime = ''
		this.status = undefined
	}
}