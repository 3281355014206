<template>
  <el-dialog v-model="show" title="Create Module">
    <el-form ref="createModuleForm" :model="createModule" label-width="60px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Name" prop="name" required>
				<el-input v-model="createModule.name"></el-input>
			</el-form-item>
			<el-form-item label="Content" prop="content">
				<div style="width: 100%; height: 300px; line-height: normal;">
					<quill-editor v-model:content="createModule.content" theme="snow" contentType="html"></quill-editor>
				</div>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doCreateModule">Create</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import moduleAPI from '../../api/module'
import { Result } from '../../types/common/result'
import { CreateModule, CreateModuleImpl, Module } from '../../types/module'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	courseId: string
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const createModule = reactive<CreateModule>(new CreateModuleImpl(props.courseId))
const createModuleForm = ref<InstanceType<typeof ElForm>>()

const doCreateModule = function () {
	createModuleForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true

			moduleAPI.create(createModule).then((response: AxiosResponse<Result<Module>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					createModuleForm.value?.resetFields()
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>