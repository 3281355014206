export const mobileValidator = function(rule: any, value: string, callback: any) {
	const reg = /^1[3456789]\d{9}$/
	if(reg.test(value)) {
		return callback()
	} else {
		return callback(new Error('Please input currect mobile number'))
	}
} 

export const emailValidator = function(rule: any, value: string, callback: any) {
	const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/i
	if(reg.test(value)) {
		return callback()
	} else {
		return callback(new Error('Please input currect email'))
	}
}

export const passwordValidator = function(rule: any, value: string, callback: any) {
	const reg = /^[a-zA-Z0-9]{6,50}$/
	if(reg.test(value)) {
		return callback()
	} else {
		return callback(new Error('Please input currect password'))
	}
}

export const urlValidator = function(rule: any, value: string, callback: any) {
	try {
		new URL(value)
		return callback()
	} catch (error) {
		return callback(new Error('Please input currect url'))
	}
}

export const teacherNameValidator = function(rule: any, value: string, callback: any) {
	if(value) {
		return callback()
	} else {
		return callback(new Error('Please input currect name'))
	}
}