<template>
  <el-dialog v-model="show" title="Edit Staff">
    <el-form ref="updateStaffInfoForm" :model="updateStaffInfo" label-width="80px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Name" prop="name" required>
				<el-input v-model="updateStaffInfo.name"></el-input>
			</el-form-item>
			<el-form-item label="Email" prop="email" :rules="[{required: true, validator: emailValidator, trigger: 'blur'}]">
				<el-input v-model="updateStaffInfo.email"></el-input>
			</el-form-item>
			<el-form-item label="Role" prop="role" required>
				<el-radio-group v-model="updateStaffInfo.role">
					<el-radio v-for="option in StaffRoleOptions.options" :key="option.label" :label="option.value">{{ option.label }}</el-radio>
				</el-radio-group>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateStaffInfo">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import staffAPI from '../../api/staff'
import { StaffRoleOptions } from '../../types//common/option/staffOptions'
import { Result } from '../../types/common/result'
import { Staff, UpdateStaffInfo, UpdateStaffInfoImpl } from '../../types/staff'
import { useModelWrapper } from '../../utils/modelWrapper'
import { emailValidator } from '../../validators'

const props = defineProps<{
  visible: boolean,
	staff: Staff
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

let updateStaffInfo = reactive<UpdateStaffInfo>(new UpdateStaffInfoImpl(props.staff))

const updateStaffInfoForm = ref<InstanceType<typeof ElForm>>()

const doUpdateStaffInfo = function () {
	updateStaffInfoForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			staffAPI.updateInfo(updateStaffInfo).then((response: AxiosResponse<Result<Staff>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>