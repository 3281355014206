<template>
  <el-dialog v-model="show" title="Create Teacher" @opened="onOpened">
    <el-form ref="createTeacherForm" :model="createTeacher" label-width="120px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Name" required>
				<el-form-item prop="firstName" required>
					<el-input v-model="createTeacher.firstName" placeholder="Firest Name" style="width: 200px;"></el-input>
				</el-form-item>
				<el-form-item prop="lastName" required>
					<el-input v-model="createTeacher.lastName" placeholder="Last Name" style="width: 200px; margin-left: 10px;"></el-input>
				</el-form-item>
			</el-form-item>
			<el-form-item label="Nickname" prop="nickname" required>
				<el-input v-model="createTeacher.nickname" style="width: 410px;"></el-input>
			</el-form-item>
			<el-form-item label="Mobile" prop="fullMobile" required>
				<el-input v-model="createTeacher.mobile" class="input-with-select" @blur="onMobileInputBlur">
					<template #prepend>
						<el-select v-model="createTeacher.region" placeholder="Country Code" style="width: 190px;" filterable @change="onRegionSelectChange">
							<el-option v-for="phoneCountryCode in phoneCountryCodes" :key="phoneCountryCode.region" :label="phoneCountryCode.country" :value="phoneCountryCode.region"></el-option>
						</el-select>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item label="Email" prop="email" required>
				<el-input v-model="createTeacher.email"></el-input>
			</el-form-item>
			<el-form-item label="Time Zone" prop="zoneId" required>
				<el-select v-model="createTeacher.zoneId" filterable>
					<el-option v-for="(availableZoneId, index) in availableZoneIds" :key="index" :label="availableZoneId" :value="availableZoneId"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Identity" prop="vendor" required>
				<el-radio-group v-model="createTeacher.vendor">
					<el-radio v-for="option in TeacherVendorOptions.options" :key="option.label" :label="option.value">{{ option.label }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="Country" prop="country" required>
				<el-select v-model="createTeacher.country" filterable>
					<el-option v-for="country in countries" :key="country.code" :label="country.name" :value="country.code"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Display Country" prop="displayCountry" required>
				<el-radio-group v-model="createTeacher.displayCountry">
					<el-radio v-for="option in CountryOptions.options" :key="option.label" :label="option.value">{{ option.label }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="Accent" prop="accent" required>
				<el-radio-group v-model="createTeacher.accent">
					<el-radio v-for="option in AccentOptions.options" :key="option.label" :label="option.value">{{ option.label }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="Avatar" prop="avatar" required>
				<el-upload :action="uploadTeacherAvatarUrl" :headers="headers" :multiple="false" :show-file-list="false" accept="image/*" :before-upload="beforeUploadTeacherAvatar" :on-success="onUploadTeacherAvatarSuccess" :on-error="onUploadTeacherAvatarError">
					<el-button type="primary">Upload Image</el-button>
					<template #tip>
						<div class="el-upload__tip">Image size should less than 1M</div>
					</template>
				</el-upload>
				<div class="error" v-if="teacherAvatarMessage">{{ teacherAvatarMessage }}</div>
			</el-form-item>
			<el-form-item>
				<el-image v-if="createTeacher.avatar" :src="createTeacher.avatar" fit="contain" style="width: 100px;" />
			</el-form-item>
			<el-form-item label="Show" prop="show" required>
				<el-upload :action="uploadTeacherShowUrl" :headers="headers" :multiple="false" :show-file-list="false" accept="video/*" :before-upload="beforeUploadTeacherShow" :on-success="onUploadTeacherShowSuccess" :on-error="onUploadTeacherShowError">
					<el-button type="primary">Upload Video</el-button>
					<template #tip>
						<div class="el-upload__tip">Video size should less than 50M</div>
					</template>
				</el-upload>
				<div class="error" v-if="teacherShowMessage">{{ teacherShowMessage }}</div>
			</el-form-item>
			<el-form-item>
				<video v-if="createTeacher.show" :src="createTeacher.show" controls preload="auto" style="width: 50%;"></video>
			</el-form-item>
			<el-form-item label="Introduction" prop="introduction" required>
				<el-input type="textarea" v-model="createTeacher.introduction" :rows="5"></el-input>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doCreateTeacher">Create</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElAlert, ElButton, ElDialog, ElForm, ElFormItem, ElImage, ElInput, ElMessage, ElOption, ElRadio, ElRadioGroup, ElSelect, ElUpload } from 'element-plus'
import { reactive, ref } from 'vue'
import countryAPI from '../../api/country'
import mobileAPI from '../../api/mobile'
import teacherAPI from '../../api/teacher'
import zoneIdAPI from '../../api/zoneId'
import { AccentOptions, CountryOptions } from '../../types/common/option/enumOptions'
import { TeacherVendorOptions } from '../../types/common/option/teacherOptions'
import { Result } from '../../types/common/result'
import { UploadHeadersImpl } from '../../types/common/upload'
import { Country } from '../../types/country'
import { PhoneCountryCode } from '../../types/mobile'
import { CreateTeacher, CreateTeacherImpl, Teacher } from '../../types/teacher'
import { getCountryCodeFromRegion, getFullMobile } from '../../utils/mobileUtils'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const availableZoneIds = ref<string[]>()
const doFindAvailableZoneIds = function () {
	zoneIdAPI.findAvailable().then((response: AxiosResponse<Result<string[]>>) => {
		const result = response.data
		if (result.success) {
			availableZoneIds.value = result.data
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// phone country code
const phoneCountryCodes = ref<PhoneCountryCode[]>()
const doFindAllPhoneCountryCodes = function () {
	mobileAPI.findAllCountryCode().then((response: AxiosResponse<Result<PhoneCountryCode[]>>) => {
		const result = response.data
		if (result.success) {
			phoneCountryCodes.value = result.data
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// country
const countries = ref<Country[]>()
const doFindAllCountries = function () {
	countryAPI.findAll().then((response: AxiosResponse<Result<Country[]>>) => {
		const result = response.data
		if (result.success) {
			countries.value = result.data
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const headers = reactive(new UploadHeadersImpl())

// upload teacher avatar
const uploadTeacherAvatarUrl = ref<string>(teacherAPI.uploadAvatar)

const teacherAvatarMessage = ref<string>('')
const beforeUploadTeacherAvatar = function (file: File) {
	if (file && file.size > 1024 * 1024) {
		teacherAvatarMessage.value = 'Please upload photo which size is less than 1M'
		return false
	}
}

const onUploadTeacherAvatarSuccess = function (result: Result<URL>) {
	if (result.success) {
		createTeacher.avatar = result.data.toString()
	} else {
		teacherAvatarMessage.value = result.message
	}
}

const onUploadTeacherAvatarError = function (error: Error) {
	if (error) teacherAvatarMessage.value = error.message
}

// upload teacher show
const uploadTeacherShowUrl = ref<string>(teacherAPI.uploadShow)

const teacherShowMessage = ref<string>('')
const beforeUploadTeacherShow = function (file: File) {
	if (file && file.size > 1024 * 1024 * 50) {
		teacherShowMessage.value = 'Please upload video which size is less than 50M'
		return false
	}
}

const onUploadTeacherShowSuccess = function (result: Result<URL>) {
	if (result.success) {
		createTeacher.show = result.data.toString()
	} else {
		teacherShowMessage.value = result.message
	}
}

const onUploadTeacherShowError = function (error: Error) {
	if (error) teacherShowMessage.value = error.message
}

// create teacher
const createTeacher = reactive<CreateTeacher>(new CreateTeacherImpl())
const createTeacherForm = ref<InstanceType<typeof ElForm>>()

const doCreateTeacher = function () {
	createTeacherForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			teacherAPI.create(createTeacher).then((response: AxiosResponse<Result<Teacher>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const onRegionSelectChange = function () {
	setFullMobile()
}

const onMobileInputBlur = function () {
	setFullMobile()
}

const setFullMobile = function () {
	createTeacher.countryCode = getCountryCodeFromRegion(phoneCountryCodes.value, createTeacher.region)
	createTeacher.fullMobile = getFullMobile(createTeacher.countryCode, createTeacher.mobile)
}

const onOpened = function () {
	doFindAvailableZoneIds()
	doFindAllPhoneCountryCodes()
	doFindAllCountries()
}
</script>