<template>
  <div class="content">
		<div class="actionbar">
			<div>Student Avatar List</div>
			<el-button-group>
				<el-button text type="primary" @click="showCreateStudentAvatarDialog = true">Create Student Avatar</el-button>
			</el-button-group>
		</div>

    <el-divider />

		<div style="display: grid; grid-template-columns: repeat(auto-fill, 100px);">
			<div v-for="(studentAvatar, index) in studentAvatars" :key="index">
				<student-avatar-card :student-avatar="studentAvatar" @delete="doDeleteStudentAvatar"></student-avatar-card>
			</div>
		</div>

		<create-student-avatar-dialog v-if="showCreateStudentAvatarDialog" v-model:visible="showCreateStudentAvatarDialog" @success="onCreateStudentAvatarSuccess"></create-student-avatar-dialog>

	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, ref } from 'vue'
import studentAvatarAPI from '../../api/studentAvatar'
import CreateStudentAvatarDialog from '../../components/avatar/CreateStudentAvatarDialog.vue'
import StudentAvatarCard from '../../components/avatar/StudentAvatarCard.vue'
import { Result } from '../../types/common/result'
import { StudentAvatar } from '../../types/studentAvatar'

const loading = ref<boolean>(false)

const studentAvatars = ref<StudentAvatar[]>()

onMounted(() => {
	doFindAllStudentAvatar()
})

const doFindAllStudentAvatar = function () {
	loading.value = true
	studentAvatarAPI.findAll().then((response: AxiosResponse<Result<StudentAvatar[]>>) => {
		const result = response.data
		if (result.success) {
			studentAvatars.value = result.data
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Create student avatar
const showCreateStudentAvatarDialog = ref<boolean>(false)
const onCreateStudentAvatarSuccess = function () {
	ElMessage.success('Success to create')
	doFindAllStudentAvatar()
}

// Delete student avatar
const doDeleteStudentAvatar = function (id: string) {
	loading.value = true
	studentAvatarAPI.deleteById(id).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete')
			doFindAllStudentAvatar()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}
</script>