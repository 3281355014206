<template>
  <div class="content">
		<slot></slot>

		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item>
					<el-select v-model="filters.status" placeholder="Status">
						<el-option v-for="option in WithdrawStatusOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.transactionStatus" placeholder="Transaction Status">
						<el-option v-for="option in WithdrawTransactionStatusOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-button-group>
				<el-button text type="primary" @click="showManualWithdrawDialog = true">Manual Withdraw</el-button>
			</el-button-group>
		</div>

		<el-table :data="withdraws" v-loading="loading"  border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column v-if="!teacher" label="Teacher" prop="teacherNickname" width="100"></el-table-column>
			<el-table-column label="Status" prop="status" width="110">
				<template #default="scope">{{WithdrawFilter.status(scope.row.status)}}</template>
			</el-table-column>
			<el-table-column label="Transaction Status" prop="transactionStatus" width="200">
				<template #default="scope">{{WithdrawFilter.transactionStatus(scope.row.transactionStatus)}}</template>
			</el-table-column>
			<el-table-column label="Value ($)" prop="value" width="100">
				<template #default="scope">{{NumberFilter.money(scope.row.value)}}</template>
			</el-table-column>
			<el-table-column label="Time" prop="dateTime" width="200">
				<template #default="scope">{{DateTimeFilter.datetime(scope.row.dateTime)}}</template>
			</el-table-column>
			<el-table-column label="Remark" prop="remark" />
			<el-table-column label="Actions" fixed="right" width="380">
				<template #default="scope">
					<el-button-group>
						<el-popconfirm title="Are you sure to approve the withdraw?" @confirm="doApproveWithdraw(scope.row)">
							<template #reference>
								<el-button text type="primary" :disabled="scope.row.status !== Status.REQUESTED">Approve</el-button>
							</template>
						</el-popconfirm>
						<el-button text type="primary" :disabled="scope.row.status !== Status.REQUESTED" @click="doShowRejectWithdrawDialog(scope.row)">Reject</el-button>
						<el-button text type="primary" :disabled="scope.row.status !== Status.APPROVED" @click="doRetrieveWithdrawTransactionStatus(scope.row)">Retrieve Transaction Status</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />
	</div>

	<reject-withdraw-dialog v-if="showRejectWithdrawDialog" v-model:visible="showRejectWithdrawDialog" :withdraw="selectedWithdraw" @success="onRejectWithdrawSuccess"></reject-withdraw-dialog>
	<manual-withdraw-dialog v-if="showManualWithdrawDialog" v-model:visible="showManualWithdrawDialog" :teacher="teacher" @success="onManualWithdrawSuccess"></manual-withdraw-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import withdrawAPI from '../../api/withdraw'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { WithdrawFilter } from '../../filters/withdrawFilter'
import { WithdrawStatusOptions, WithdrawTransactionStatusOptions } from '../../types/common/option/withdrawOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { WithdrawFilters, WithdrawFiltersImpl } from '../../types/common/query/filters/withdrawFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { WithdrawSearcherImpl } from '../../types/common/query/searcher/withdrawSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Teacher } from '../../types/teacher'
import { ApproveWithdrawImpl, RetrieveWithdrawTransactionStatusImpl, Status, Withdraw, WithdrawImpl } from '../../types/withdraw'
import ManualWithdrawDialog from './ManualWithdrawDialog.vue'
import RejectWithdrawDialog from './RejectWithdrawDialog.vue'

const props = defineProps<{
  teacher?: Teacher
}>()

const loading = ref<boolean>(false)

const withdraws = ref<Withdraw[]>()

onMounted(() => {
	doListWithdraw()
})

// Query withdraw
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<WithdrawFilters>(new WithdrawFiltersImpl())
const searcher = reactive<Searcher>(new WithdrawSearcherImpl())
const sorts = [new SortImpl('dateTime', Direction.DESC)]

const doListWithdraw = function () {
	if (props.teacher) filters.teacherId = props.teacher.id

	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	withdrawAPI.list(query).then((response: AxiosResponse<Result<ListObject<Withdraw>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			withdraws.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListWithdraw()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListWithdraw()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListWithdraw()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListWithdraw()
}

let selectedWithdraw = ref<Withdraw>(new WithdrawImpl(''))

// Approve withdraw
const doApproveWithdraw = function (withdraw: Withdraw) {
	const approveWithdraw = new ApproveWithdrawImpl(withdraw.id)
	withdrawAPI.approve(approveWithdraw).then((response: AxiosResponse<Result<Withdraw>>) => {
		const result = response.data
		if (result.success) {
			doListWithdraw()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// Reject withdraw
const showRejectWithdrawDialog = ref<boolean>(false)
const doShowRejectWithdrawDialog = function (withdraw: Withdraw) {
	selectedWithdraw.value = withdraw
	showRejectWithdrawDialog.value = true
}
const onRejectWithdrawSuccess = function () {
	ElMessage.success('Success to reject')
	doListWithdraw()
}

// Manual withdraw
const showManualWithdrawDialog = ref<boolean>(false)
const doShowManualWithdrawDialog = function (withdraw: Withdraw) {
	showManualWithdrawDialog.value = true
}
const onManualWithdrawSuccess = function () {
	ElMessage.success('Success to withdraw')
	doListWithdraw()
}

// Retrieve withdraw transaction status
const	doRetrieveWithdrawTransactionStatus = function (withdraw: Withdraw) {
	const retrieveWithdrawTransactionStatus = new RetrieveWithdrawTransactionStatusImpl(withdraw.id)
	withdrawAPI.retrieveTransactionStatus(retrieveWithdrawTransactionStatus).then((response: AxiosResponse<Result<Withdraw>>) => {
		const result = response.data
		if (result.success) {
			doListWithdraw()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const refresh = function () {
	doListWithdraw()
}

defineExpose({
	refresh
})
</script>

<style scoped>
.time {
	display: flex;
	justify-content: space-between;
}
</style>