<template>
  <arrangements ref="arrangementsComponent">
    <div class="actionbar">
			<div>Arrangement List</div>
      <el-button-group>
        <el-popconfirm title="Are you sure to book this week arrangement?" @confirm="doBookThisWeekArrangement()">
          <template #reference>
            <el-button text type="primary" :loading="loading">Book This Week Arrangement</el-button>
          </template>
        </el-popconfirm>
        <el-popconfirm title="Are you sure to book next week arrangement?" @confirm="doBookNextWeekArrangement()">
          <template #reference>
            <el-button text type="primary" :loading="loading">Book Next Week Arrangement</el-button>
          </template>
        </el-popconfirm>
			</el-button-group>
		</div>

    <el-divider />
  </arrangements>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { ref } from 'vue'
import arrangementAPI from '../../api/arrangement'
import Arrangements from '../../components/schedule/Arrangements.vue'
import { Result } from '../../types/common/result'

const loading = ref<boolean>(false)
const arrangementsComponent = ref<InstanceType<typeof Arrangements>>()

const doBookThisWeekArrangement = function () {
  loading.value = true
  arrangementAPI.bookThisWeek().then((response: AxiosResponse<Result<void>>) => {
    const result = response.data
    if (result.success) {
      ElMessage.success('success to arrange')
      arrangementsComponent.value?.doListArrangement()
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

const doBookNextWeekArrangement = function () {
  loading.value = true
  arrangementAPI.bookNextWeek().then((response: AxiosResponse<Result<void>>) => {
    const result = response.data
    if (result.success) {
      ElMessage.success('success to arrange')
      arrangementsComponent.value?.doListArrangement()
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}
</script>
