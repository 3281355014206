import { Status } from "../../../acquiredCoupon"
import { Filters } from "./index"

export interface AcquiredCouponFilters extends Filters {
	parentId?: string
	couponId?: string
	status?: Status
}

export class AcquiredCouponFiltersImpl implements AcquiredCouponFilters {
	parentId?: string
	couponId?: string
	status?: Status

	constructor(parentId?: string, couponId?: string) {
		this.parentId = parentId
		this.couponId = couponId
	}

	reset(): void {
		this.parentId = undefined
		this.couponId = undefined
		this.status = undefined
	}
}