<template>
  <el-dialog v-model="show" title="Edit Course Cover">
    <el-form ref="updateCourseCoverForm" :model="updateCourseCover">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item prop="cover" required>
				<el-upload :action="uploadCourseCoverUrl" :headers="headers" :multiple="false" :show-file-list="false" accept="image/*" :before-upload="beforeUploadCourseCover" :on-success="onUploadCourseCoverSuccess" :on-error="onUploadCourseCoverError">
					<el-button type="primary">Upload Image</el-button>
					<template #tip>
						<div class="el-upload__tip">Image size should less than 1M</div>
					</template>
				</el-upload>
				<div class="error" v-if="courseCoverMessage">{{ courseCoverMessage }}</div>
				<el-image v-if="updateCourseCover.cover" :src="updateCourseCover.cover" fit="contain" style="width: 100%;" />
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateCourseCover">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import courseAPI from '../../api/course'
import { Result } from '../../types/common/result'
import { UploadHeadersImpl } from '../../types/common/upload'
import { Course, UpdateCourseCover, UpdateCourseCoverImpl } from '../../types/course'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	course: Course
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const uploadCourseCoverUrl = ref<string>(courseAPI.uploadCover)
const headers = reactive(new UploadHeadersImpl())

const courseCoverMessage = ref<string>('')
const beforeUploadCourseCover = function (file: File) {
	if (file && file.size > 1024 * 1024) {
		courseCoverMessage.value = 'Please upload photo which size is less than 1M'
		return false
	}
}

const onUploadCourseCoverSuccess = function (result: Result<URL>) {
	if (result.success) {
		updateCourseCover.cover = result.data.toString()
	} else {
		courseCoverMessage.value = result.message
	}
}

const onUploadCourseCoverError = function (error: Error) {
	if (error) courseCoverMessage.value = error.message
}

const updateCourseCover = reactive<UpdateCourseCover>(new UpdateCourseCoverImpl(props.course))

const updateCourseCoverForm = ref<InstanceType<typeof ElForm>>()

const doUpdateCourseCover = function () {
	updateCourseCoverForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			courseAPI.updateCover(updateCourseCover).then((response: AxiosResponse<Result<Course>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>