<template>
  <div class="content">
		<slot></slot>
		
		<div class="query">
			<!-- filter -->
			<it-placeholder></it-placeholder>
			<!-- search -->
			<el-form :inline="true" :model="searcher">
				<el-form-item>
					<el-input v-model="searcher.keyword" class="input-with-select">
						<template #prepend>
							<el-select v-model="searcher.field" placeholder="Field" style="width: 120px;">
								<el-option label="Remark" value="remark"></el-option>
							</el-select>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item style="margin-right: 0;">
					<el-button type="primary" @click="doSearch">Search</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="earnings" v-loading="loading"  border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column v-if="!teacherId" label="Teacher" prop="teacherNickname"></el-table-column>
			<el-table-column label="Value ($)" prop="value" width="100">
				<template #default="scope">{{NumberFilter.money(scope.row.value)}}</template>
			</el-table-column>
			<el-table-column label="Time" prop="dateTime" width="140">
				<template #default="scope">{{DateTimeFilter.datetime(scope.row.dateTime)}}</template>
			</el-table-column>
			<el-table-column label="Remark" prop="remark"></el-table-column>
			<el-table-column label="Actions" fixed="right" width="100">
				<template #default="scope">
					<el-button-group>
						<el-popconfirm title="Are you sure to delete the earning record?" @confirm="doDeleteEarning(scope.row.id)">
							<template #reference>
								<el-button text type="primary">Delete</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import earningAPI from '../../api/earning'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { Query, QueryImpl } from '../../types/common/query'
import { EarningFilters, EarningFiltersImpl } from '../../types/common/query/filters/earningFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { EarningSearcherImpl } from '../../types/common/query/searcher/earningSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Earning } from '../../types/earning'
import ItPlaceholder from '../ui/ItPlaceholder.vue'

const props = defineProps<{
  teacherId?: string
}>()

const loading = ref<boolean>(false)

const earnings = ref<Earning[]>()

onMounted(() => {
	doListEarning()
})

// Query earning
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<EarningFilters>(new EarningFiltersImpl())
const searcher = reactive<Searcher>(new EarningSearcherImpl())
const sorts = [new SortImpl('dateTime', Direction.DESC)]

const doListEarning = function () {
	if (props.teacherId) filters.teacherId = props.teacherId
	
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	earningAPI.list(query).then((response: AxiosResponse<Result<ListObject<Earning>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			earnings.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListEarning()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListEarning()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListEarning()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListEarning()
}

// Delete earning
const doDeleteEarning = function (id: string) {
	loading.value = true
	earningAPI.deleteById(id).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete')
			doListEarning()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}
</script>