import { Filters } from "./index"

export interface LessonFilters extends Filters {
	courseId: string
}

export class LessonFiltersImpl implements LessonFilters {
	courseId: string

	constructor(courseId: string) {
		this.courseId = courseId
	}
	
	reset(): void {

	}
}