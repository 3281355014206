import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { CreateModule, SimpleModule, Module, UpdateModuleInfo, MoveModuleForward, MoveModuleBackward, UpdateModuleNumber } from "../types/module"
import service from "./api-service"

const api = '/module'

const moduleAPI = {    
  list (query: Query) {
    return service.post<Result<ListObject<SimpleModule>>>(api + '/list', query)
  },
  
  findById (id: string) {
    return service.get<Result<Module>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  findByCourseId (courseId: string) {
    return service.get<Result<Module[]>>(api + '/findByCourseId', { 
      params: {
        courseId: courseId
      }
    })
  },

  create (createModule: CreateModule) {
    return service.post<Result<Module>>(api, createModule)
  },

  updateInfo (updateModuleInfo: UpdateModuleInfo) {
    return service.put<Result<Module>>(api + '/updateInfo', updateModuleInfo)
  },

  updateNumber (updateModuleNumber: UpdateModuleNumber) {
    return service.put<Result<Module>>(api + '/updateNumber', updateModuleNumber)
  },

  moveForward (moveModuleForward: MoveModuleForward) {
    return service.put<Result<Module>>(api + '/moveForward', moveModuleForward)
  },

  moveBackward (moveModuleBackward: MoveModuleBackward) {
    return service.put<Result<Module>>(api + '/moveBackward', moveModuleBackward)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default moduleAPI