<template>
  <van-grid :border="false" :column-num="5" :gutter="5" class="my-1">
    <van-grid-item v-for="studentAvatar in studentAvatars" :key="studentAvatar.id">
      <van-image :src="studentAvatar.url" round @click="doSelectStudentAvatar(studentAvatar)"></van-image>
    </van-grid-item>
  </van-grid>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, ref } from 'vue'
import studentAvatarAPI from '../../api/studentAvatar'
import { Result } from '../../types/common/result'
import { StudentAvatar } from '../../types/studentAvatar'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  avatar?: string
}>()

const emit = defineEmits<{
  (e: 'update:avatar'): void
}>()

const studentAvatars = ref<StudentAvatar[]>()
const avatarURL = useModelWrapper(props, emit, 'avatar')

const doFindAllStudentAvatar = function () {
  studentAvatarAPI.findAll().then((response: AxiosResponse<Result<StudentAvatar[]>>) => {
    const result = response.data
    if (result.success) {
      studentAvatars.value = result.data
      avatarURL.value = studentAvatars.value[0].url
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  })
}

const doSelectStudentAvatar = function (studentAvatar: StudentAvatar) {
  avatarURL.value = studentAvatar.url
}

onMounted(() => {
  doFindAllStudentAvatar()
})
</script>
