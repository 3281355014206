import { Type } from "../types/course"

export class CourseFilter {
  static type (type?: Type): string {
    switch (type) {
      case Type.VIP:
        return 'VIP'
      case Type.GROUP:
        return 'Group'
      default:
        return '' 
    }
  }

  static trial (trial?: boolean): string {
    if (trial == undefined) return ''
    else return trial? 'Trial' : 'Formal'
  }

  static ready (ready?: boolean): string {
    if (ready == undefined) return ''
    else return ready? 'Ready' : 'Preparing'
  }

  static hidden (hidden?: boolean): string {
    if (hidden == undefined) return ''
    else return hidden? 'Hidden' : 'Visible'
  }
}