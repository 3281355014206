import { DateTime } from 'luxon'

export class DateTimeFilter {
  static year (date?: Date, zoneId?: string): string {
    return date? Number(DateTimeFilter.fromJSDate(date, zoneId).year).toString() : ''
  }

  static yearMonth (date?: Date): string {
    return date? DateTimeFilter.fromJSDate(date).toLocaleString({year: 'numeric', month: '2-digit'}) : ''
  }

  static date (date?: Date, zoneId?: string): string {
    return date? DateTimeFilter.fromJSDate(date, zoneId).toLocaleString(DateTime.DATE_SHORT) : ''
  }

  static datetime (date?: Date, zoneId?: string): string {
    return date? DateTimeFilter.date(date, zoneId) + ' ' + DateTimeFilter.time(date, zoneId) : ''
  }

  static time (date?: Date, zoneId?: string): string {
    return date? DateTimeFilter.fromJSDate(date, zoneId).toLocaleString(DateTime.TIME_24_SIMPLE) : ''
  }

  static age (date?: Date): string {
    return date? Number(DateTime.now().diff(DateTime.fromJSDate(date), 'year').years).toFixed(1).toString() : ""
  }

  static fromJSDate(date: Date, zoneId?: string): DateTime {
    if (zoneId) {
      return DateTime.fromJSDate(date).setZone(zoneId)
    } else {
      return DateTime.fromJSDate(date)
    }
  }
}