<template>
  <el-dialog v-model="show" title="Edit Serie">
    <el-form ref="updateSerieInfoForm" :model="updateSerieInfo">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Number" prop="number" required>
				<el-input-number v-model="updateSerieInfo.number" :precision="0" :step="1" :min="0" :max="1000" />
			</el-form-item>
			<el-form-item label="Subject" prop="subject" required>
				<el-radio-group v-model="updateSerieInfo.subject">
					<el-radio v-for="option in SerieSubjectOptions.options" :key="option.label" :label="option.value">{{ option.label }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="Name" prop="name" required>
				<el-input v-model="updateSerieInfo.name"></el-input>
			</el-form-item>
			<el-form-item label="Major" prop="major" required>
				<el-switch v-model="updateSerieInfo.major"></el-switch>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateSerieInfo">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import serieAPI from '../../api/serie'
import { SerieSubjectOptions } from '../../types//common/option/serieOptions'
import { Result } from '../../types/common/result'
import { Serie, UpdateSerieInfo, UpdateSerieInfoImpl } from '../../types/serie'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	serie: Serie
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

let updateSerieInfo = reactive<UpdateSerieInfo>(new UpdateSerieInfoImpl(props.serie))

const updateSerieInfoForm = ref<InstanceType<typeof ElForm>>()

const doUpdateSerieInfo = function () {
	updateSerieInfoForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			serieAPI.updateInfo(updateSerieInfo).then((response: AxiosResponse<Result<Serie>>) => {
				const result: Result<Serie> = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>