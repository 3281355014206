<template>
  <el-dialog v-model="show" title="Change Lesson Number">
    <el-form ref="updateLessonNumberForm" :model="updateLessonNumber" label-width="100px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Number" prop="number" required>
				<el-input-number v-model="updateLessonNumber.number" :precision="0" :step="1" :min="0" :max="1000" />
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateLessonNumber">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm } from 'element-plus'
import { reactive, ref } from 'vue'
import lessonAPI from '../../api/lesson'
import { Result } from '../../types/common/result'
import { Lesson, UpdateLessonNumber, UpdateLessonNumberImpl } from '../../types/lesson'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	lesson: Lesson
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const updateLessonNumber = reactive<UpdateLessonNumber>(new UpdateLessonNumberImpl(props.lesson))

const updateLessonNumberForm = ref<InstanceType<typeof ElForm>>()

const doUpdateLessonNumber = function () {
	updateLessonNumberForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			lessonAPI.updateNumber(updateLessonNumber).then((response: AxiosResponse<Result<Lesson>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				message.value = error.message
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>