<template>
  <withdraws ref="withdrawsComponent">
    <div class="actionbar">
			<div>Withdraw List</div>
      <el-button-group>
				<el-button text type="primary" :loading="loading" @click="doRetrieveAllTransactionStatus">Retrieve All Transaction Status</el-button>
			</el-button-group>
		</div>

    <el-divider />
  </withdraws>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { ref } from 'vue'
import withdrawAPI from '../../api/withdraw'
import Withdraws from '../../components/withdraw/Withdraws.vue'
import { Result } from '../../types/common/result'
import { Withdraw } from '../../types/withdraw'

const loading = ref<boolean>(false)

const withdrawsComponent = ref<InstanceType<typeof Withdraws>>()

const doRetrieveAllTransactionStatus = function () {
  loading.value = true
  withdrawAPI.retrieveAllTransactionStatus().then((response: AxiosResponse<Result<Withdraw[]>>) => {
    const result = response.data
    if (result.success) {
      withdrawsComponent.value?.refresh()
    } else {
      ElMessage.error(result.message)
    }						
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}
</script>