export interface Module {
	id: string
	name?: string
	fullName?: string
	number?: number
	content?: string
	courseId?: string
	courseName?: string
}

export class ModuleImpl implements Module {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface SimpleModule {
	id: string
	name: string
	number: number
	content?: string
	courseId: string
}

export interface CreateModule {
	name?: string
	content?: string
	courseId: string

	reset(): void
}

export class CreateModuleImpl implements CreateModule {
	name?: string
	content?: string
	courseId: string

	constructor(courseId: string) {
		this.courseId = courseId
	}

	reset() {
		this.name = undefined
		this.content = undefined
	}
}

export interface UpdateModuleInfo {
	id: string
	number?: number
	name?: string
	content?: string
}

export class UpdateModuleInfoImpl implements UpdateModuleInfo {
	id: string
	number?: number
	name?: string
	content?: string

	constructor(module: Module) {
		this.id = module.id
		this.number = module.number
		this.name = module.name
		this.content = module.content
	}
}

export interface UpdateModuleNumber {
	id: string
	number?: number
}

export class UpdateModuleNumberImpl implements UpdateModuleNumber {
	id: string
	number?: number

	constructor(module: Module) {
		this.id = module.id
		this.number = module.number
	}
}

export interface MoveModuleForward {
	id: string
}

export class MoveModuleForwardImpl implements MoveModuleForward {
	id: string

	constructor(module: Module) {
		this.id = module.id
	}
}

export interface MoveModuleBackward {
	id: string
}

export class MoveModuleBackwardImpl implements MoveModuleBackward {
	id: string

	constructor(module: Module) {
		this.id = module.id
	}
}