import { Method, Status } from "../types/refund"
import { toFirstUpperCase } from "../utils/stringUtils"

export class RefundFilter {
  static status (status?: Status): string {
    return toFirstUpperCase(status?.toString())
  }

  static method (method?: Method): string {
    switch (method) {
      case Method.CREDIT_CARD:
        return 'Credit Card'
      case Method.PAYPAL:
        return 'PayPal'
      case Method.ALIPAY:
        return 'Alipay'
      case Method.WECHAT_PAY:
        return 'Wechat Pay'
      case Method.CASH:
        return 'Cash'
      default:
        return 'Undefined' 
    }
  }
}