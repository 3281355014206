import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { CreateCourse, SimpleCourse, Course, UpdateCourseInfo, UpdateCourseCover, ToggleCourseReady, ToggleCourseHidden, UpdateCourseGuide, CreateCourseLocaleIntroduction, UpdateCourseLocaleIntroduction, LocaleCourse } from "../types/course"
import service from "./api-service"

const api = '/course'

const courseAPI = {
  uploadCover: import.meta.env.VITE_API_URL + api + '/uploadCover',
  
  list (query: Query) {
    return service.post<Result<ListObject<SimpleCourse>>>(api + '/list', query)
  },
  
  findById (id: string) {
    return service.get<Result<Course>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  findLocaleById (id: string) {
    return service.get<Result<LocaleCourse>>(api + '/findLocaleById', { 
      params: {
        id: id
      }
    })
  },

  findBySerieId (serieId: string) {
    return service.get<Result<Course[]>>(api + '/findBySerieId', { 
      params: {
        serieId: serieId
      }
    })
  },

  create (createCourse: CreateCourse) {
    return service.post<Result<Course>>(api, createCourse)
  },

  updateInfo (updateCourseInfo: UpdateCourseInfo) {
    return service.put<Result<Course>>(api + '/updateInfo', updateCourseInfo)
  },

  updateCover (updateCourseCover: UpdateCourseCover) {
    return service.put<Result<Course>>(api + '/updateCover', updateCourseCover)
  },

  updateGuide (updateCourseGuide: UpdateCourseGuide) {
    return service.put<Result<Course>>(api + '/updateGuide', updateCourseGuide)
  },

  createLocaleIntroduction (createCourseLocaleIntroduction: CreateCourseLocaleIntroduction) {
    return service.put<Result<Course>>(api + '/createLocaleIntroduction', createCourseLocaleIntroduction)
  },

  updateLocaleIntroduction (updateLocaleIntroduction: UpdateCourseLocaleIntroduction) {
    return service.put<Result<Course>>(api + '/updateLocaleIntroduction', updateLocaleIntroduction)
  },

  toggleReady (toggleCourseReady: ToggleCourseReady) {
    return service.put<Result<Course>>(api + '/toggleReady', toggleCourseReady)
  },

  toggleHidden (toggleCourseHidden: ToggleCourseHidden) {
    return service.put<Result<Course>>(api + '/toggleHidden', toggleCourseHidden)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default courseAPI