import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { CreateRechargeCard, RechargeCard, ToggleRechargeCardPublished, UpdateRechargeCardInfo } from "../types/rechargeCard"
import service from "./api-service"

const api = '/rechargeCard'

const rechargeCardAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<RechargeCard>>>(api + '/list', query)
  },

  findAll () {
    return service.get<Result<RechargeCard[]>>(api + '/findAll')
  },
  
  findById (id: string) {
    return service.get<Result<RechargeCard>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  create (createRechargeCard: CreateRechargeCard) {
    return service.post<Result<RechargeCard>>(api, createRechargeCard)
  },

  updateInfo (updateRechargeCardInfo: UpdateRechargeCardInfo) {
    return service.put<Result<RechargeCard>>(api + '/updateInfo', updateRechargeCardInfo)
  },

  togglePublished (toggleRechargeCardPublished: ToggleRechargeCardPublished) {
    return service.put<Result<RechargeCard>>(api + '/togglePublished', toggleRechargeCardPublished)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default rechargeCardAPI