import { Staff } from '../types/staff'

const STAFF = 'staff'
const ACCESS_TOKEN = 'accessToken'
const REFRESH_TOKEN = 'refreshToken'
const MENU_COLLAPSE = 'menuCollapse'

const storage = {
  getStaff (): Staff | undefined {
    const staffJson = localStorage.getItem(STAFF)
    return staffJson? JSON.parse(staffJson as string) : undefined
  },

  setStaff (staff: Staff): void {
    const staffJson = JSON.stringify(staff)
    localStorage.setItem(STAFF, staffJson)
  },

  removeStaff (): void {
    localStorage.removeItem(STAFF)
  },

  getAccessToken (): string | undefined {
    return localStorage.getItem(ACCESS_TOKEN) || undefined
  },

  setAccessToken (accessToken: string): void {
    localStorage.setItem(ACCESS_TOKEN, accessToken)
  },

  removeAccessToken (): void {
    localStorage.removeItem(ACCESS_TOKEN)
  },

  getRefreshToken (): string | undefined {
    return localStorage.getItem(REFRESH_TOKEN) || undefined
  },

  setRefreshToken (refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN, refreshToken)
  },

  removeRefreshToken (): void {
    localStorage.removeItem(REFRESH_TOKEN)
  },

  isMenuCollapse (): boolean | undefined {
    const menuCollapse = localStorage.getItem(MENU_COLLAPSE)
    return menuCollapse? JSON.parse(menuCollapse) : undefined
  },

  setMenuCollapse (menuCollapse: boolean): void {
    localStorage.setItem(MENU_COLLAPSE, String(menuCollapse))
  },

  removeMenuCollapse (): void {
    localStorage.removeItem(MENU_COLLAPSE)
  },

  clean (): void {
      this.removeStaff()
      this.removeAccessToken()
      this.removeRefreshToken()
      this.removeMenuCollapse()
  }
}

export default storage