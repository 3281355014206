import { DateTime } from "luxon"

export function toHourString(hour: number): string {
  if (hour < 0) {
    return ''
  } else if (hour < 10) {
    return '0' + hour
  } else if (hour < 24) {
    return hour.toString()
  } else {
    return ''
  }
}

export function toMinuteString(minute: number): string {
  if (minute < 0) {
    return ''
  } else if (minute < 30) {
    return '00'
  } else if (minute < 60) {
    return '30'
  } else if (minute = 60) {
    return '00'
  } else {
    return ''
  }
}

export function toHourMinuteString(hour: number, minute: number): string {
  return toHourString(hour) + ':' + toMinuteString(minute)
}

export function toHourNumber(text: string): number {
  return DateTime.fromFormat(text, 'HH:mm').hour
}

export function toMinuteNumber(text: string): number {
  return DateTime.fromFormat(text, 'HH:mm').minute
}

export function isMinutesAfterNow(date: Date, minutes: number): boolean {
  const dateTime = DateTime.fromJSDate(date)
  const afterNow = DateTime.now().plus({minutes: minutes})
  return dateTime > afterNow
}

export function getPrevMonth(i: number): Date {
  const dateTime = DateTime.now()
  const thisMonth = dateTime.startOf('month').set({hour: 0, minute: 0, second: 0, millisecond: 0})
  return thisMonth.minus({month: i}).toJSDate()
}