<template>
  <el-dialog v-model="show" title="Edit Module">
    <el-form ref="updateModuleInfoForm" :model="updateModuleInfo" label-width="100px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Number" prop="number" required>
				<el-input-number v-model="updateModuleInfo.number" :precision="0" :step="1" :min="0" :max="1000" />
			</el-form-item>
			<el-form-item label="Name" prop="name" required>
				<el-input v-model="updateModuleInfo.name"></el-input>
			</el-form-item>
			<el-form-item label="Content" prop="content">
				<div style="width: 100%; height: 300px; line-height: normal;">
					<quill-editor v-model:content="updateModuleInfo.content" theme="snow" contentType="html"></quill-editor>
				</div>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateModuleInfo">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm } from 'element-plus'
import { reactive, ref } from 'vue'
import moduleAPI from '../../api/module'
import { Result } from '../../types/common/result'
import { Module, UpdateModuleInfo, UpdateModuleInfoImpl } from '../../types/module'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	module: Module
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const updateModuleInfo = reactive<UpdateModuleInfo>(new UpdateModuleInfoImpl(props.module))

const updateModuleInfoForm = ref<InstanceType<typeof ElForm>>()

const doUpdateModuleInfo = function () {
	updateModuleInfoForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			moduleAPI.updateInfo(updateModuleInfo).then((response: AxiosResponse<Result<Module>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				message.value = error.message
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>