import { Accent, Country, Currency, DayOfWeek, Gender } from "../types/enums"
import { toFirstUpperCase } from "../utils/stringUtils"

export class EnumFilter {
  static gender (gender?: Gender): string {
    return toFirstUpperCase(gender?.toString())
  }

  static accent (accent?: Accent): string {
    return toFirstUpperCase(accent?.toString())
  }

  static country (country?: Country): string {
    if (country) return country?.toString()
    else return ''
  }

  static currency (currency: Currency | undefined): string {
    if (currency) {
      switch (currency) {
        case Currency.USD: return "$"
        case Currency.CNY: return "¥"
        default: return ''
      }
    } else {
      return ''
    }
  }

  static dayOfWeek (dayOfWeek?: DayOfWeek): string {
    return toFirstUpperCase(dayOfWeek)
  }
}