import { DateTime } from "luxon"

export interface Vacation {
  id: string
  dateTime?: Date
  remark?: string

  teacherId?: string
  teacherFullName?: string
  teacherNickname?: string
}

export interface CreateVacation {
	teacherId?: string
	dateTime?: DateTime
	remark?: string
}

export class CreateVacationImpl implements CreateVacation {
  teacherId?: string

  constructor(teacherId?: string) {
    this.teacherId = teacherId
  }
}