<template>
  <div class="content">
		<div class="actionbar">
			<div>Staff List</div>
			<el-button-group>
				<el-button text type="primary" @click="showCreateStaffDialog = true">Create Staff</el-button>
			</el-button-group>
		</div>

    <el-divider />

		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item>
					<el-select v-model="filters.role" placeholder="Role">
						<el-option v-for="option in StaffRoleOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.locked" placeholder="Status">
						<el-option v-for="option in StaffLockedOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-form :inline="true" :model="searcher">
				<el-form-item>
					<el-input v-model="searcher.keyword" class="input-with-select">
						<template #prepend>
							<el-select v-model="searcher.field" placeholder="Field" style="width: 120px;">
								<el-option label="Name" value="name"></el-option>
								<el-option label="Email" value="email"></el-option>
							</el-select>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item style="margin-right: 0;">
					<el-button type="primary" @click="doSearch">Search</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="staffs" v-loading="loading"  border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column label="Name" prop="name" />
			<el-table-column label="Role" prop="role">
				<template #default="scope">{{StaffFilter.role(scope.row.role)}}</template>
			</el-table-column>
			<el-table-column label="Email" prop="email" />
			<el-table-column label="Status" prop="locked">
				<template #default="scope">{{StaffFilter.locked(scope.row.locked)}}</template>
			</el-table-column> 
			<el-table-column label="Actions" fixed="right" width="90">
				<template #default="scope">
					<el-button-group>
						<el-button text type="primary" @click="doViewStaff(scope.row.id)">View</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />
  
		<create-staff-dialog v-if="showCreateStaffDialog" v-model:visible="showCreateStaffDialog" @success="onCreateStaffSuccess"></create-staff-dialog>
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import staffAPI from '../../api/staff'
import CreateStaffDialog from '../../components/staff/CreateStaffDialog.vue'
import { StaffFilter } from '../../filters/staffFilter'
import { StaffLockedOptions, StaffRoleOptions } from '../../types/common/option/staffOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { StaffFilters, StaffFiltersImpl } from '../../types/common/query/filters/staffFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher/index'
import { StaffSearcherImpl } from '../../types/common/query/searcher/staffSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Staff } from '../../types/staff'

const loading = ref<boolean>(false)
const router = useRouter()

const staffs = ref<Staff[]>()

onMounted(() => {
	doListStaff()
})

// Query staff
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<StaffFilters>(new StaffFiltersImpl())
const searcher = reactive<Searcher>(new StaffSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const doListStaff = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	staffAPI.list(query).then((response: AxiosResponse<Result<ListObject<Staff>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			staffs.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListStaff()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListStaff()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListStaff()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListStaff()
}

// Create staff
const showCreateStaffDialog = ref<boolean>(false)
const onCreateStaffSuccess = function () {
	ElMessage.success('Success to create')
	doListStaff()
}

// View staff
const doViewStaff = function(id: string) {
	router.push({name: 'staff', params: {id: id}})
}
</script>