import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { Consume } from "../types/consume"
import service from "./api-service"

const api = '/consume'

const consumeAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Consume>>>(api + '/list', query)
  },
  
  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default consumeAPI