import { Option, OptionImpl } from "."
import { toFirstUpperCase } from "../../../utils/stringUtils"
import { Subject } from "../../serie"

export class SerieMajorOptions {
	static options: Option[] = [
		new OptionImpl('Major', true),
		new OptionImpl('Normal', false)
	]
}

export class SerieSubjectOptions {
	static options: Option[] = [
		new OptionImpl(toFirstUpperCase(Subject.ENGLISH), Subject.ENGLISH),
		new OptionImpl(toFirstUpperCase(Subject.MATH), Subject.MATH)
	]
}