import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { CancelEnrollment, CreateEnrollment, Enrollment, FinishEnrollment, RearrangeEnrollment, RecreateEnrollment, RestartEnrollment, StartEnrollment, SuspendEnrollment, UpdateEnrollmentInfo, UpdateEnrollmentSuspendReason } from "../types/enrollment"
import service from "./api-service"

const api = '/enrollment'

const enrollmentAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Enrollment>>>(api + '/list', query)
  },

  findById (id: string) {
    return service.get<Result<Enrollment>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  findStartedByStudentId (studentId: string) {
    return service.get<Result<Enrollment[]>>(api + '/findStartedByStudentId', { 
      params: {
        studentId: studentId
      }
    })
  },

  findByStudentId (studentId: string) {
    return service.get<Result<Enrollment[]>>(api + '/findByStudentId', { 
      params: {
        studentId: studentId
      }
    })
  },

  create (createEnrollment: CreateEnrollment) {
    return service.post<Result<Enrollment>>(api, createEnrollment)
  },

  recreate (recreateEnrollment: RecreateEnrollment) {
    return service.put<Result<Enrollment>>(api + '/recreate', recreateEnrollment)
  },

  rearrange (rearrangeEnrollment: RearrangeEnrollment) {
    return service.put<Result<Enrollment>>(api + '/rearrange', rearrangeEnrollment)
  },

  start (startEnrollment: StartEnrollment) {
    return service.put<Result<Enrollment>>(api + '/start', startEnrollment)
  },

  restart (restartEnrollment: RestartEnrollment) {
    return service.put<Result<Enrollment>>(api + '/restart', restartEnrollment)
  },

  suspend (suspendEnrollment: SuspendEnrollment) {
    return service.put<Result<Enrollment>>(api + '/suspend', suspendEnrollment)
  },

  cancel (cancelEnrollment: CancelEnrollment) {
    return service.put<Result<Enrollment>>(api + '/cancel', cancelEnrollment)
  },

  finish (finishEnrollment: FinishEnrollment) {
    return service.put<Result<Enrollment>>(api + '/finish', finishEnrollment)
  },

  updateInfo (updateEnrollmentInfo: UpdateEnrollmentInfo) {
    return service.put<Result<Enrollment>>(api + '/updateInfo', updateEnrollmentInfo)
  },

  updateSuspendReason (updateEnrollmentSuspendReason: UpdateEnrollmentSuspendReason) {
    return service.put<Result<Enrollment>>(api + '/updateSuspendReason', updateEnrollmentSuspendReason)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default enrollmentAPI