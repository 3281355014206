<template>
  <div class="content">
		<slot></slot>

		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item v-if="!parentId">
					<el-select v-model="filters.parentId" placeholder="Parent" style="width: 150px;" filterable>
						<el-option v-for="parent in parents" :key="parent.id" :label="ParentFilter.fullName(parent)" :value="parent.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="!couponId">
					<el-select v-model="filters.couponId" placeholder="Coupon" style="width: 150px;" filterable>
						<el-option v-for="coupon in coupons" :key="coupon.id" :label="coupon.name" :value="coupon.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.status" placeholder="Status" style="width: 150px;">
						<el-option v-for="option in AcquiredCouponStatusOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-button-group>
				<el-popconfirm v-if="couponId" title="Are you sure to assign coupon for all parents?" @confirm="doCreateAcquiredCouponForAll">
					<template #reference>
						<el-button text type="primary">Assign Coupon For All Parents</el-button>
					</template>
				</el-popconfirm>
				<el-button text type="primary" @click="showCreateAcquiredCouponDialog = true">Assign Coupon</el-button>
			</el-button-group>
		</div>

		<el-table :data="acquiredCoupons" v-loading="loading" border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column v-if="!parentId" label="Parent">
				<template #default="scope">
					<div>NA: {{scope.row.parentName}}</div>
					<div>NN: {{scope.row.parentNickname}}</div>
				</template>
			</el-table-column>
			<el-table-column v-if="!couponId" label="Coupon">
				<template #default="scope">
					<div>{{scope.row.couponName}}</div>
					<div style="color: lightgray;">{{scope.row.couponDescription}}</div>
				</template>
			</el-table-column>
			<el-table-column v-if="!couponId" label="Value ($)" width="120">
				<template #default="scope">{{NumberFilter.money(scope.row.couponValue)}}</template>
			</el-table-column>
			<el-table-column label="Acquired Date" width="120">
				<template #default="scope">{{DateTimeFilter.date(scope.row.acquiredDate)}}</template>
			</el-table-column>
			<el-table-column label="Expired Date" width="120">
				<template #default="scope">{{DateTimeFilter.date(scope.row.expiredDate)}}</template>
			</el-table-column>
			<el-table-column label="Status" width="120">
				<template #default="scope">{{AcquiredCouponFilter.status(scope.row.status)}}</template>
			</el-table-column>
			<el-table-column label="Actions" fixed="right" width="100">
				<template #default="scope">
					<el-button-group>
						<el-popconfirm title="Are you sure to delete the coupon?" @confirm="doDeleteAcquiredCoupon(scope.row.id)">
							<template #reference>
								<el-button text type="primary">Delete</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>
		
		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />
	
		<create-acquired-coupon-dialog v-if="showCreateAcquiredCouponDialog" :parent-id="parentId" :coupon-id="couponId" v-model:visible="showCreateAcquiredCouponDialog" @success="onCreateAcquiredCouponSuccess"></create-acquired-coupon-dialog>
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import acquiredCouponAPI from '../../api/acquiredCoupon'
import couponAPI from '../../api/coupon'
import parentAPI from '../../api/parent'
import { AcquiredCouponFilter } from '../../filters/acquiredCouponFilter'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { AcquiredCoupon, CreateAcquiredCouponForAllImpl } from '../../types/acquiredCoupon'
import { AcquiredCouponStatusOptions } from '../../types/common/option/acquiredCouponOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { AcquiredCouponFilters, AcquiredCouponFiltersImpl } from '../../types/common/query/filters/acquiredCouponFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { AcquiredCouponSearcherImpl } from '../../types/common/query/searcher/acquiredCouponSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { NanoCoupon } from '../../types/coupon'
import { NanoParent } from '../../types/parent'
import CreateAcquiredCouponDialog from './CreateAcquiredCouponDialog.vue'
import { NumberFilter } from '../../filters/numberFilter'
import { ParentFilter } from '../../filters/parentFilter'

const props = defineProps<{
	parentId?: string
	couponId?: string
}>()

const loading = ref<boolean>(false)

const acquiredCoupons = ref<AcquiredCoupon[]>()

onMounted(() => {
	doListAcquiredCoupon()

	if (!props.couponId) doFindAllCoupon()
	if (!props.parentId) doFindAllParent()
})

// Query acquiredCoupon
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<AcquiredCouponFilters>(new AcquiredCouponFiltersImpl(props.parentId, props.couponId))
const searcher = reactive<Searcher>(new AcquiredCouponSearcherImpl())
const sorts = [new SortImpl('acquiredDate', Direction.DESC)]

// Find parents
const parents = ref<NanoParent[]>()
const doFindAllParent = function () {
	parentAPI.findAll().then((response: AxiosResponse<Result<NanoParent[]>>) => {
		const result = response.data
		if (result.success) {
			parents.value = result.data
		} else {
			ElMessage.error(result.message)
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// Find coupons
const coupons = ref<NanoCoupon[]>()
const doFindAllCoupon = function () {
	couponAPI.findAllPublished().then((response: AxiosResponse<Result<NanoCoupon[]>>) => {
		const result = response.data
		if (result.success) {
			coupons.value = result.data
		} else {
			ElMessage.error(result.message)
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const doListAcquiredCoupon = function () {
	if (props.parentId) filters.parentId = props.parentId
	if (props.couponId) filters.couponId = props.couponId

	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	acquiredCouponAPI.list(query).then((response: AxiosResponse<Result<ListObject<AcquiredCoupon>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			acquiredCoupons.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListAcquiredCoupon()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListAcquiredCoupon()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListAcquiredCoupon()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListAcquiredCoupon()
}

// Create acquiredCoupon
const showCreateAcquiredCouponDialog = ref<boolean>(false)
const onCreateAcquiredCouponSuccess = function () {
	ElMessage.success('Success to create')
	doListAcquiredCoupon()
}

defineExpose({
	doListAcquiredCoupon
})

// Create acquiredCoupon for all
const doCreateAcquiredCouponForAll = function () {
	loading.value = true
	const createAcquiredCouponForAll = new CreateAcquiredCouponForAllImpl(props.couponId)
	acquiredCouponAPI.createForAll(createAcquiredCouponForAll).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to create')
			doListAcquiredCoupon()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Delete acquiredCoupon
const doDeleteAcquiredCoupon = function (id: string) {
	loading.value = true
	acquiredCouponAPI.deleteById(id).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete')
			doListAcquiredCoupon()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}
</script>