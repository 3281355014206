export enum Type {
	LECTURE = 'LECTURE',
	TEST = 'TEST'
}

export interface Lesson {
	id: string
	workId?: string
	type?: Type
	name?: string
	fullName?: string
	number?: number
	slide?: string
	content?: string
	totalTestScore?: number
	courseId?: string
	courseName?: string
	moduleId?: string
	moduleName?: string
}

export class LessonImpl implements Lesson {
	id: string
	content?: string

	constructor(id: string) {
		this.id = id
		this.content = ''
	}
}

export interface SimpleLesson {
	id: string
	type: Type
	name: string
	number: number
	courseId: string
	content?: string
	totalTestScore?: number
}

export interface CreateLesson {
	moduleId?: string
	name?: string
	type?: Type
	totalTestScore?: number
	slide?: string
	content?: string
	courseId: string

	reset(): void
}

export class CreateLessonImpl implements CreateLesson {
	moduleId?: string
	name?: string
	type?: Type
	totalTestScore?: number
	slide?: string
	content?: string
	courseId: string

	constructor(courseId: string) {
		this.courseId = courseId
		this.type = Type.LECTURE
	}

	reset() {
		this.type = Type.LECTURE
		this.moduleId = undefined
		this.name = undefined
		this.slide = undefined
		this.totalTestScore = undefined
	}
}

export interface UpdateLessonInfo {
	id: string
	moduleId?: string
	type?: Type
	totalTestScore?: number
	number?: number
	name?: string
	slide?: string
	content?: string
}

export class UpdateLessonInfoImpl implements UpdateLessonInfo {
	id: string
	moduleId?: string
	type?: Type
	totalTestScore?: number
	number?: number
	name?: string
	slide?: string
	content?: string

	constructor(lesson: Lesson) {
		this.id = lesson.id
		this.moduleId = lesson.moduleId
		this.type = lesson.type
		this.totalTestScore = lesson.totalTestScore
		this.number = lesson.number
		this.name = lesson.name
		this.slide = lesson.slide
		this.content = lesson.content
	}
}

export interface UpdateLessonNumber {
	id: string
	number?: number
}

export class UpdateLessonNumberImpl implements UpdateLessonNumber {
	id: string
	number?: number

	constructor(lesson: Lesson) {
		this.id = lesson.id
		this.number = lesson.number
	}
}

export interface MoveLessonForward {
	id: string
}

export class MoveLessonForwardImpl implements MoveLessonForward {
	id: string

	constructor(lesson: Lesson) {
		this.id = lesson.id
	}
}

export interface MoveLessonBackward {
	id: string
}

export class MoveLessonBackwardImpl implements MoveLessonBackward {
	id: string

	constructor(lesson: Lesson) {
		this.id = lesson.id
	}
}