import { Agent, CreateAgent, GenerateAgentPoster, NanoAgent, ToggleAgentLocked, UpdateAgentDetailInfo, UpdateAgentRemark } from "../types/agent"
import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import service from "./api-service"

const api = '/agent'

const agentAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Agent>>>(api + '/list', query)
  },

  findAll () {
    return service.get<Result<NanoAgent[]>>(api + '/findAll')
  },
  
  findById (id: string) {
    return service.get<Result<Agent>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  create (createAgent: CreateAgent) {
    return service.post<Result<Agent>>(api, createAgent)
  },

  updateDetailInfo (updateAgentDetailInfo: UpdateAgentDetailInfo) {
    return service.put<Result<Agent>>(api + '/updateDetailInfo', updateAgentDetailInfo)
  },

  updateRemark (updateAgentRemark: UpdateAgentRemark) {
    return service.put<Result<Agent>>(api + '/updateRemark', updateAgentRemark)
  },

  toggleLocked (toggleAgentLocked: ToggleAgentLocked) {
    return service.put<Result<Agent>>(api + '/toggleLocked', toggleAgentLocked)
  },

  generatePoster (generateAgentPoster: GenerateAgentPoster) {
    return service.put<Result<Agent>>(api + '/generatePoster', generateAgentPoster)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default agentAPI