import { Status } from "../types/recommendation"

export class RecommendationFilter {
    static status (status?: Status): string {
        switch (status) {
            case Status.SIGNUP:
                return 'Signup'
            case Status.RECHARGE:
                return 'Recharge'
            default:
                return 'Undefined' 
        }
    }
}