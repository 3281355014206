import { Status } from "../types/acquiredCoupon"

export class AcquiredCouponFilter {
    static status (status?: Status): string {
        switch (status) {
            case Status.ACQUIRED:
                return 'Acquired'
            case Status.USED:
                return 'Used'
            case Status.EXPIRED:
                return 'Expired'
            default:
                return 'Undefined' 
        }
    }
}