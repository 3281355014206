import { Option, OptionImpl } from "."
import { toFirstUpperCase } from "../../../utils/stringUtils"
import { Status } from "../../student"

export class StudentStatusOptions {
	static options: Option[] = [
		new OptionImpl(toFirstUpperCase(Status.REGISTERED), Status.REGISTERED),
		new OptionImpl(toFirstUpperCase(Status.TRIALING), Status.TRIALING),
		new OptionImpl(toFirstUpperCase(Status.HESITATING), Status.HESITATING),
		new OptionImpl(toFirstUpperCase(Status.UNINTERESTED), Status.UNINTERESTED),
		new OptionImpl(toFirstUpperCase(Status.LEARNING), Status.LEARNING),
		new OptionImpl(toFirstUpperCase(Status.QUITED), Status.QUITED),
		new OptionImpl(toFirstUpperCase(Status.GRADUATED), Status.GRADUATED),
	]
}