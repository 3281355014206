<template>
  <el-dialog v-model="show" title="Edit Teacher" @opened="onOpened">
    <el-form ref="updateTeacherDetailInfoForm" :model="updateTeacherDetailInfo" label-width="120px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Name" required>
				<el-form-item prop="firstName" required>
					<el-input v-model="updateTeacherDetailInfo.firstName" placeholder="Firest Name" style="width: 200px;"></el-input>
				</el-form-item>
				<el-form-item prop="lastName" required>
					<el-input v-model="updateTeacherDetailInfo.lastName" placeholder="Last Name" style="width: 200px; margin-left: 10px;"></el-input>
				</el-form-item>
			</el-form-item>
			<el-form-item label="Nickname" prop="nickname" required>
				<el-input v-model="updateTeacherDetailInfo.nickname" style="width: 410px;"></el-input>
			</el-form-item>
			<el-form-item label="Mobile" prop="fullMobile" required>
				<el-input v-model="updateTeacherDetailInfo.mobile" class="input-with-select" @blur="onMobileInputBlur">
					<template #prepend>
						<el-select v-model="updateTeacherDetailInfo.region" placeholder="Country Code" style="width: 190px;" filterable @change="onRegionSelectChange">
							<el-option v-for="phoneCountryCode in phoneCountryCodes" :key="phoneCountryCode.region" :label="phoneCountryCode.country" :value="phoneCountryCode.region"></el-option>
						</el-select>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item label="Email" prop="email" required>
				<el-input v-model="updateTeacherDetailInfo.email"></el-input>
			</el-form-item>
			<el-form-item label="Time Zone" prop="zoneId" required>
				<el-select v-model="updateTeacherDetailInfo.zoneId" filterable>
					<el-option v-for="(availableZoneId, index) in availableZoneIds" :key="index" :label="availableZoneId" :value="availableZoneId"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Country" prop="country" required>
				<el-select v-model="updateTeacherDetailInfo.country" filterable>
					<el-option v-for="country in countries" :key="country.code" :label="country.name" :value="country.code"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Display Country" prop="displayCountry" required>
				<el-radio-group v-model="updateTeacherDetailInfo.displayCountry">
					<el-radio v-for="option in CountryOptions.options" :key="option.label" :label="option.value">{{ option.label }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="Accent" prop="accent" required>
				<el-radio-group v-model="updateTeacherDetailInfo.accent">
					<el-radio v-for="option in AccentOptions.options" :key="option.label" :label="option.value">{{ option.label }}</el-radio>
				</el-radio-group>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateTeacherDetailInfo">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElAlert, ElButton, ElDialog, ElForm, ElFormItem, ElInput, ElMessage, ElOption, ElRadio, ElRadioGroup, ElSelect } from 'element-plus'
import { reactive, ref } from 'vue'
import countryAPI from '../../api/country'
import mobileAPI from '../../api/mobile'
import teacherAPI from '../../api/teacher'
import zoneIdAPI from '../../api/zoneId'
import { AccentOptions, CountryOptions } from '../../types/common/option/enumOptions'
import { Result } from '../../types/common/result'
import { Country } from '../../types/country'
import { PhoneCountryCode } from '../../types/mobile'
import { Teacher, UpdateTeacherDetailInfo, UpdateTeacherDetailInfoImpl } from '../../types/teacher'
import { getCountryCodeFromRegion, getFullMobile } from '../../utils/mobileUtils'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	teacher: Teacher
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const availableZoneIds = ref<string[]>()
const doFindAvailableZoneIds = function () {
	zoneIdAPI.findAvailable().then((response: AxiosResponse<Result<string[]>>) => {
		const result = response.data
		if (result.success) {
			availableZoneIds.value = result.data
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// phone country code
const phoneCountryCodes = ref<PhoneCountryCode[]>()
const doFindAllPhoneCountryCodes = function () {
	mobileAPI.findAllCountryCode().then((response: AxiosResponse<Result<PhoneCountryCode[]>>) => {
		const result = response.data
		if (result.success) {
			phoneCountryCodes.value = result.data
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// country
const countries = ref<Country[]>()
const doFindAllCountries = function () {
	countryAPI.findAll().then((response: AxiosResponse<Result<Country[]>>) => {
		const result = response.data
		if (result.success) {
			countries.value = result.data
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// update teacher
const updateTeacherDetailInfo = reactive<UpdateTeacherDetailInfo>(new UpdateTeacherDetailInfoImpl(props.teacher))
const updateTeacherDetailInfoForm = ref<InstanceType<typeof ElForm>>()

const doUpdateTeacherDetailInfo = function () {
	updateTeacherDetailInfoForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			teacherAPI.updateDetailInfo(updateTeacherDetailInfo).then((response: AxiosResponse<Result<Teacher>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const onRegionSelectChange = function () {
	setFullMobile()
}

const onMobileInputBlur = function () {
	setFullMobile()
}

const setFullMobile = function () {
	updateTeacherDetailInfo.countryCode = getCountryCodeFromRegion(phoneCountryCodes.value, updateTeacherDetailInfo.region)
	updateTeacherDetailInfo.fullMobile = getFullMobile(updateTeacherDetailInfo.countryCode, updateTeacherDetailInfo.mobile)
}

const onOpened = function () {
	doFindAvailableZoneIds()
	doFindAllPhoneCountryCodes()
	doFindAllCountries()
}
</script>