<template>
  <el-dialog v-model="show" title="Edit Teacher Wage">
    <el-form ref="updateTeacherWageBaseForm" :model="updateTeacherWageBase">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Base" prop="base" required>
				<el-input-number v-model="updateTeacherWageBase.base" :min="0" :max="25"></el-input-number>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateTeacherWageBase">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import teacherAPI from '../../api/teacher'
import { Result } from '../../types/common/result'
import { Teacher, UpdateTeacherWageBase, UpdateTeacherWageBaseImpl } from '../../types/teacher'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	teacher: Teacher
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

let updateTeacherWageBase = reactive<UpdateTeacherWageBase>(new UpdateTeacherWageBaseImpl(props.teacher))

const updateTeacherWageBaseForm = ref<InstanceType<typeof ElForm>>()

const doUpdateTeacherWageBase = function () {
	updateTeacherWageBaseForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			teacherAPI.updateWageBase(updateTeacherWageBase).then((response: AxiosResponse<Result<Teacher>>) => {
				const result: Result<Teacher> = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>