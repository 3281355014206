export enum Status {
	ACQUIRED = 'ACQUIRED',
	USED = 'USED',
  EXPIRED = 'EXPIRED'
}

export interface AcquiredCoupon {
  id: string
  status: Status

  parentId: string
  parentName: string
  parentNickname: string
  
  couponId: string
  couponName: string
  couponDescription: string
  couponValue: string
  
  acquiredDate: Date
  expiredDate: Date
}

export interface CreateAcquiredCoupon {
  parentId?: string
  couponId?: string
}

export class CreateAcquiredCouponImpl implements CreateAcquiredCoupon {
  parentId?: string
  couponId?: string

  constructor(parentId?: string, couponId?: string) {
    this.parentId = parentId
    this.couponId = couponId
  }
}

export interface CreateAcquiredCouponForAll {
  couponId?: string
}

export class CreateAcquiredCouponForAllImpl implements CreateAcquiredCouponForAll {
  couponId?: string

  constructor(couponId?: string) {
    this.couponId = couponId
  }
}