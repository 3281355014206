<template>
  <div class="content">
		<slot></slot>

		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item v-if="!date">
					<el-date-picker v-model="filters.startDateTime" type="date" placeholder="Date" />
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.studentId" placeholder="Student" style="width: 150px;" filterable>
						<el-option v-for="student in students" :key="student.id" :label="student.nickname" :value="student.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="!teacherId">
					<el-select v-model="filters.teacherId" placeholder="Teacher" style="width: 150px;" filterable>
						<el-option v-for="teacher in teachers" :key="teacher.id" :label="teacher.nickname" :value="teacher.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.status" placeholder="Status" style="width: 150px;">
						<el-option v-for="option in ArrangementStatusOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.cancelReason" placeholder="Cancel Reason" style="width: 150px;">
						<el-option v-for="option in ArrangementCancelReasonOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.trial" placeholder="Type" style="width: 150px;">
						<el-option v-for="option in ArrangementTrialOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-button-group>
				<el-button text type="primary" @click="showBookArrangementDialog = true">Book Arrangement</el-button>
			</el-button-group>
		</div>

		<el-table :data="arrangements" v-loading="loading"  border class="table" row-key="id">
			<el-table-column type="expand">
				<template #default="scope">
					<div style="margin-left: 90px;">
						<it-info-items title="Cancel Reason" v-if="scope.row.cancelReason === CancelReason.OTHERS">
							<it-info-item label="Reason">{{ ArrangementFilter.cancelReason(scope.row.cancelReason) }}</it-info-item>
							<it-info-item label="Remark">{{ scope.row.cancelRemark }}</it-info-item>
						</it-info-items>
						<it-info-items title="Teacher Comment">
							<it-info-item label="Star">{{ NumberFilter.rank(scope.row.star) }}</it-info-item>
							<it-info-item label="Remark">{{ scope.row.remark }}</it-info-item>
						</it-info-items>
						<it-info-items title="Student Review">
							<it-info-item label="Rank">{{ NumberFilter.rank(scope.row.rank) }}</it-info-item>
							<it-info-item label="Feedback">{{ scope.row.feedback }}</it-info-item>
						</it-info-items>
					</div>
				</template>
    	</el-table-column>
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column label="Status" width="120">
				<template #default="scope">
					<div>{{ArrangementFilter.status(scope.row.status)}}</div>
					<div v-if="scope.row.status === Status.CANCELED">{{ArrangementFilter.cancelReason(scope.row.cancelReason)}}</div>
					<div v-if="scope.row.status === Status.CANCELED" :class="{ 'unConfirmed': !scope.row.cancelConfirmed }">{{ArrangementFilter.cancelConfirmed(scope.row.cancelConfirmed)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Type" width="120">
				<template #default="scope">
					<div>{{CourseFilter.type(scope.row.courseType)}}</div>
					<div>{{ArrangementFilter.trial(scope.row.trial)}}</div>
					<div>{{ArrangementFilter.demo(scope.row.demo)}}</div>
				</template>
			</el-table-column>
			<!-- <el-table-column label="GMT Time" width="120">
				<template #default="scope">
					<div>{{DateTimeFilter.date(scope.row.startDateTime, 'GMT')}}</div>
					<div>{{DateTimeFilter.time(scope.row.startDateTime, 'GMT')}}</div>
				</template>
			</el-table-column> -->
			<el-table-column label="Student Time" width="120">
				<template #default="scope">
					<div>{{DateTimeFilter.date(scope.row.startDateTime, scope.row.parentZoneId)}}</div>
					<div>{{DateTimeFilter.time(scope.row.startDateTime, scope.row.parentZoneId)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Teacher Time" width="120">
				<template #default="scope">
					<div>{{DateTimeFilter.date(scope.row.startDateTime, scope.row.teacherZoneId)}}</div>
					<div>{{DateTimeFilter.time(scope.row.startDateTime, scope.row.teacherZoneId)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Student" width="150">
				<template #default="scope">
					<div>Parent: {{scope.row.parentName}} </div>
					<div>Student: {{scope.row.studentNickname}}</div>
				</template>
			</el-table-column>      
			<el-table-column label="Teacher" width="120">
				<template #default="scope">
					<div>{{scope.row.teacherNickname}}</div>
					<div>{{scope.row.teacherFullName}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Lesson" min-width="250">
				<template #default="scope">
					<div>{{scope.row.courseFullName}}</div>
					<div>{{scope.row.lessonFullName}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Type" min-width="80">
				<template #default="scope">
					<div>{{LessonFilter.type(scope.row.lessonType)}}</div>
					<div v-if="scope.row.lessonType === LessonType.TEST && scope.row.lessonTestScore">{{scope.row.lessonTestScore}} / {{scope.row.lessonTotalTestScore}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Finance" width="100">
				<template #default="scope">
					<div>Fee: {{NumberFilter.money(scope.row.teacherFee)}}</div>
					<div>Wage: {{NumberFilter.money(scope.row.teacherWage)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Meeting" width="250">
				<template #default="scope">
					<div>ID: {{ scope.row.meetingId }}</div>
					<div>Password: {{ scope.row.meetingPassword }}</div>
					<div>Host: {{ scope.row.meetingHostEmail }}</div>
				</template>
			</el-table-column>
			<el-table-column label="Actions" fixed="right" width="320">
				<template #default="scope">
					<el-button-group>
						<el-button text type="primary" :disabled="scope.row.status !== Status.BOOKED" @click="doShowRebookArrangementDialog(scope.row)">Rebook</el-button>
						<el-button text type="primary" :disabled="scope.row.status !== Status.BOOKED" @click="doShowCancelArrangementDialog(scope.row)">Cancel</el-button>
						<el-popconfirm title="Are you sure to confirm cancel the arrangement?" @confirm="doConfirmCancelArrangement(scope.row)">
							<template #reference>
								<el-button text type="primary" :disabled="scope.row.cancelConfirmed || !(scope.row.status === Status.CANCELED && (scope.row.cancelReason === CancelReason.ABSENT_STUDENT || scope.row.cancelReason === CancelReason.ABSENT_TEACHER))">Confirm Cancel</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
					<el-button-group>
						<el-button text type="primary" :disabled="scope.row.status !== Status.BOOKED" :loading="starting" @click="doStartClass(scope.row)">Start Class</el-button>
						<el-button text type="primary" :disabled="scope.row.status !== Status.BOOKED" :loading="joining" @click="doJoinClass(scope.row)">Join Class</el-button>
						<el-button text type="primary" :disabled="scope.row.status !== Status.FINISHED" @click="doViewRecordingInfo(scope.row.meetingId)">Recording</el-button>
					</el-button-group>
					<el-button-group>
						<el-popconfirm title="Are you sure to create the meeting?" @confirm="doCreateArrangementMeeting(scope.row)">
							<template #reference>
								<el-button text type="primary" :disabled="scope.row.status === Status.FINISHED">Create Meeting</el-button>
							</template>
						</el-popconfirm>
						<el-popconfirm title="Are you sure to delete the meeting?" @confirm="doDeleteArrangementMeeting(scope.row)">
							<template #reference>
								<el-button text type="primary" :disabled="scope.row.status === Status.FINISHED">Delete Meeting</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
					<el-button-group>
						<el-button text type="primary" @click="doShowUpdateArrangementInfoDialog(scope.row)">Edit</el-button>
						<el-button text type="primary" :disabled="scope.row.status !== Status.BOOKED" @click="doShowFinishArrangementDialog(scope.row)">Finish</el-button>
						<el-popconfirm title="Are you sure to un-finish the arrangement?" @confirm="doUnFinishArrangement(scope.row)">
							<template #reference>
								<el-button text type="primary" :disabled="scope.row.status !== Status.FINISHED" :loading="loading">UnFinish</el-button>
							</template>
						</el-popconfirm>
						<el-popconfirm title="Are you sure to delete the arrangement?" @confirm="doDeleteArrangement(scope.row)">
							<template #reference>
								<el-button text type="primary" :disabled="scope.row.status !== Status.BOOKED">Delete</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>
		
		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />
	
		<recording-info v-model:visible="showRecordingInfo" :meetingId="selectedMeetingId"></recording-info>

		<update-arrangement-info-dialog v-if="showUpdateArrangementInfoDialog" v-model:visible="showUpdateArrangementInfoDialog" :arrangement="selectedArrangement" @success="onUpdateArrangementInfoSuccess"></update-arrangement-info-dialog>

		<book-arrangement-dialog v-if="showBookArrangementDialog" v-model:visible="showBookArrangementDialog" :parent-id="parentId" @success="onBookArrangementSuccess"></book-arrangement-dialog>

		<rebook-arrangement-dialog v-if="showRebookArrangementDialog" v-model:visible="showRebookArrangementDialog" :arrangement="selectedArrangement" @success="onRebookArrangementSuccess"></rebook-arrangement-dialog>

		<cancel-arrangement-dialog v-if="showCancelArrangementDialog" v-model:visible="showCancelArrangementDialog" :arrangement="selectedArrangement" @success="onCancelArrangementSuccess"></cancel-arrangement-dialog>

		<finish-arrangement-dialog v-if="showFinishArrangementDialog" v-model:visible="showFinishArrangementDialog" :arrangement="selectedArrangement" @success="onFinishArrangementSuccess"></finish-arrangement-dialog>
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import arrangementAPI from '../../api/arrangement'
import studentAPI from '../../api/student'
import teacherAPI from '../../api/teacher'
import { ArrangementFilter } from '../../filters/arrangementFilter'
import { CourseFilter } from '../../filters/courseFilter'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { LessonFilter } from '../../filters/lessonFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { Arrangement, ArrangementImpl, CancelReason, ConfirmCancelArrangement, ConfirmCancelArrangementImpl, CreateArrangementMeeting, CreateArrangementMeetingImpl, DeleteArrangementMeeting, DeleteArrangementMeetingImpl, Status, UnFinishArrangement, UnFinishArrangementImpl } from '../../types/arrangement'
import { ArrangementCancelReasonOptions, ArrangementStatusOptions, ArrangementTrialOptions } from '../../types/common/option/arrangementOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { ArrangementFilters, ArrangementFiltersImpl } from '../../types/common/query/filters/arrangementFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { ArrangementSearcherImpl } from '../../types/common/query/searcher/arrangementSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Type as LessonType } from "../../types/lesson"
import { NanoStudent, Student } from '../../types/student'
import { NanoTeacher, Teacher } from '../../types/teacher'
import RecordingInfo from '../meeting/RecordingInfo.vue'
import ItInfoItem from '../ui/ItInfoItem.vue'
import ItInfoItems from '../ui/ItInfoItems.vue'
import UpdateArrangementInfoDialog from './UpdateArrangementInfoDialog.vue'
import BookArrangementDialog from './BookArrangementDialog.vue'
import CancelArrangementDialog from './CancelArrangementDialog.vue'
import FinishArrangementDialog from './FinishArrangementDialog.vue'
import RebookArrangementDialog from './RebookArrangementDialog.vue'

const props = defineProps<{
  date?: Date,
	parentId?: string
	teacherId?: string
}>()

const loading = ref<boolean>(false)

const arrangements = ref<Arrangement[]>()

onMounted(() => {
	doListArrangement()

	if (props.parentId) {
		doFindStudentByParentId(props.parentId)
	} else {
		doFindAllStudents()
	}

	if (props.teacherId) {
		// do nothing
	} else {
		doFindAllTeachers()
	}
})

// Query arrangement
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<ArrangementFilters>(new ArrangementFiltersImpl())
const searcher = reactive<Searcher>(new ArrangementSearcherImpl())
const sorts = [new SortImpl('startDateTime', Direction.DESC), new SortImpl('studentId', Direction.DESC)]

// Find students
const students = ref<Student[]>()
const doFindAllStudents = function () {
	studentAPI.findAll().then((response: AxiosResponse<Result<NanoStudent[]>>) => {
		const result = response.data
		if (result.success) {
			students.value = result.data
		} else {
			ElMessage.error(result.message)
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}
const doFindStudentByParentId = function (parentId: string) {
	studentAPI.findByParentId(parentId).then((response: AxiosResponse<Result<Student[]>>) => {
		const result = response.data
		if (result.success) {
			students.value = result.data
		} else {
			ElMessage.error(result.message)
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// Find teachers
const teachers = ref<Teacher[]>()
const doFindAllTeachers = function () {
	teacherAPI.findAll().then((response: AxiosResponse<Result<NanoTeacher[]>>) => {
		const result = response.data
		if (result.success) {
			teachers.value = result.data
		} else {
			ElMessage.error(result.message)
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const doListArrangement = function () {
	if (props.date) filters.startDateTime = props.date
	if (props.parentId) filters.parentId = props.parentId
	if (props.teacherId) filters.teacherId = props.teacherId

	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	arrangementAPI.list(query).then((response: AxiosResponse<Result<ListObject<Arrangement>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			arrangements.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListArrangement()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListArrangement()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListArrangement()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListArrangement()
}

// Book arrangement
const showBookArrangementDialog = ref<boolean>(false)
const onBookArrangementSuccess = function () {
	ElMessage.success('Success to book')
	doListArrangement()
}

const selectedArrangement = ref<Arrangement>(new ArrangementImpl(''))

// Cancel arrangement
const showCancelArrangementDialog = ref<boolean>(false)
const doShowCancelArrangementDialog = function (arrangement: Arrangement) {
	selectedArrangement.value = arrangement
	showCancelArrangementDialog.value = true
}
const onCancelArrangementSuccess = function () {
	ElMessage.success('Success to cancel')
	doListArrangement()
}

// Edit arrangement info
const showUpdateArrangementInfoDialog = ref<boolean>(false)
const doShowUpdateArrangementInfoDialog = function (arrangement: Arrangement) {
	selectedArrangement.value = arrangement
	showUpdateArrangementInfoDialog.value = true
}
const onUpdateArrangementInfoSuccess = function () {
	ElMessage.success('Success to update')
	doListArrangement()
}

// Finish arrangement
const showFinishArrangementDialog = ref<boolean>(false)
const doShowFinishArrangementDialog = function (arrangement: Arrangement) {
	selectedArrangement.value = arrangement
	showFinishArrangementDialog.value = true
}
const onFinishArrangementSuccess = function () {
	ElMessage.success('Success to finish')
	doListArrangement()
}

// UnFinish arrangement
const doUnFinishArrangement = function (arrangement: Arrangement) {
	const unFinishArrangement: UnFinishArrangement = new UnFinishArrangementImpl(arrangement)
	loading.value = true
	arrangementAPI.unFinish(unFinishArrangement).then((response: AxiosResponse<Result<Arrangement>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to un-finish')
			doListArrangement()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Rebook arrangement
const showRebookArrangementDialog = ref<boolean>(false)
const doShowRebookArrangementDialog = function (arrangement: Arrangement) {
	selectedArrangement.value = arrangement
	showRebookArrangementDialog.value = true
}
const onRebookArrangementSuccess = function () {
	ElMessage.success('Success to rebook')
	doListArrangement()
}

// Confirm cancel arrangement
const doConfirmCancelArrangement = function (arrangement: Arrangement) {
	const confirmCancelArrangement: ConfirmCancelArrangement = new ConfirmCancelArrangementImpl(arrangement)
	loading.value = true
	arrangementAPI.confirmCancel(confirmCancelArrangement).then((response: AxiosResponse<Result<Arrangement>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to confirm cancel')
			doListArrangement()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Delete arrangement
const doDeleteArrangement = function (arrangement: Arrangement) {
	loading.value = true
	arrangementAPI.deleteById(arrangement.id).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete')
			doListArrangement()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Start class
const starting = ref<boolean>(false)
const doStartClass = function (arrangement: Arrangement) {
	const startClassWindow = window.open()
	if (startClassWindow) {
		if (arrangement.startUrl) {
			startClassWindow.location = arrangement.startUrl
		} else {
			starting.value = true
			const createArrangementMeeting = new CreateArrangementMeetingImpl(arrangement.id)
			arrangementAPI.createMeeting(createArrangementMeeting).then((response: AxiosResponse<Result<Arrangement>>) => {
				const result = response.data
				if (result.success) {
					const arrangement = result.data
					if (arrangement.startUrl) {
						startClassWindow.location = arrangement.startUrl
					} else {
						ElMessage.error('Can not create zoom meeting')
					}
					doListArrangement()
				} else {
					ElMessage.error(result.message)
				}
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				starting.value = false
			})
		}
	} else {
		ElMessage.error('Can not open window for Zoom')
	}
}

// Join Class
const joining = ref<boolean>(false)
const doJoinClass = function (arrangement: Arrangement) {
	const joinClassWindow = window.open()
	if (joinClassWindow) {
		if (arrangement.joinUrl) {
			joinClassWindow.location = arrangement.joinUrl
		} else {
			joining.value = true
			arrangementAPI.findById(arrangement.id).then((response: AxiosResponse<Result<Arrangement>>) => {
				const result = response.data
				if (result.success) {
					const arrangement = result.data
					if (arrangement.joinUrl) {
						joinClassWindow.location = arrangement.joinUrl
					} else {
						ElMessage.info('Teacher has not start the class. Please join class later.')
					}
				} else {
					ElMessage.error(result.message)
				}
			}).catch((error: AxiosError) => {
				joinClassWindow?.close()
				ElMessage.error(error.message)
			}).finally(() => {
				joining.value = false
			})
		}
	} else {
		ElMessage.error('Can not open window for Zoom')
	}
}

// Show/Hide recording
const showRecordingInfo = ref<boolean>(false)
const selectedMeetingId = ref<number>()
const doViewRecordingInfo = function (meetingId: number) {
	selectedMeetingId.value = meetingId
	showRecordingInfo.value = true
}

// Create Arrangement Meeting
const doCreateArrangementMeeting = function (arrangement: Arrangement) {
	loading.value = true
	const createArrangementMeeting: CreateArrangementMeeting = new CreateArrangementMeetingImpl(arrangement.id)
	arrangementAPI.createMeeting(createArrangementMeeting).then((response: AxiosResponse<Result<Arrangement>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to create meeting')
			doListArrangement()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Delete Arrangement Meeting
const doDeleteArrangementMeeting = function (arrangement: Arrangement) {
	loading.value = true
	const deleteArrangementMeeting: DeleteArrangementMeeting = new DeleteArrangementMeetingImpl(arrangement.id)
	arrangementAPI.deleteMeeting(deleteArrangementMeeting).then((response: AxiosResponse<Result<Arrangement>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete meeting')
			doListArrangement()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

defineExpose({
	doListArrangement
})
</script>

<style scoped>
.unConfirmed {
	color: red
}
</style>