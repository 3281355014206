import { Filters } from "./index"
import { Role } from "../../../staff"

export interface StaffFilters extends Filters {
	locked?: boolean
	role?: Role
}

export class StaffFiltersImpl implements StaffFilters {
	locked?: boolean
	role?: Role

	reset(): void {
		this.locked = undefined
		this.role = undefined
	}
}