import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { CreateLesson, SimpleLesson, Lesson, UpdateLessonInfo, MoveLessonForward, MoveLessonBackward, UpdateLessonNumber } from "../types/lesson"
import service from "./api-service"

const api = '/lesson'

const lessonAPI = {    
  list (query: Query) {
    return service.post<Result<ListObject<SimpleLesson>>>(api + '/list', query)
  },
  
  findById (id: string) {
    return service.get<Result<Lesson>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  findByCourseId (courseId: string) {
    return service.get<Result<Lesson[]>>(api + '/findByCourseId', { 
      params: {
        courseId: courseId
      }
    })
  },

  create (createLesson: CreateLesson) {
    return service.post<Result<Lesson>>(api, createLesson)
  },

  updateInfo (updateLessonInfo: UpdateLessonInfo) {
    return service.put<Result<Lesson>>(api + '/updateInfo', updateLessonInfo)
  },

  updateNumber (updateLessonNumber: UpdateLessonNumber) {
    return service.put<Result<Lesson>>(api + '/updateNumber', updateLessonNumber)
  },

  moveForward (moveLessonForward: MoveLessonForward) {
    return service.put<Result<Lesson>>(api + '/moveForward', moveLessonForward)
  },

  moveBackward (moveLessonBackward: MoveLessonBackward) {
    return service.put<Result<Lesson>>(api + '/moveBackward', moveLessonBackward)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default lessonAPI