import { DateTime } from "luxon"
import { Type as CourseType } from "./course"
import { Type as LessonType } from "./lesson"


export enum Status {
  BOOKED = 'BOOKED', // 已预约
  FINISHED = 'FINISHED', // 已完成
  CANCELED = 'CANCELED', // 已取消
}

export enum CancelReason {
  LEAVE_STUDENT = 'LEAVE_STUDENT', // 学生请假（不扣课时）
  LEAVE_TEACHER = 'LEAVE_TEACHER', // 老师请假（不发工资）

  ABSENT_STUDENT = 'ABSENT_STUDENT', // 学生缺席（扣课时）
  ABSENT_TEACHER = 'ABSENT_TEACHER', // 老师缺席（不发工资，罚课时）

  OTHERS = 'OTHERS', // 其他（学生不扣课时，老师不发工资）
}

export interface Arrangement {
  id: string
  status?: Status
  cancelReason?: CancelReason
  cancelRemark?: string
  cancelConfirmed?: boolean

  startDateTime?: Date
  duration?: number

  bookedDateTime?: Date
  finishedDateTime?: Date
  canceledDateTime?: Date

  trial?: boolean
  demo?: boolean

  rank?: number
  feedback?: string

  star?: number
  remark?: string

  teacherId?: string
  teacherNickname?: string
  teacherFullName?: string
  teacherFee?: number
  teacherWage?: number
  teacherZoneId?: string

  studentId?: string
  studentNickname?: string

  parentId?: string
  parentNickname?: string
  parentName?: string
  parentZoneId?: string

  lessonId?: string
  lessonName?: string
  lessonFullName?: string

  lessonType?: LessonType
  lessonTotalTestScore?: number
  lessonTestScore?: number

  courseId?: string
  courseName?: string
  courseFullName?: string
  courseType?: CourseType
  courseTrial?: boolean

  meetingId?: number
  meetingHostEmail?: string
  meetingPassword?: string
  startUrl?: string
  joinUrl?: string
  recorded?: boolean
}

export class ArrangementImpl implements Arrangement {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface BookArrangement {
	parentId: string
	studentId?: string
	enrollmentId?: string
	teacherId?: string
  courseId?: string
	lessonId?: string
  trial: boolean
  demo: boolean
  urgent: boolean
  startDateTime: DateTime
}

export class BookArrangementImpl implements BookArrangement {
  parentId: string
  trial: boolean
  demo: boolean
  urgent: boolean
  startDateTime: DateTime

  constructor(parentId: string) {
    this.parentId = parentId
    this.trial = false
    this.demo = false
    this.urgent = false
    this.startDateTime = DateTime.now()
  }
}

export interface FinishArrangement {
  id: string
  star?: number
  remark?: string
  lessonTestScore?: number

  reset(): void
}

export class FinishArrangementImpl implements FinishArrangement {
  id: string
  star?: number
  remark?: string
  lessonTestScore?: number

  constructor(arrangement: Arrangement) {
    this.id = arrangement.id
  }

  reset(): void {
    this.star = undefined
    this.remark = undefined
    this.lessonTestScore = undefined
  }
}

export interface UnFinishArrangement {
  id: string
}

export class UnFinishArrangementImpl implements UnFinishArrangement {
  id: string

  constructor(arrangement: Arrangement) {
    this.id = arrangement.id
  }
}

export interface RebookArrangement {
  id: string
	teacherId?: string
	lessonId?: string
  startDateTime: DateTime
  trial?: boolean
  demo?: boolean
  urgent?: boolean
}

export class RebookArrangementImpl implements RebookArrangement {
  id: string
  teacherId?: string
	lessonId?: string
  startDateTime: DateTime
  trial?: boolean
  demo?: boolean
  urgent?: boolean

  constructor(arrangement: Arrangement) {
    this.id = arrangement.id
    this.teacherId = arrangement.teacherId
    this.lessonId = arrangement.lessonId
    this.startDateTime = DateTime.fromJSDate(arrangement.startDateTime!)
    this.trial = arrangement.trial
    this.demo = arrangement.demo
  }
}

export interface UpdateArrangementInfo {
  id: string
	lessonId?: string
  trial?: boolean
  demo?: boolean
}

export class UpdateArrangementInfoImpl implements UpdateArrangementInfo {
  id: string
	lessonId?: string
  trial?: boolean
  demo?: boolean

  constructor(arrangement: Arrangement) {
    this.id = arrangement.id
    this.lessonId = arrangement.lessonId
    this.trial = arrangement.trial
    this.demo = arrangement.demo
  }
}

export interface CancelArrangement {
  id: string
  cancelReason?: CancelReason
  cancelRemark?: string
}

export class CancelArrangementImpl implements CancelArrangement {
  id: string
  cancelReason?: CancelReason
  cancelRemark?: string

  constructor(arrangement: Arrangement) {
    this.id = arrangement.id
    this.cancelReason = arrangement.cancelReason
    this.cancelRemark = arrangement.cancelRemark
  }
}

export interface ConfirmCancelArrangement {
	id: string
}

export class ConfirmCancelArrangementImpl implements ConfirmCancelArrangement {
  id: string

  constructor(arrangement: Arrangement) {
    this.id = arrangement.id
  }
}

export interface CreateArrangementMeeting {
	id: string
}

export class CreateArrangementMeetingImpl implements CreateArrangementMeeting {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface DeleteArrangementMeeting {
	id: string
}

export class DeleteArrangementMeetingImpl implements DeleteArrangementMeeting {
  id: string

  constructor(id: string) {
    this.id = id
  }
}