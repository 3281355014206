<template>
  <el-container class="info">
    <el-aside v-loading="loading" class="aside">
      <it-info-items title="Basic Info">
        <template #actions>
          <el-button text type="primary" class="action-button" @click="showUpdateParentDetailInfoDialog = true">Edit</el-button>
        </template>
        <it-info-item label="Nickname">{{ parent.nickname }}</it-info-item>
        <it-info-item label="Name">{{ parent.name }}</it-info-item>
        <it-info-item label="Locale">{{ parent.locale }}</it-info-item>
        <it-info-item label="Time Zone">{{ parent.zoneId }}</it-info-item>
        <it-info-item label="Email">{{ parent.email }}</it-info-item>
        <it-info-item label="Mobile">{{ parent.fullMobile }}</it-info-item>
        <it-info-item label="Balance">{{ parent.balanceLessons }}</it-info-item>
        <it-info-item label="Freeze">{{ parent.freezeLessons }}</it-info-item>
        <it-info-item label="Total Recharge">{{ parent.totalRechargeLessons }}</it-info-item>
        <it-info-item label="Total Refund">{{ parent.totalRefundLessons }}</it-info-item>
        <it-info-item label="Total Consume">{{ parent.totalConsumeLessons }}</it-info-item>
      </it-info-items>
      <it-info-items title="Other Info">
        <it-info-item label="Promote Code">{{ parent.promoteCode }}</it-info-item>
        <it-info-item label="Type">
          <div>{{ ParentFilter.type(parent.type) }}</div>
          <el-button text type="primary" class="action-button" @click="showUpdateParentTypeDialog = true">Change</el-button>
        </it-info-item>
        <it-info-item label="Status">
          <div>{{ ParentFilter.locked(parent.locked) }}</div>
          <el-popconfirm :title="parent.locked? 'Are you sure to unlock the parent?' : 'Are you sure to lock the parent?'" @confirm="doToggleParentLocked">
            <template #reference>
              <el-button text type="primary" class="action-button">{{ parent.locked? 'Unlock' : 'Lock' }}</el-button>
            </template>
          </el-popconfirm>
        </it-info-item>
        <it-info-item label="Charge (USD)">
          <div>{{ NumberFilter.money(parent.chargeForLessonUsd) }}</div>
          <el-button text type="primary" class="action-button" @click="showUpdateParentChargeForLessonDialog = true">Change</el-button>
        </it-info-item>
        <it-info-item label="Charge (CNY)">
          <div>{{ NumberFilter.money(parent.chargeForLessonCny) }}</div>
          <el-button text type="primary" class="action-button" @click="showUpdateParentChargeForLessonDialog = true">Change</el-button>
        </it-info-item>
        <it-info-item label="Completion">{{ ParentFilter.completed(parent.complete) }}</it-info-item>
        <it-info-item label="Referrer">
          <div>
            <router-link v-if="parent.referrerId" :to="{ name: 'parent', params: { id: parent.referrerId }}">{{ parent.referrerName }}</router-link>
          </div>
          <el-button text type="primary" class="action-button" @click="showUpdateParentReferrerDialog = true">Change</el-button>
        </it-info-item>
        <it-info-item label="Agent">
          <div>
            <router-link v-if="parent.agentId" :to="{ name: 'agent', params: { id: parent.agentId }}">{{ parent.agentName }}</router-link>
          </div>
          <el-button text type="primary" class="action-button" @click="showUpdateParentAgentDialog = true">Change</el-button>
        </it-info-item>
        <it-info-item label="CS">
          <div>
            <router-link v-if="parent.courseServiceId" :to="{ name: 'staff', params: { id: parent.courseServiceId }}">{{ parent.courseServiceName }}</router-link>
          </div>
          <el-button text type="primary" class="action-button" @click="showAssignParentCourseServiceDialog = true">Assign</el-button>
        </it-info-item>
        <it-info-item label="Created">{{ DateTimeFilter.datetime(parent.createdDateTime) }}</it-info-item>
        <it-info-item label="Remark">
          <div>{{ parent.remark }}</div>
          <el-button text type="primary" class="action-button" @click="showUpdateParentRemarkDialog = true">Edit</el-button>
        </it-info-item>
      </it-info-items>
      <el-popconfirm title="Are you sure to delete the parent?" @confirm="doDeleteParent">
        <template #reference>
          <el-button type="danger" style="width: 100%;">Delete</el-button>
        </template>
      </el-popconfirm>
    </el-aside>
    <el-main class="main">
      <el-tabs v-model="tab">
        <el-tab-pane label="Students" name="students" lazy>
          <studens :parent-id="parentId"></studens>
        </el-tab-pane>
        <el-tab-pane label="Enrollments" name="enrollments" lazy>
          <enrollments :parent-id="parentId"></enrollments>
        </el-tab-pane>
        <el-tab-pane label="Arrangements" name="arrangements" lazy>
          <arrangements :parent-id="parentId"></arrangements>
        </el-tab-pane>
        <el-tab-pane label="Coupons" name="coupons" lazy>
          <acquired-coupons :parent-id="parentId"></acquired-coupons>
        </el-tab-pane>
        <el-tab-pane label="Recharges" name="recharges" lazy>
          <recharges :parent-id="parentId" @deleted="onRechargeDeleted"></recharges>
        </el-tab-pane>
        <el-tab-pane label="Refunds" name="refunds" lazy>
          <refunds :parent-id="parentId"></refunds>
        </el-tab-pane>
        <el-tab-pane label="Consumes" name="consumes" lazy>
          <consumes :parent-id="parentId"></consumes>
        </el-tab-pane>
        <el-tab-pane label="Poster" name="poster" lazy>
          <poster :parent-id="parent.id"></poster>
        </el-tab-pane>
        <el-tab-pane label="Recommendations" name="recommendations" lazy>
          <recommendations :parent-id="parentId"></recommendations>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>

  <update-parent-detail-info-dialog v-if="showUpdateParentDetailInfoDialog" v-model:visible="showUpdateParentDetailInfoDialog" :parent="parent" @success="onUpdateParentDetailInfoSuccess"></update-parent-detail-info-dialog>
  <update-parent-charge-for-lesson-dialog v-if="showUpdateParentChargeForLessonDialog" v-model:visible="showUpdateParentChargeForLessonDialog" :parent="parent" @success="onUpdateParentChargeForLessonSuccess"></update-parent-charge-for-lesson-dialog>
  <update-parent-type-dialog v-if="showUpdateParentTypeDialog" v-model:visible="showUpdateParentTypeDialog" :parent="parent" @success="onUpdateParentTypeSuccess"></update-parent-type-dialog>
  <update-parent-referrer-dialog v-if="showUpdateParentReferrerDialog" v-model:visible="showUpdateParentReferrerDialog" :parent="parent" @success="onUpdateParentReferrerSuccess"></update-parent-referrer-dialog>
  <update-parent-agent-dialog v-if="showUpdateParentAgentDialog" v-model:visible="showUpdateParentAgentDialog" :parent="parent" @success="onUpdateParentAgentSuccess"></update-parent-agent-dialog>
  <update-parent-remark-dialog v-if="showUpdateParentRemarkDialog" v-model:visible="showUpdateParentRemarkDialog" :parent="parent" @success="onUpdateParentRemarkSuccess"></update-parent-remark-dialog>
  <assign-parent-course-service-dialog v-if="showAssignParentCourseServiceDialog" v-model:visible="showAssignParentCourseServiceDialog" :parent="parent" @success="onAssignParentCourseServiceSuccess"></assign-parent-course-service-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import parentAPI from '../../api/parent'
import AcquiredCoupons from '../../components/acquiredCoupon/AcquiredCoupons.vue'
import Consumes from '../../components/consume/Consumes.vue'
import Enrollments from '../../components/enrollment/Enrollments.vue'
import AssignParentCourseServiceDialog from '../../components/parent/AssignParentCourseServiceDialog.vue'
import Poster from '../../components/parent/Poster.vue'
import UpdateParentAgentDialog from '../../components/parent/UpdateParentAgentDialog.vue'
import UpdateParentChargeForLessonDialog from '../../components/parent/UpdateParentChargeForLessonDialog.vue'
import UpdateParentDetailInfoDialog from '../../components/parent/UpdateParentDetailInfoDialog.vue'
import UpdateParentReferrerDialog from '../../components/parent/UpdateParentReferrerDialog.vue'
import UpdateParentRemarkDialog from '../../components/parent/UpdateParentRemarkDialog.vue'
import UpdateParentTypeDialog from '../../components/parent/UpdateParentTypeDialog.vue'
import Recharges from '../../components/recharge/Recharges.vue'
import Recommendations from '../../components/recommendation/Recommendations.vue'
import Refunds from '../../components/refund/Refunds.vue'
import Arrangements from '../../components/schedule/Arrangements.vue'
import Studens from '../../components/student/Studens.vue'
import ItInfoItem from '../../components/ui/ItInfoItem.vue'
import ItInfoItems from '../../components/ui/ItInfoItems.vue'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { ParentFilter } from '../../filters/parentFilter'
import { Result } from '../../types/common/result'
import { Parent, ParentImpl, ToggleParentLocked, ToggleParentLockedImpl } from '../../types/parent'
import { cloneFrom } from '../../utils/clone'

const route = useRoute()
const parentId = route.params.id as string
const router = useRouter()

const loading = ref<boolean>(false)

const tab = ref<string>('students')

const parent = reactive<Parent>(new ParentImpl(parentId))

const doFindParent = function() {
  loading.value = true
  parentAPI.findById(parentId).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(parent, result.data)
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Edit parent info
const showUpdateParentDetailInfoDialog = ref<boolean>(false)
const onUpdateParentDetailInfoSuccess = function () {
  ElMessage.success('Success to edit')
  doFindParent()
}

// Edit parent charge for lesson
const showUpdateParentChargeForLessonDialog = ref<boolean>(false)
const onUpdateParentChargeForLessonSuccess = function () {
  ElMessage.success('Success to update charge for lesson')
  doFindParent()
}

// Edit parent type
const showUpdateParentTypeDialog = ref<boolean>(false)
const onUpdateParentTypeSuccess = function () {
  ElMessage.success('Success to change type')
  doFindParent()
}

// Edit parent referrer
const showUpdateParentReferrerDialog = ref<boolean>(false)
const onUpdateParentReferrerSuccess = function () {
  ElMessage.success('Success to change referrer')
  doFindParent()
}

// Edit parent agent
const showUpdateParentAgentDialog = ref<boolean>(false)
const onUpdateParentAgentSuccess = function () {
  ElMessage.success('Success to change agent')
  doFindParent()
}

// Assign parent course service
const showAssignParentCourseServiceDialog = ref<boolean>(false)
const onAssignParentCourseServiceSuccess = function () {
  ElMessage.success('Success to assign course service')
  doFindParent()
}

// Edit parent remark
const showUpdateParentRemarkDialog = ref<boolean>(false)
const onUpdateParentRemarkSuccess = function () {
  ElMessage.success('Success to edit remark')
  doFindParent()
}

// Lock/Unlock parent
const doToggleParentLocked = function () {
  const toggleParentLocked: ToggleParentLocked = new ToggleParentLockedImpl(parentId)
  loading.value = true
  parentAPI.toggleLocked(toggleParentLocked).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      const parent = result.data
      ElMessage.success(parent.locked? 'Success to lock' : 'Success to unlock')
      doFindParent()
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Delete parent
const doDeleteParent = function () {
  loading.value = true
  parentAPI.deleteById(parentId).then((response: AxiosResponse<Result<void>>) => {
    const result = response.data
    if (result.success) {
      ElMessage.success('Success to delete')
      router.go(-1)
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

const onRechargeDeleted = function () {
  doFindParent()
}

onMounted(() => {
  doFindParent()
})
</script>