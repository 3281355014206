<template>
  <el-dialog v-model="show" title="Assign Coupon" @opened="onOpened">
    <el-form ref="createAcquiredCouponForm" :model="createAcquiredCoupon">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item v-if="!parentId" label="Parent" prop="parentId" required>
				<el-select v-model="createAcquiredCoupon.parentId" filterable style="width: 100%">
					<el-option v-for="parent in parents" :key="parent.id" :label="ParentFilter.fullName(parent)" :value="parent.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item v-if="!couponId" label="Coupon" prop="couponId" required>
				<el-select v-model="createAcquiredCoupon.couponId" filterable style="width: 100%">
					<el-option v-for="coupon in coupons" :key="coupon.id" :label="coupon.name" :value="coupon.id"></el-option>
				</el-select>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doCreateAcquiredCoupon">Assign</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import acquiredCouponAPI from '../../api/acquiredCoupon'
import couponAPI from '../../api/coupon'
import parentAPI from '../../api/parent'
import { AcquiredCoupon, CreateAcquiredCoupon, CreateAcquiredCouponImpl } from '../../types/acquiredCoupon'
import { Result } from '../../types/common/result'
import { NanoCoupon } from '../../types/coupon'
import { NanoParent } from '../../types/parent'
import { useModelWrapper } from '../../utils/modelWrapper'
import { ParentFilter } from '../../filters/parentFilter'

const props = defineProps<{
  visible: boolean,
	parentId?: string
	couponId?: string
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const createAcquiredCoupon = reactive<CreateAcquiredCoupon>(new CreateAcquiredCouponImpl(props.parentId, props.couponId))

const createAcquiredCouponForm = ref<InstanceType<typeof ElForm>>()

const doCreateAcquiredCoupon = function () {
	createAcquiredCouponForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			acquiredCouponAPI.create(createAcquiredCoupon).then((response: AxiosResponse<Result<AcquiredCoupon>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					createAcquiredCouponForm.value?.resetFields()
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				message.value = error.message
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const coupons = ref<NanoCoupon[]>()
const doFindAllCoupon = function () {
	couponAPI.findAllPublished().then((response: AxiosResponse<Result<NanoCoupon[]>>) => {
		const result = response.data
		if (result.success) {
			coupons.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const parents = ref<NanoParent[]>()
const doFindAllParent = function () {
	parentAPI.findAll().then((response: AxiosResponse<Result<NanoParent[]>>) => {
		const result = response.data
		if (result.success) {
			parents.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const onOpened = function () {
	if (!props.couponId) doFindAllCoupon()
	if (!props.parentId) doFindAllParent()
}
</script>