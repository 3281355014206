<template>
  <el-tabs type="border-card">
    <el-tab-pane v-for="(dayOfWeek, index) in dayOfWeeks" :key="index" :index="index" :label="dayOfWeek" stretch>
      <it-time-slots :teaching-time-slots-id="teachingTimeSlots.id" :time-slots="teachingTimeSlots?.timeSlots?.get(dayOfWeek) || []" :enrollment-id="enrollmentId"></it-time-slots>
    </el-tab-pane>
  </el-tabs>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import teachingTimeSlotsAPI from '../../api/teachingTimeSlots'
import ItTimeSlots from '../../components/ui/time/ItTimeSlots.vue'
import { createDayOfWeeks } from '../../factory'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { Result } from '../../types/common/result'
import { TeachingTimeSlots, TeachingTimeSlotsImpl } from '../../types/teachingTimeSlots'
import { cloneFrom } from '../../utils/clone'

const props = defineProps<{
  teacherId: string,
	enrollmentId?: string
}>()

const loading = ref<boolean>(false)

const teachingTimeSlots = reactive<TeachingTimeSlots>(new TeachingTimeSlotsImpl(props.teacherId))
const dayOfWeeks = createDayOfWeeks()

onMounted(() => {
  doFindTeachingTimeSlots()
})

watch(() => props.teacherId, () => {
  doFindTeachingTimeSlots()
})

const doFindTeachingTimeSlots = function () {
  loading.value = true
  teachingTimeSlotsAPI.findByTeacherId(props.teacherId).then((response: AxiosResponse<Result<TeachingTimeSlots>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(teachingTimeSlots, result.data)
      // 根据本地时间排序
      dayOfWeeks.forEach(dayOfWeek => {
        const timeSlots = teachingTimeSlots.timeSlots.get(dayOfWeek)
        timeSlots?.sort((timeSlot1, timeSlot2) => {
          return DateTimeFilter.time(timeSlot1.startTime).localeCompare(DateTimeFilter.time(timeSlot2.startTime))
        })
      })
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}
</script>