import { Option, OptionImpl } from "."
import { toFirstUpperCase } from "../../../utils/stringUtils"
import { Method, Status } from "../../refund"

export class RefundStatusOptions {
	static options: Option[] = [
		new OptionImpl(toFirstUpperCase(Status.CREATED), Status.CREATED),
		new OptionImpl(toFirstUpperCase(Status.COMPLETED), Status.COMPLETED),
		new OptionImpl(toFirstUpperCase(Status.VOIDED), Status.VOIDED)
	]
}

export class RefundMethodOptions {
	static options: Option[] = [
		new OptionImpl('PayPal', Method.PAYPAL),
		new OptionImpl('Alipay', Method.ALIPAY),
		new OptionImpl('Wechat Pay', Method.WECHAT_PAY),
		new OptionImpl('Cash', Method.CASH),
		new OptionImpl('Credit Card', Method.CREDIT_CARD)
	]
}