<template>
  <el-dialog v-model="show" title="Book Arrangement" @opened="onOpened">
    <el-form ref="bookArrangementForm" :model="bookArrangement" label-width="100px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Parent" required>
				<el-select v-model="bookArrangement.parentId" filterable @change="onSelectParent" style="width: 300px;">
					<el-option v-for="parent in parents" :key="parent.id" :label="ParentFilter.fullName(parent)" :value="parent.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Student" prop="studentId" required>
				<el-select v-model="bookArrangement.studentId" filterable @change="onSelectStudent" style="width: 300px;">
					<el-option v-for="student in students" :key="student.id" :label="student.nickname" :value="student.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Enrollment" prop="enrollmentId" required>
				<el-select v-model="bookArrangement.enrollmentId" filterable style="width: 300px;" @change="onSelectEnrollment">
					<el-option v-for="enrollment in enrollments" :key="enrollment.id" :label="enrollment.courseFullName" :value="enrollment.id"></el-option>
				</el-select>
				<div v-if="enrollments?.length === 0" style="margin-left: 10px;">No Started Enrollment</div>
				<div v-if="enrollment" style="margin-left: 10px;">
					<div v-if="enrollment.latestBookedLessonId">Last Booked Lesson: {{ enrollment.latestBookedLessonFullName }}</div>
					<div v-else>No Last Booked Lesson</div>
				</div>
			</el-form-item>
			<el-form-item label="Lesson" prop="lessonId" required>
				<el-select v-model="bookArrangement.lessonId" filterable style="width: 300px;">
					<el-option v-for="lesson in lessons" :key="lesson.id" :label="lesson.fullName" :value="lesson.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Teacher" prop="teacherId" required>
				<el-select v-model="bookArrangement.teacherId" filterable style="width: 300px;" @change="onSelectTeacher">
					<el-option v-for="teacher in teachers" :key="teacher.id" :label="teacher.nickname" :value="teacher.id"></el-option>
				</el-select>
				<span v-if="teacher" style="margin-left: 10px;">Time Zone: {{teacher.zoneId}}</span>
			</el-form-item>
			<el-form-item label="Start Time" prop="startDateTime" required>
				<it-date-time-picker v-model:datetime="bookArrangement.startDateTime"></it-date-time-picker>
				<span style="margin-left: 10px;">(Student)</span>
			</el-form-item>
			<el-form-item v-if="teacher" label="Date Time">
				<span style="width: 300px;">{{ teacherDateTimeString }}</span>
				<span style="margin-left: 10px;">(Teacher)</span>
			</el-form-item>
			<el-form-item label="Trial" required>
				<el-switch v-model="bookArrangement.trial" />
				<span style="margin-left: 10px;">Is trial class for student</span>
			</el-form-item>
			<el-form-item label="Demo" required>
				<el-switch v-model="bookArrangement.demo" />
				<span style="margin-left: 10px;">Is demo class for teacher</span>
			</el-form-item>
			<el-form-item label="Urgent" required>
				<el-switch v-model="bookArrangement.urgent" />
				<span style="margin-left: 10px;">Is urgent class within 24h</span>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doBookArrangement">Create</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { computed, reactive, ref } from 'vue'
import arrangementAPI from '../../api/arrangement'
import enrollmentAPI from '../../api/enrollment'
import lessonAPI from '../../api/lesson'
import parentAPI from '../../api/parent'
import studentAPI from '../../api/student'
import teacherAPI from '../../api/teacher'
import { Arrangement, BookArrangement, BookArrangementImpl } from '../../types/arrangement'
import { Result } from '../../types/common/result'
import { Enrollment } from '../../types/enrollment'
import { Lesson } from '../../types/lesson'
import { NanoParent } from '../../types/parent'
import { Student } from '../../types/student'
import { Teacher } from '../../types/teacher'
import { useModelWrapper } from '../../utils/modelWrapper'
import ItDateTimePicker from '../ui/ItDateTimePicker.vue'
import { ParentFilter } from '../../filters/parentFilter'

const props = defineProps<{
  visible: boolean,
	parentId?: string
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

// Find parents
const parents = ref<NanoParent[]>()
const doFindParents = function () {
	parentAPI.findAll().then((response: AxiosResponse<Result<NanoParent[]>>) => {
		const result = response.data
		if (result.success) {
			parents.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const onSelectParent = function () {
	doFindStudentByParentId(bookArrangement.parentId)
}

// Find students
const students = ref<Student[]>()
const doFindStudentByParentId = function (parentId: string) {
	studentAPI.findByParentId(parentId).then((response: AxiosResponse<Result<Student[]>>) => {
		const result = response.data
		if (result.success) {
			students.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}
const onSelectStudent = function () {
	doFindEnrollment(bookArrangement.studentId!)
}

// Find enrollments
const enrollments = ref<Enrollment[]>()
const enrollment = ref<Enrollment>()
const doFindEnrollment = function (studentId: string) {
	enrollmentAPI.findStartedByStudentId(studentId).then((response: AxiosResponse<Result<Enrollment[]>>) => {
		const result = response.data
		if (result.success) {
			enrollments.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}
const onSelectEnrollment = function () {
	enrollment.value = enrollments.value?.filter(enrollment => enrollment.id === bookArrangement.enrollmentId)[0]
	doFindLessons(enrollment.value?.courseId!)
}

// Find lessons
const lessons = ref<Lesson[]>()
const doFindLessons = function (courseId: string) {
	lessonAPI.findByCourseId(courseId).then((response: AxiosResponse<Result<Lesson[]>>) => {
		const result = response.data
		if (result.success) {
			lessons.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// Find Teachers
const teachers = ref<Teacher[]>()
const teacher = ref<Teacher>()
const doFindTeachers = function () {
	teacherAPI.findReady().then((response: AxiosResponse<Result<Teacher[]>>) => {
		const result = response.data
		if (result.success) {
			teachers.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}
const onSelectTeacher = function () {
	teacher.value = teachers.value?.filter(teacher => teacher.id === bookArrangement.teacherId)[0]
	doFindLessons(enrollment.value?.courseId!)
}

const teacherDateTimeString = computed(() => {
	return bookArrangement.startDateTime.setZone(teacher.value?.zoneId).set({second: 0, millisecond: 0}).toFormat('yyyy-MM-dd HH:mm')
})

const bookArrangement = reactive<BookArrangement>(new BookArrangementImpl(props.parentId ?? ''))

const bookArrangementForm = ref<InstanceType<typeof ElForm>>()

const doBookArrangement = function () {
	bookArrangementForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			arrangementAPI.book(bookArrangement).then((response: AxiosResponse<Result<Arrangement>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					bookArrangementForm.value?.resetFields()
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				message.value = error.message
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const onOpened = function () {
	doFindParents()
	doFindTeachers()
	
	if (props.parentId) {
		doFindStudentByParentId(props.parentId)
	}
}
</script>