<template>
  <div class="content">
		<div class="actionbar">
			<div>Locale Introductions</div>
			<el-button-group>
				<el-button text type="primary" @click="showCreateCourseLocaleIntroductionDialog = true">Create Locale Introduction</el-button>
			</el-button-group>
		</div>

    <el-divider />

		<el-collapse accordion>
      <el-collapse-item v-for="(localeIntroduction, index) in course.localeIntroductions" :key="index" :name="index">
        <template #title>
					<div style="display: flex; justify-content: space-between; width: 100%; padding: 0 10px; align-items: center;">
						<div>{{ LocaleFilter.locale(localeIntroduction.locale) }}</div>
						<el-button text type="primary" @click="doShowUpdateCourseLocaleIntroductionDialog(localeIntroduction.locale)">Update Locale Introduction</el-button>
					</div>
        </template>
        <div style="padding: 0 10px;" v-html="localeIntroduction.introduction"></div>
      </el-collapse-item>
		</el-collapse>
		
		<create-course-locale-introduction-dialog v-model:visible="showCreateCourseLocaleIntroductionDialog" :course-id="courseId" @success="onCreateCourseLocaleIntroductionSuccess"></create-course-locale-introduction-dialog>

		<update-course-locale-introduction-dialog v-if="showUpdateCourseLocaleIntroductionDialog" v-model:visible="showUpdateCourseLocaleIntroductionDialog" :course="course" :locale="locale" @success="onUpdateCourseLocaleIntroductionSuccess"></update-course-locale-introduction-dialog>
	</div>
</template>
<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref } from 'vue'
import courseAPI from '../../api/course'
import CreateCourseLocaleIntroductionDialog from '../../components/course/CreateCourseLocaleIntroductionDialog.vue'
import UpdateCourseLocaleIntroductionDialog from '../../components/course/UpdateCourseLocaleIntroductionDialog.vue'
import { LocaleFilter } from '../../filters/localeFilter'
import { Result } from '../../types/common/result'
import { LocaleCourse, LocaleCourseImpl } from '../../types/course'
import { cloneFrom } from '../../utils/clone'

const props = defineProps<{
  courseId: string
}>()

onMounted(() => {
	doFindLocaleCourse()
})

const loading = ref<boolean>(false)

const course = reactive<LocaleCourse>(new LocaleCourseImpl(props.courseId))
const doFindLocaleCourse = function() {
	loading.value = true
	courseAPI.findLocaleById(props.courseId).then((response: AxiosResponse<Result<LocaleCourse>>) => {
		const result = response.data
		if (result.success) {
			cloneFrom(course, result.data)
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Create locale introduction
const showCreateCourseLocaleIntroductionDialog = ref<boolean>(false)
const onCreateCourseLocaleIntroductionSuccess = function () {
	ElMessage.success('Success to create')
	doFindLocaleCourse()
}

// Update locale introduction
const locale = ref<string>()
const showUpdateCourseLocaleIntroductionDialog = ref<boolean>(false)
const doShowUpdateCourseLocaleIntroductionDialog = function (selectedLocale: string) {
	locale.value = selectedLocale
	showUpdateCourseLocaleIntroductionDialog.value = true
}
const onUpdateCourseLocaleIntroductionSuccess = function () {
	ElMessage.success('Success to update')
	doFindLocaleCourse()
}
</script>
