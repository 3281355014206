import { Type } from "../../../course"
import { Filters } from "./index"

export interface CourseFilters extends Filters {
	serieId?: string
	type?: Type
	trial?: boolean
}

export class CourseFiltersImpl implements CourseFilters {
	serieId?: string
	type?: Type
	trial?: boolean

	reset(): void {
			this.serieId = undefined
			this.type = undefined
			this.trial = undefined
	}
}