import { Option, OptionImpl } from "."
import { toFirstUpperCase } from "../../../utils/stringUtils"
import { Status, TransactionStatus } from "../../withdraw"

export class WithdrawStatusOptions {
	static options: Option[] = [
    new OptionImpl(toFirstUpperCase(Status.REQUESTED), Status.REQUESTED),
		new OptionImpl(toFirstUpperCase(Status.REJECTED), Status.REJECTED),
		new OptionImpl(toFirstUpperCase(Status.APPROVED), Status.APPROVED),
		new OptionImpl(toFirstUpperCase(Status.FINISHED), Status.FINISHED),
	]
}

export class WithdrawTransactionStatusOptions {
	static options: Option[] = [
    new OptionImpl(toFirstUpperCase(TransactionStatus.SUCCESS), TransactionStatus.SUCCESS),
		new OptionImpl(toFirstUpperCase(TransactionStatus.FAILED), TransactionStatus.FAILED),
		new OptionImpl(toFirstUpperCase(TransactionStatus.PENDING), TransactionStatus.PENDING),
		new OptionImpl(toFirstUpperCase(TransactionStatus.UNCLAIMED), TransactionStatus.UNCLAIMED),
		new OptionImpl(toFirstUpperCase(TransactionStatus.RETURNED), TransactionStatus.RETURNED),
		new OptionImpl(toFirstUpperCase(TransactionStatus.ONHOLD), TransactionStatus.ONHOLD),
		new OptionImpl(toFirstUpperCase(TransactionStatus.BLOCKED), TransactionStatus.BLOCKED),
		new OptionImpl(toFirstUpperCase(TransactionStatus.REFUNDED), TransactionStatus.REFUNDED),
		new OptionImpl(toFirstUpperCase(TransactionStatus.REVERSED), TransactionStatus.REVERSED),
	]
}