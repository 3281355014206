export enum Role {
	ADMIN = 'ADMIN',
	CXO = 'CXO',
	CS = 'CS'
}

export interface Staff {
	id: string
	name?: string
	email?: string
	role?: Role
	locked?: boolean
}

export class StaffImpl implements Staff {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface NanoStaff {
	id: string
	name: string
}

export interface CreateStaff {
	name?: string
	email?: string
	role?: Role
}

export class CreateStaffImpl implements CreateStaff {

}

export interface ToggleStaffLocked {
	id: string
}

export class ToggleStaffLockedImpl implements ToggleStaffLocked {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface UpdateStaffInfo {
	id: string
	name?: string
	email?: string
	role?: Role
}

export class UpdateStaffInfoImpl implements UpdateStaffInfo {
	id: string
	name?: string
	email?: string
	role?: Role

	constructor(staff: Staff) {
		this.id = staff.id
		this.name = staff.name
		this.email = staff.email
		this.role = staff.role
	}
}