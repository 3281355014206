export interface Meeting {
  id: number
  hostEmail: string
  startUrl: string
  joinUrl: string
  password: string
}

export interface RecordingFile {
  id: string
  fileType: string
  playUrl: string
  status: string
}

export interface Recording {
  uuid?: string
  meetingId?: string
  topic?: string
  password?: string
  recordingFiles?: RecordingFile[]
}

export class RecordingImpl implements Recording {

}