import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { Recharge, StaffCheckout, UpdateRechargeInfo } from "../types/recharge"
import service from "./api-service"

const api = '/recharge'

const rechargeAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Recharge>>>(api + '/list', query)
  },
  
  staffCheckout (staffCheckout: StaffCheckout) {
    return service.post<Result<Recharge>>(api + '/checkout/staff', staffCheckout)
  },

  updateInfo (updateRechargeInfo: UpdateRechargeInfo) {
    return service.put<Result<Recharge>>(api + '/updateInfo', updateRechargeInfo)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default rechargeAPI