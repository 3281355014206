import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { CreateRefund, Refund, UpdateRefundInfo } from "../types/refund"
import service from "./api-service"

const api = '/refund'

const refundAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Refund>>>(api + '/list', query)
  },
  
  create (createRefund: CreateRefund) {
    return service.post<Result<Refund>>(api, createRefund)
  },

  updateInfo (updateRefundInfo: UpdateRefundInfo) {
    return service.put<Result<Refund>>(api + '/updateInfo', updateRefundInfo)
  }
}

export default refundAPI