<template>
  <el-dialog v-model="show" title="Create Student Avatar">
    <el-form ref="createStudentAvatarForm" :model="createStudentAvatar">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Image" prop="url" required>
				<el-upload :action="uploadStudentAvatarImageUrl" :headers="headers" :multiple="false" :show-file-list="false" accept="image/*" :before-upload="beforeUploadStudentAvatarImage" :on-success="onUploadStudentAvatarImageSuccess" :on-error="onUploadStudentAvatarImageError">
					<el-button type="primary">Upload Image</el-button>
					<template #tip>
						<div class="el-upload__tip">Image size should less than 1M</div>
					</template>
				</el-upload>
				<div class="error" v-if="studentAvatarImageMessage">{{ studentAvatarImageMessage }}</div>
			</el-form-item>
			<el-form-item>
				<el-image v-if="createStudentAvatar.url" :src="createStudentAvatar.url" fit="contain" style="width: 100px; height: 100px;" />
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doCreateStudentAvatar">Create</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import studentAvatarAPI from '../../api/studentAvatar'
import { Result } from '../../types/common/result'
import { UploadHeadersImpl } from '../../types/common/upload'
import { CreateStudentAvatar, CreateStudentAvatarImpl, StudentAvatar } from '../../types/studentAvatar'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const uploadStudentAvatarImageUrl = ref<string>(studentAvatarAPI.uploadImage)
const headers = reactive(new UploadHeadersImpl())

const studentAvatarImageMessage = ref<string>('')
const beforeUploadStudentAvatarImage = function (file: File) {
	if (file && file.size > 1024 * 1024) {
		studentAvatarImageMessage.value = 'Please upload photo which size is less than 1M'
		return false
	}
}

const onUploadStudentAvatarImageSuccess = function (result: Result<URL>) {
	if (result.success) {
		createStudentAvatar.url = result.data.toString()
	} else {
		studentAvatarImageMessage.value = result.message
	}
}

const onUploadStudentAvatarImageError = function (error: Error) {
	if (error) studentAvatarImageMessage.value = error.message
}

const createStudentAvatar = reactive<CreateStudentAvatar>(new CreateStudentAvatarImpl(''))
const createStudentAvatarForm = ref<InstanceType<typeof ElForm>>()

const doCreateStudentAvatar = function () {
	createStudentAvatarForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			studentAvatarAPI.create(createStudentAvatar).then((response: AxiosResponse<Result<StudentAvatar>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					createStudentAvatarForm.value?.resetFields()
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>