import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { CreateStudent, NanoStudent, Student, UpdateStudentInfo, UpdateStudentStatus } from "../types/student"
import service from "./api-service"

const api = '/student'

const studentAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Student>>>(api + '/list', query)
  },

  findById (id: string) {
    return service.get<Result<Student>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  findAll () {
    return service.get<Result<NanoStudent[]>>(api + '/findAll')
  },

  findByParentId (parentId: string) {
    return service.get<Result<Student[]>>(api + '/findByParentId', { 
      params: {
        parentId: parentId
      }
    })
  },

  searchByNickname (keyword: string) {
    return service.get<Result<Student[]>>(api + '/searchByNickname', { 
      params: {
        keyword: keyword
      }
    })
  },

  create (createStudent: CreateStudent) {
    return service.post<Result<Student>>(api, createStudent)
  },

  updateInfo (updateStudentInfo: UpdateStudentInfo) {
    return service.put<Result<Student>>(api + '/updateInfo', updateStudentInfo)
  },

  updateStatus (updateStudentStatus: UpdateStudentStatus) {
    return service.put<Result<Student>>(api + '/updateStatus', updateStudentStatus)
  },


  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default studentAPI