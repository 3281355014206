import { Option, OptionImpl } from "."
import { toFirstUpperCase } from "../../../utils/stringUtils"
import { Category, Method, Status, Type } from "../../recharge"

export class RechargeStatusOptions {
	static options: Option[] = [
		new OptionImpl(toFirstUpperCase(Status.CREATED), Status.CREATED),
		new OptionImpl(toFirstUpperCase(Status.COMPLETED), Status.COMPLETED),
		new OptionImpl(toFirstUpperCase(Status.VOIDED), Status.VOIDED)
	]
}

export class RechargeTypeOptions {
	static options: Option[] = [
		new OptionImpl(toFirstUpperCase(Type.USER), Type.USER),
		new OptionImpl(toFirstUpperCase(Type.SYSTEM), Type.SYSTEM)
	]
}

export class RechargeCategoryOptions {
	static options: Option[] = [
		new OptionImpl(toFirstUpperCase(Category.TUITION), Category.TUITION),
		new OptionImpl(toFirstUpperCase(Category.REWARD), Category.REWARD),
		new OptionImpl(toFirstUpperCase(Category.COMPENSATION), Category.COMPENSATION)
	]
}

export class RechargeMethodOptions {
	static options: Option[] = [
		new OptionImpl('Card', Method.CARD),
		new OptionImpl('Alipay', Method.ALIPAY),
		new OptionImpl('Wechat Pay', Method.WECHAT_PAY),
		new OptionImpl('Cash', Method.CASH),
		new OptionImpl('Others', Method.OTHERS),
	]
}