<template>
  <el-drawer v-model="show" title="Recording Info" direction="rtl" @open="onOpen" class="drawer">
    <it-info-items title="Meeting">
      <it-info-item label="Topic">{{ recording.topic }}</it-info-item>
      <it-info-item label="Passcode">{{ recording.password }}</it-info-item>
    </it-info-items>
    <it-info-items title="Recording Files">
      <el-table :data="recording.recordingFiles" v-loading="loading"  border class="table" row-key="id">
        <el-table-column label="#" type="index" align="center" width="40" />
        <el-table-column label="Recording Type" prop="recordingType" width="250" />
        <el-table-column label="File Type" prop="fileType" />
        <el-table-column label="Status" prop="status" />
        <el-table-column label="Actions" fixed="right" width="90">
          <template #default="scope">
            <el-button-group>
              <el-button text type="primary" :disabled="scope.row.status !== 'completed'" @click="doPlayRecordingFile(scope.row.playUrl)">Play</el-button>
            </el-button-group>     
          </template>
        </el-table-column>
      </el-table>
    </it-info-items>

  </el-drawer>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import zoomAPI from '../../api/zoom'
import { Result } from '../../types/common/result'
import { Recording, RecordingImpl } from '../../types/zoom'
import { cloneFrom } from '../../utils/clone'
import { useModelWrapper } from '../../utils/modelWrapper'
import ItInfoItem from '../ui/ItInfoItem.vue'
import ItInfoItems from '../ui/ItInfoItems.vue'

const props = defineProps<{
  visible: boolean,
	meetingId?: number
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)

const recording = reactive<Recording>(new RecordingImpl())

const doFindRecording = function() {
  loading.value = true
  zoomAPI.findRecordingByMeetingId(props.meetingId!).then((response: AxiosResponse<Result<Recording>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(recording, result.data)
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

const doPlayRecordingFile = function(url: string) {
  window.open(url)
}

const onOpen = function () {
  doFindRecording()
}
</script>

<style scoped>
.drawer {
  width: 800px !important;
}
</style>