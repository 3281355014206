import { Currency } from "./enums"

export enum Status {
  SIGNUP = 'SIGNUP', // 已注册
  RECHARGE = 'RECHARGE' // 已充值
}

export interface Recruitment {
  id: string
  status?: Status
  signupDateTime?: Date
  rechargeDateTime?: Date

  agentId?: string
  agentName?: string

  parentId?: string
  parentNickname?: string

  rechargeId?: string
  rechargeCurrency?: Currency
  rechargeActualValue?: number
}