<template>
  <el-dialog v-model="show" title="Reject Withdraw">
    <el-form ref="manualWithdrawForm" :model="manualWithdraw" label-width="100px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="PayPal Email" prop="payPalEmail" required>
				<el-input v-model="manualWithdraw.payPalEmail"></el-input>
			</el-form-item>
			<el-form-item label="Value" prop="value" required>
				<el-input-number v-model="manualWithdraw.value" :min="1" :max="5000" />
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doManualWithdraw">Withdraw</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import withdrawAPI from '../../api/withdraw'
import { Result } from '../../types/common/result'
import { Teacher } from '../../types/teacher'
import { ManualWithdraw, ManualWithdrawImpl, Withdraw } from '../../types/withdraw'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	teacher?: Teacher
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const manualWithdraw = reactive<ManualWithdraw>(new ManualWithdrawImpl(props.teacher))
const manualWithdrawForm = ref<InstanceType<typeof ElForm>>()

const doManualWithdraw = function () {
	manualWithdrawForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			withdrawAPI.manual(manualWithdraw).then((response: AxiosResponse<Result<Withdraw>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>