import { getFullMobile } from "../utils/mobileUtils"

export enum Type {
	NORMAL = 'NORMAL', // 普通家长
	KOC = 'KOC', // 意见领袖家长，能够推荐身边朋友
	AGENT = 'AGENT' // 招生代理，有渠道可以批量招生
}

export interface Parent {
	id: string
	type?: Type
	name?: string
	nickname?: string
	region?: string
	countryCode?: string
	mobile?: string
	fullMobile?: string
	email?: string
	locale?: string
	zoneId?: string
	promoteCode?: string
	balanceLessons?: number
	freezeLessons?: number
  totalRechargeLessons?: number
	totalRefundLessons?: number
  totalConsumeLessons?: number
	poster?: string
	locked?: boolean
	complete?: boolean
	createdDateTime?: Date
	remark?: string
	chargeForLessonUsd?: number
	chargeForLessonCny?: number

	referrerId?: string
	referrerName?: string

	agentId?: string
	agentName?: string

	courseServiceId?: string
	courseServiceName?: string
}

export class ParentImpl implements Parent {
	id: string
	
	constructor(id: string) {
		this.id = id
	}
}

export interface NanoParent {
	id: string
	name: string
	nickname: string
}

export interface Student {
	id: string
	nickname: string
	name: string
}

export interface SimpleParent {
	id: string
	type?: Type
	nickname?: string
	name?: string
	email?: string
	countryCode?: string
	mobile?: string
	fullMobile?: string
	balanceLessons?: number
	freezeLessons?: number
	locked?: boolean
	complete?: boolean
	students?: Student[]
	remark?: string

	agentId?: string
	agentName?: string

	courseServiceId?: string
	courseServiceName?: string
}

export interface ToggleParentLocked {
	id: string
}

export class ToggleParentLockedImpl implements ToggleParentLocked {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface UpdateParentDetailInfo {
	id: string
	name?: string
	nickname?: string
	email?: string
	region?: string
	countryCode?: string
	mobile?: string
	fullMobile?: string
}

export class UpdateParentDetailInfoImpl implements UpdateParentDetailInfo {
	id: string
	name?: string
	nickname?: string
	email?: string
	region?: string
	countryCode?: string
	mobile?: string
	fullMobile?: string

	constructor(parent: Parent) {
		this.id = parent.id
		this.name = parent.name
		this.nickname = parent.nickname
		this.email = parent.email
		this.region = parent.region
		this.countryCode = parent.countryCode
		this.mobile = parent.mobile
		this.fullMobile = getFullMobile(parent.countryCode, parent.mobile)
	}
}

export interface UpdateParentType {
	id: string
	type?: Type
}

export class UpdateParentTypeImpl implements UpdateParentType {
	id: string
	type?: Type

	constructor(parent: Parent) {
		this.id = parent.id
		this.type = parent.type
	}
}

export interface UpdateParentReferrer {
	id: string
	referrerId?: string
}

export class UpdateParentReferrerImpl implements UpdateParentReferrer {
	id: string
	referrerId?: string

	constructor(parent: Parent) {
		this.id = parent.id
		this.referrerId = parent.referrerId
	}
}

export interface UpdateParentAgent {
	id: string
	agentId?: string
}

export class UpdateParentAgentImpl implements UpdateParentAgent {
	id: string
	agentId?: string

	constructor(parent: Parent) {
		this.id = parent.id
		this.agentId = parent.agentId
	}
}

export interface UpdateParentRemark {
	id: string
	remark?: string
}

export class UpdateParentRemarkImpl implements UpdateParentRemark {
	id: string
	remark?: string

	constructor(parent: Parent) {
		this.id = parent.id
		this.remark = parent.remark
	}
}

export interface UpdateParentChargeForLesson {
	id: string
	chargeForLessonUsd?: number
	chargeForLessonCny?: number
}

export class UpdateParentChargeForLessonImpl implements UpdateParentChargeForLesson {
	id: string
	chargeForLessonUsd?: number
	chargeForLessonCny?: number

	constructor(parent: Parent) {
		this.id = parent.id
		this.chargeForLessonUsd = parent.chargeForLessonUsd
		this.chargeForLessonCny = parent.chargeForLessonCny
	}
}

export interface GenerateParentPoster {
  id: string
}

export class GenerateParentPosterImpl implements GenerateParentPoster {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface AssignParentCourseService {
  id: string,
	courseServiceId?: string
}

export class AssignParentCourseServiceImpl implements AssignParentCourseService {
  id: string
	courseServiceId?: string

  constructor(parent: Parent) {
    this.id = parent.id
		this.courseServiceId = parent.courseServiceId
  }
}