import { Status } from "../types/student"

export class StudentFilter {

  static status (status?: Status): string {
    switch (status) {
        case Status.REGISTERED:
            return 'Registered'
        case Status.TRIALING:
            return 'Trialing'
        case Status.HESITATING:
          return 'Hesitating'
        case Status.UNINTERESTED:
          return 'Uninterested'
        case Status.LEARNING:
          return 'Learning'
        case Status.QUITED:
          return 'Quited'
        case Status.GRADUATED:
          return 'Graduated'
        default:
            return '' 
    }
  }
}