<template>
  <div class="content">
		<div class="actionbar">
			<div>Recharge Card List</div>
			<el-button-group>
				<el-button text type="primary" @click="showCreateRechargeCardDialog = true">Create Recharge Card</el-button>
			</el-button-group>
		</div>

    <el-divider />

		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item>
					<el-select v-model="filters.hot" placeholder="Hot">
						<el-option v-for="option in RechargeCardHotOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.published" placeholder="Published">
						<el-option v-for="option in RechargeCardPublishedOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-form :inline="true" :model="searcher">
				<el-form-item>
					<el-input v-model="searcher.keyword" class="input-with-select">
						<template #prepend>
							<el-select v-model="searcher.field" placeholder="Field" style="width: 120px;">
								<el-option label="Name" value="name"></el-option>
							</el-select>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item style="margin-right: 0;">
					<el-button type="primary" @click="doSearch">Search</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="rechargeCards" v-loading="loading"  border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column label="Name" prop="name" />
			<el-table-column label="Lessons" prop="lessons" />
			<el-table-column label="Price ($)" prop="price">
				<template #default="scope">{{NumberFilter.money(scope.row.price)}}</template>
			</el-table-column> 
			<el-table-column label="Discount Price ($)" prop="discountPrice">
				<template #default="scope">{{NumberFilter.money(scope.row.discountPrice)}}</template>
			</el-table-column>			
			<el-table-column label="Recommended" prop="recommended">
				<template #default="scope">
					<div :style="[scope.row.recommended? {'color': 'red'} : {'color': ''}]">{{RechargeCardFilter.recommended(scope.row.recommended)}}</div>
				</template>	
			</el-table-column>
			<el-table-column label="Hot" prop="hot">
				<template #default="scope">
					<div :style="[scope.row.hot? {'color': 'red'} : {'color': ''}]">{{RechargeCardFilter.hot(scope.row.hot)}}</div>
				</template>	
			</el-table-column>
			<el-table-column label="Published" prop="published">
				<template #default="scope">
					<div>{{RechargeCardFilter.published(scope.row.published)}}</div>
				</template>	
			</el-table-column>
			<el-table-column label="Stripe Product" prop="stripeProductId" />
			<el-table-column label="Actions" fixed="right" width="100">
				<template #default="scope">
					<el-button-group>
						<el-button text type="primary" @click="doShowUpdateRechargeCardInfoDialog(scope.row)">Edit</el-button>
						<el-popconfirm :title="scope.row.published? 'Are you sure to unpublish the card?' : 'Are you sure to publish the card?'" @confirm="doToggleRechargeCardPublished(scope.row.id)">
							<template #reference>
								<el-button text type="primary">{{ scope.row.published? 'Unpublish' : 'Publish' }}</el-button>
							</template>
						</el-popconfirm>
						<el-popconfirm title="Are you sure to delete the recharge card?" @confirm="doDeleteRechargeCard(scope.row.id)">
							<template #reference>
								<el-button text type="primary">Delete</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model="pagination.currentPage" layout="total, prev, pager, next" />
  
		<create-recharge-card-dialog v-if="showCreateRechargeCardDialog" v-model:visible="showCreateRechargeCardDialog" @success="onCreateRechargeCardSuccess"></create-recharge-card-dialog>

		<update-recharge-card-info-dialog v-if="showUpdateRechargeCardInfoDialog" v-model:visible="showUpdateRechargeCardInfoDialog" :recharge-card="selectedRechargeCard" @success="onUpdateRechargeCardInfoSuccess"></update-recharge-card-info-dialog>
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import rechargeCardAPI from '../../api/rechargeCard'
import CreateRechargeCardDialog from '../../components/rechargeCard/CreateRechargeCardDialog.vue'
import UpdateRechargeCardInfoDialog from '../../components/rechargeCard/UpdateRechargeCardInfoDialog.vue'
import { NumberFilter } from '../../filters/numberFilter'
import { RechargeCardFilter } from '../../filters/rechargeCardFilter'
import { RechargeCardHotOptions, RechargeCardPublishedOptions } from '../../types/common/option/rechargeCardOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { RechargeCardFilters, RechargeCardFiltersImpl } from '../../types/common/query/filters/rechargeCardFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher/index'
import { RechargeCardSearcherImpl } from '../../types/common/query/searcher/rechargeCardSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { RechargeCard, RechargeCardImpl, ToggleRechargeCardPublished, ToggleRechargeCardPublishedImpl } from '../../types/rechargeCard'


const loading = ref<boolean>(false)

const rechargeCards = ref<RechargeCard[]>()

onMounted(() => {
	doListRechargeCard()
})

// Query rechargeCard
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<RechargeCardFilters>(new RechargeCardFiltersImpl())
const searcher = reactive<Searcher>(new RechargeCardSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const doListRechargeCard = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	rechargeCardAPI.list(query).then((response: AxiosResponse<Result<ListObject<RechargeCard>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			rechargeCards.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListRechargeCard()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListRechargeCard()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListRechargeCard()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListRechargeCard()
}

// Create rechargeCard
const showCreateRechargeCardDialog = ref<boolean>(false)
const onCreateRechargeCardSuccess = function () {
	ElMessage.success('Success to create')
	pagination.gotoLastPage()
	doListRechargeCard()
}

// Edit rechargeCard
let selectedRechargeCard = ref<RechargeCard>(new RechargeCardImpl(''))
const showUpdateRechargeCardInfoDialog = ref<boolean>(false)
const doShowUpdateRechargeCardInfoDialog = function (rechargeCard: RechargeCard) {
	selectedRechargeCard.value = rechargeCard
	showUpdateRechargeCardInfoDialog.value = true
}
const onUpdateRechargeCardInfoSuccess = function () {
	ElMessage.success('Success to edit')
	doListRechargeCard()
}

// Publish/Unpublish rechargeCard
const doToggleRechargeCardPublished = function (id: string) {
  const toggleRechargeCardPublished: ToggleRechargeCardPublished = new ToggleRechargeCardPublishedImpl(id)
  loading.value = true
  rechargeCardAPI.togglePublished(toggleRechargeCardPublished).then((response: AxiosResponse<Result<RechargeCard>>) => {
    const result = response.data
    if (result.success) {
      const rechargeCard = result.data
      ElMessage.success(rechargeCard.published? 'Success to publish' : 'Success to unpublish')
      doListRechargeCard()
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Delete rechargeCard
const doDeleteRechargeCard = function (id: string) {
	loading.value = true
	rechargeCardAPI.deleteById(id).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete')
			doListRechargeCard()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}
</script>