<template>
  <el-dialog v-model="show" title="Update Recharge Info">
    <el-form ref="updateRechargeInfoForm" :model="updateRechargeInfo">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Remark" prop="remark" required>
				<el-input v-model="updateRechargeInfo.remark" type="textarea" rows="5" maxlength="100" show-word-limit clearable></el-input>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateRechargeInfo">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import rechargeAPI from '../../api/recharge'
import { Result } from '../../types/common/result'
import { Recharge, UpdateRechargeInfo, UpdateRechargeInfoImpl } from '../../types/recharge'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	recharge: Recharge
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const updateRechargeInfo = reactive<UpdateRechargeInfo>(new UpdateRechargeInfoImpl(props.recharge))
const updateRechargeInfoForm = ref<InstanceType<typeof ElForm>>()

const doUpdateRechargeInfo = function () {
	updateRechargeInfoForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			rechargeAPI.updateInfo(updateRechargeInfo).then((response: AxiosResponse<Result<Recharge>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>