<template>
	<div class="actionbar">
		<div>Teaching Guide</div>
		<el-button-group>
			<el-button text type="primary" @click="showUpdateCourseGuideDialog = true">Edit</el-button>
		</el-button-group>
	</div>

	<el-divider />

  <div class="mx-2 my-2" v-if="course.guide" v-html="course.guide"></div>

	<update-course-guide-dialog v-if="showUpdateCourseGuideDialog" v-model:visible="showUpdateCourseGuideDialog" :course="course" @success="onUpdateCourseGuideSuccess"></update-course-guide-dialog>
</template>
<script setup lang="ts">
import { ElMessage } from 'element-plus';
import { ref } from 'vue';
import UpdateCourseGuideDialog from '../../components/course/UpdateCourseGuideDialog.vue';
import { Course } from '../../types/course';

const props = defineProps<{
  course: Course
}>()

const emit = defineEmits<{
  (e: 'updated'): void
}>()

// Edit course guide
const showUpdateCourseGuideDialog = ref<boolean>(false)
const onUpdateCourseGuideSuccess = function () {
	ElMessage.success('Success to edit')
	emit('updated')
}
</script>
