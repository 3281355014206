import { Status, TransactionStatus } from "../../../withdraw"
import { Filters } from "./index"

export interface WithdrawFilters extends Filters {
	teacherId?: string
	status?: Status
	transactionStatus?: TransactionStatus
}

export class WithdrawFiltersImpl implements WithdrawFilters {
	teacherId?: string
	status?: Status
	transactionStatus?: TransactionStatus

	reset(): void {
		this.teacherId = undefined
		this.status = undefined
		this.transactionStatus = undefined
	}
}