export interface StudentAvatar {
	id: string
	url: string
}

export interface CreateStudentAvatar {
	url: string
}

export class CreateStudentAvatarImpl implements CreateStudentAvatar {
	url: string
	
	constructor(url: string) {
		this.url = url
	}
}