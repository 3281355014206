export enum Subject {
	ENGLISH = 'ENGLISH',
	MATH = 'MATH'
}

export interface Serie {
	id: string
	name?: string
	subject?: Subject
	major?: boolean
	number?: number
	hidden?: boolean
}

export class SerieImpl implements Serie {
	id: string
	
	constructor(id: string) {
		this.id = id
	}
}

export interface CreateSerie {
	name?: string
	subject?: Subject
	major?: boolean
	number?: number
}

export class CreateSerieImpl implements CreateSerie {
	subject?: Subject = Subject.ENGLISH
}

export interface UpdateSerieInfo {
	id: string
	name?: string
	subject?: Subject
	major?: boolean
	number?: number
}

export class UpdateSerieInfoImpl implements UpdateSerieInfo {
	id: string
	name?: string
	subject?: Subject
	major?: boolean
	number?: number

	constructor(serie: Serie) {
		this.id = serie.id
		this.name = serie.name
		this.subject = serie.subject
		this.major = serie.major
		this.number = serie.number
	}
}

export interface ToggleSerieHidden {
	id: string
}

export class ToggleSerieHiddenImpl implements ToggleSerieHidden {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface MoveSerieForward {
	id: string
}

export class MoveSerieForwardImpl implements MoveSerieForward {
	id: string

	constructor(serie: Serie) {
		this.id = serie.id
	}
}

export interface MoveSerieBackward {
	id: string
}

export class MoveSerieBackwardImpl implements MoveSerieBackward {
	id: string

	constructor(serie: Serie) {
		this.id = serie.id
	}
}