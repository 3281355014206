import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { Recruitment } from "../types/recruitment"
import service from "./api-service"

const api = '/recruitment'

const recruitmentAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Recruitment>>>(api + '/list', query)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default recruitmentAPI