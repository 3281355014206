<template>
  <el-dialog v-model="show" title="Create Work">
    <el-form ref="createWorkForm" :model="createWork" label-width="100px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Copy" prop="copy">
				<el-input v-model="createWork.copy" :rows="3" type="textarea"></el-input>
			</el-form-item>
			<el-form-item label="Dictation" prop="dictation">
				<el-input v-model="createWork.dictation" :rows="3" type="textarea"></el-input>
			</el-form-item>
			<el-form-item label="Writing" prop="writing">
				<el-input v-model="createWork.writing" :rows="3" type="textarea"></el-input>
			</el-form-item>
			<el-form-item label="Practice">
				<el-switch v-model="createWork.practice" />
			</el-form-item>
			<el-form-item label="Reading">
				<el-switch v-model="createWork.reading" />
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doCreateWork">Create</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import workAPI from '../../api/work'
import { Result } from '../../types/common/result'
import { CreateWork, CreateWorkImpl, Work } from '../../types/work'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	lessonId: string
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const createWork = reactive<CreateWork>(new CreateWorkImpl(props.lessonId))
const createWorkForm = ref<InstanceType<typeof ElForm>>()

const doCreateWork = function () {
	createWorkForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true

			workAPI.create(createWork).then((response: AxiosResponse<Result<Work>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					createWorkForm.value?.resetFields()
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>