<template>
  <el-dialog v-model="show" title="Edit Lesson" @opened="onOpened">
    <el-form ref="updateLessonInfoForm" :model="updateLessonInfo" label-width="100px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Number" prop="number" required>
				<el-input-number v-model="updateLessonInfo.number" :precision="0" :step="1" :min="0" :max="1000" />
			</el-form-item>
			<el-form-item label="Module" prop="moduleId" required>
				<el-select v-model="updateLessonInfo.moduleId" filterable style="width: 100%;">
					<el-option v-for="module in modules" :key="module.id" :label="module.fullName" :value="module.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Name" prop="name" required>
				<el-input v-model="updateLessonInfo.name"></el-input>
			</el-form-item>
			<el-form-item label="Type" prop="type" required>
				<el-radio-group v-model="updateLessonInfo.type">
					<el-radio v-for="option in LesssonTypeOptions.options" :key="option.label" :label="option.value">{{ option.label }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="Score" prop="totalTestScore" required v-if="updateLessonInfo.type === Type.TEST">
				<el-input-number v-model="updateLessonInfo.totalTestScore" :precision="0" :step="1" :min="0" :max="100" />
			</el-form-item>
			<el-form-item label="Slide" prop="slide">
				<el-input v-model="updateLessonInfo.slide"></el-input>
			</el-form-item>
			<el-form-item label="Content" prop="content">
				<div style="width: 100%; height: 300px; line-height: normal;">
					<quill-editor ref="editor" v-model:content="updateLessonInfo.content" theme="snow" contentType="html"></quill-editor>
				</div>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateLessonInfo">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { QuillEditor } from '@vueup/vue-quill'
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import lessonAPI from '../../api/lesson'
import moduleAPI from '../../api/module'
import { LesssonTypeOptions } from '../../types/common/option/lessonOptions'
import { Result } from '../../types/common/result'
import { Lesson, Type, UpdateLessonInfo, UpdateLessonInfoImpl } from '../../types/lesson'
import { Module } from '../../types/module'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	lesson: Lesson
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const editor = ref<InstanceType<typeof QuillEditor>>()

const modules = ref<Module[]>()
const doFindModules = function (courseId: string) {
	moduleAPI.findByCourseId(courseId).then((response: AxiosResponse<Result<Module[]>>) => {
		const result = response.data
		if (result.success) {
			modules.value = result.data
		} else {
			ElMessage.error(result.message)
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const updateLessonInfo = reactive<UpdateLessonInfo>(new UpdateLessonInfoImpl((props.lesson)))

const updateLessonInfoForm = ref<InstanceType<typeof ElForm>>()

const doUpdateLessonInfo = function () {
	updateLessonInfoForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			lessonAPI.updateInfo(updateLessonInfo).then((response: AxiosResponse<Result<Lesson>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				message.value = error.message
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const onOpened = function () {
	doFindModules(props.lesson.courseId!)
}
</script>