<template>
  <div class="content">
		<slot></slot>

		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item>
					<el-select v-model="filters.method" placeholder="Method">
						<el-option v-for="option in RefundMethodOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.status" placeholder="Status">
						<el-option v-for="option in RefundStatusOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-button-group v-if="parentId">
				<el-button text type="primary" @click="showCreateRefundDialog = true">Create Refund</el-button>
			</el-button-group>
			<it-placeholder v-else></it-placeholder>
		</div>

		<el-table :data="refunds" v-loading="loading"  border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column v-if="!parentId" label="Parent" prop="parentName" width="100"></el-table-column>
			<el-table-column label="Method" prop="method" width="100">
				<template #default="scope">{{RefundFilter.method(scope.row.method)}}</template>
			</el-table-column>
			<el-table-column label="Status" prop="status" width="110">
				<template #default="scope">{{RefundFilter.status(scope.row.status)}}</template>
			</el-table-column>
			<el-table-column label="Lessons" prop="lessons" width="100"></el-table-column>
			<el-table-column label="Refund" prop="refund" width="150">
				<template #default="scope">
					<div>{{EnumFilter.currency(scope.row.currency)}} {{NumberFilter.money(scope.row.refund)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Remark" prop="remark" />
			<el-table-column label="Times">
				<template #default="scope">
					<el-button-group>
						<div v-if="scope.row.createdDateTime" class="time">
							<span>Created:</span>
							<span>{{DateTimeFilter.datetime(scope.row.createdDateTime)}}</span>
						</div>
						<div v-if="scope.row.completedDateTime" class="time">
							<span>Completed: </span>
							<span>{{DateTimeFilter.datetime(scope.row.completedDateTime)}}</span>
						</div>	
						<div v-if="scope.row.voidedDateTime" class="time">
							<span>Voided:</span>
							<span>{{DateTimeFilter.datetime(scope.row.voidedDateTime)}}</span>
						</div>
					</el-button-group>
				</template>
			</el-table-column>
			<el-table-column label="Actions" fixed="right" width="110">
				<template #default="scope">
					<el-button-group>
						<el-button text type="primary" @click="doShowUpdateRefundInfoDialog(scope.row)">Edit Info</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />
	
		<create-refund-dialog v-if="parentId && showCreateRefundDialog" v-model:visible="showCreateRefundDialog" :parent-id="parentId" @success="onCreateRefundSuccess"></create-refund-dialog>

		<update-refund-info-dialog v-if="showUpdateRefundInfoDialog" v-model:visible="showUpdateRefundInfoDialog" :refund="selectedRefund" @success="onUpdateRefundInfoSuccess"></update-refund-info-dialog>
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import refundAPI from '../../api/refund'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { EnumFilter } from '../../filters/enumFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { RefundFilter } from '../../filters/refundFilter'
import { RefundMethodOptions, RefundStatusOptions } from '../../types/common/option/refundOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { RefundFilters, RefundFiltersImpl } from '../../types/common/query/filters/refundFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { RefundSearcherImpl } from '../../types/common/query/searcher/refundSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Refund, RefundImpl } from '../../types/refund'
import ItPlaceholder from '../ui/ItPlaceholder.vue'
import CreateRefundDialog from './CreateRefundDialog.vue'
import UpdateRefundInfoDialog from './UpdateRefundInfoDialog.vue'

const props = defineProps<{
  parentId?: string
}>()

const emit = defineEmits<{
  (e: 'deleted'): void
}>()

const loading = ref<boolean>(false)

const refunds = ref<Refund[]>()

onMounted(() => {
	doListRefund()
})

// Query refund
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<RefundFilters>(new RefundFiltersImpl())
const searcher = reactive<Searcher>(new RefundSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const doListRefund = function () {
	if (props.parentId) filters.parentId = props.parentId

	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	refundAPI.list(query).then((response: AxiosResponse<Result<ListObject<Refund>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			refunds.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListRefund()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListRefund()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListRefund()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListRefund()
}

// Create refund
const showCreateRefundDialog = ref<boolean>(false)
const onCreateRefundSuccess = function () {
	ElMessage.success('Success to create')
	doListRefund()
}

const selectedRefund = ref<Refund>(new RefundImpl(''))

// Edit refund info
const showUpdateRefundInfoDialog = ref<boolean>(false)
const doShowUpdateRefundInfoDialog = function (refund: Refund) {
	selectedRefund.value = refund
	showUpdateRefundInfoDialog.value = true
}
const onUpdateRefundInfoSuccess = function () {
	ElMessage.success('Success to edit info')
	doListRefund()
}
</script>

<style scoped>
.time {
	width: 200px;
	display: flex;
	justify-content: space-between;
}
</style>