<template>
  <div class="content">
		<slot></slot>
		
		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item v-if="props.parentId">
					<el-select v-model="filters.studentId" placeholder="Student">
						<el-option v-for="student in students" :key="student.id" :label="student.nickname" :value="student.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.status" placeholder="Status">
						<el-option v-for="option in EnrollmentStatusOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.suspendReason" placeholder="Suspend Reason">
						<el-option v-for="option in EnrollmentSuspendReasonOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-button-group v-if="parentId">
				<el-button text type="primary" @click="showCreateEnrollmentDialog = true">Create Enrollment</el-button>
			</el-button-group>
			<it-placeholder v-else></it-placeholder>
		</div>

		<el-table :data="enrollments" v-loading="loading"  border class="table" row-key="id">	
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column label="Status" prop="status" width="120">
				<template #default="scope">
					<div>{{EnrollmentFilter.status(scope.row.status)}}</div>
					<div>{{EnrollmentFilter.suspendReason(scope.row.suspendReason)}}</div>
				</template>
			</el-table-column>
			<el-table-column v-if="!parentId" label="Parent" prop="parentNickname" width="100"></el-table-column>
			<el-table-column label="Student" prop="studentNickname" width="100"></el-table-column>
			<el-table-column label="Arrange">
				<template #default="scope">
					<div v-for="(arrange, index) in scope.row.arranges" :key="index" style="padding: 5px 10px;">
						{{ EnumFilter.dayOfWeek(arrange.dayOfWeek) }} {{ DateTimeFilter.time(arrange.startTime) }} : {{ arrange.teacherNickname }}
					</div>
				</template>
			</el-table-column>
			<el-table-column label="Course" prop="courseFullName"></el-table-column>
			<el-table-column label="Latest Booked Lesson" prop="latestBookedLessonFullName"></el-table-column>
			<el-table-column label="Latest Finished Lesson" prop="latestFinishedLessonFullName"></el-table-column>
			<el-table-column label="Actions" fixed="right" width="260">
				<template #default="scope">
					<el-button-group>
						<el-button text type="primary" :disabled="scope.row.status !== Status.CREATED && scope.row.status != Status.SUSPENDED" @click="doShowArrangeEnrollmentDialog(scope.row)">Arrange</el-button>
						<el-popconfirm title="Are you sure to start the enrollment?" @confirm="doStartEnrollment(scope.row)">
							<template #reference>
								<el-button text type="primary" :disabled="scope.row.status != Status.CREATED">Start</el-button>
							</template>
						</el-popconfirm>
						<el-button text type="primary" :disabled="scope.row.status !== Status.STARTED" @click="doRearrangeEnrollment(scope.row)">Rearrange</el-button>
					</el-button-group>
					<el-button-group>
						<el-button text type="primary" :disabled="scope.row.status !== Status.STARTED" @click="doShowSuspendEnrollmentDialog(scope.row)">Suspend</el-button>
						<el-button text type="primary" :disabled="scope.row.status !== Status.SUSPENDED" @click="doShowUpdateEnrollmentSuspendReasonDialog(scope.row)">Update Reason</el-button>
					</el-button-group>
					<el-button-group>
						<el-popconfirm title="Are you sure to cancel the enrollment?" @confirm="doCancelEnrollment(scope.row)">
							<template #reference>
								<el-button text type="primary" :disabled="scope.row.status !== Status.CREATED && scope.row.status !== Status.STARTED">Cancel</el-button>
							</template>
						</el-popconfirm>
						<el-popconfirm title="Are you sure to restart the enrollment?" @confirm="doRestartEnrollment(scope.row)">
							<template #reference>
								<el-button text type="primary" :disabled="scope.row.status != Status.SUSPENDED && scope.row.status != SuspendReason.LEAVE">Restart</el-button>
							</template>
						</el-popconfirm>
						<el-popconfirm title="Are you sure to finish the enrollment?" @confirm="doFinishEnrollment(scope.row)">
							<template #reference>
								<el-button text type="primary" :disabled="scope.row.status !== Status.STARTED">Finish</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
					<el-button-group>
						<el-popconfirm title="Are you sure to recreate the enrollment?" @confirm="doRecreateEnrollment(scope.row)">
							<template #reference>
								<el-button text type="primary" :disabled="scope.row.status != Status.FINISHED">Recreate</el-button>
							</template>
						</el-popconfirm>
						<el-button text type="primary" :disabled="scope.row.status !== Status.STARTED" @click="doShowUpdateEnrollmentInfoDialog(scope.row)">Edit</el-button>
						<el-popconfirm title="Are you sure to delete the enrollment?" @confirm="doDeleteEnrollment(scope.row)">
							<template #reference>
								<el-button text type="primary">Delete</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
					<el-button-group>
						<el-popconfirm title="Are you sure to book this week arrangement?" @confirm="doBookThisWeekArrangementByEnrollmentId(scope.row.id)">
							<template #reference>
								<el-button text type="primary" :disabled="scope.row.status !== Status.STARTED" :loading="loading">Book This Week Arrangement</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
					<el-button-group>
						<el-popconfirm title="Are you sure to book next week arrangement?" @confirm="doBookNextWeekArrangementByEnrollmentId(scope.row.id)">
							<template #reference>
								<el-button text type="primary" :disabled="scope.row.status !== Status.STARTED" :loading="loading">Book Next Week Arrangement</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />
	
		<create-enrollment-dialog v-if="parentId && showCreateEnrollmentDialog" v-model:visible="showCreateEnrollmentDialog" :parent-id="parentId" @success="onCreateEnrollmentSuccess"></create-enrollment-dialog>
	
		<update-enrollment-info-dialog v-if="showUpdateEnrollmentInfoDialog" v-model:visible="showUpdateEnrollmentInfoDialog" :enrollment="selectedEnrollment" @success="onUpdateEnrollmentInfoSuccess"></update-enrollment-info-dialog>

		<update-enrollment-suspend-reason-dialog v-if="showUpdateEnrollmentSuspendReasonDialog" v-model:visible="showUpdateEnrollmentSuspendReasonDialog" :enrollment="selectedEnrollment" @success="onUpdateEnrollmentSuspendReasonSuccess"></update-enrollment-suspend-reason-dialog>

		<arrange-enrollment-dialog v-if="showArrangeEnrollmentDialog" v-model:visible="showArrangeEnrollmentDialog" :enrollment="selectedEnrollment" @success="onArrangeEnrollmentSuccess"></arrange-enrollment-dialog>

		<suspend-enrollment-dialog v-if="showSuspendEnrollmentDialog" v-model:visible="showSuspendEnrollmentDialog" :enrollment="selectedEnrollment" @success="onSuspendEnrollmentSuccess"></suspend-enrollment-dialog>
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import arrangementAPI from '../../api/arrangement'
import enrollmentAPI from '../../api/enrollment'
import studentAPI from '../../api/student'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { EnrollmentFilter } from '../../filters/enrollmentFilter'
import { EnumFilter } from '../../filters/enumFilter'
import { EnrollmentStatusOptions, EnrollmentSuspendReasonOptions } from '../../types/common/option/enrollmentOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { EnrollmentFilters, EnrollmentFiltersImpl } from '../../types/common/query/filters/enrollmentFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { EnrollmentSearcherImpl } from '../../types/common/query/searcher/enrollmentSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { CancelEnrollmentImpl, Enrollment, EnrollmentImpl, FinishEnrollmentImpl, RearrangeEnrollmentImpl, RecreateEnrollment, RecreateEnrollmentImpl, RestartEnrollment, RestartEnrollmentImpl, StartEnrollment, StartEnrollmentImpl, Status, SuspendReason } from '../../types/enrollment'
import { Student } from '../../types/student'
import ItPlaceholder from '../ui/ItPlaceholder.vue'
import ArrangeEnrollmentDialog from './ArrangeEnrollmentDialog.vue'
import CreateEnrollmentDialog from './CreateEnrollmentDialog.vue'
import SuspendEnrollmentDialog from './SuspendEnrollmentDialog.vue'
import UpdateEnrollmentInfoDialog from './UpdateEnrollmentInfoDialog.vue'
import UpdateEnrollmentSuspendReasonDialog from './UpdateEnrollmentSuspendReasonDialog.vue'

const props = defineProps<{
  parentId?: string,
	teacherId?: string
}>()

const loading = ref<boolean>(false)

const enrollments = ref<Enrollment[]>()

onMounted(() => {
	doListEnrollment()
	doFindStudentByParentId()
})

const students = ref<Student[]>()
const doFindStudentByParentId = function () {
	if (props.parentId) {
		studentAPI.findByParentId(props.parentId).then((response: AxiosResponse<Result<Student[]>>) => {
			const result = response.data
			if (result.success) {
				students.value = result.data
			} else {
				ElMessage.error(result.message)
			}						
		}).catch((error: AxiosError) => {
			ElMessage.error(error.message)
		})
	}
}

// Query enrollment
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<EnrollmentFilters>(new EnrollmentFiltersImpl())
const searcher = reactive<Searcher>(new EnrollmentSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const doListEnrollment = function () {
	if (props.parentId) filters.parentId = props.parentId
	if (props.teacherId) filters.teacherId = props.teacherId
	
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	enrollmentAPI.list(query).then((response: AxiosResponse<Result<ListObject<Enrollment>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			enrollments.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListEnrollment()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListEnrollment()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListEnrollment()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListEnrollment()
}

// Create Enrollment
const showCreateEnrollmentDialog = ref<boolean>(false)
const onCreateEnrollmentSuccess = function () {
	ElMessage.success('Success to create')
	doListEnrollment()
}

const selectedEnrollment = ref<Enrollment>(new EnrollmentImpl(''))

// Edit Enrollment
const showUpdateEnrollmentInfoDialog = ref<boolean>(false)
const doShowUpdateEnrollmentInfoDialog = function (enrollment: Enrollment) {
	selectedEnrollment.value = enrollment
	showUpdateEnrollmentInfoDialog.value = true
}
const onUpdateEnrollmentInfoSuccess = function () {
	ElMessage.success('Success to edit')
	doListEnrollment()
}

// Update Enrollment Suspend Reason
const showUpdateEnrollmentSuspendReasonDialog = ref<boolean>(false)
const doShowUpdateEnrollmentSuspendReasonDialog = function (enrollment: Enrollment) {
	selectedEnrollment.value = enrollment
	showUpdateEnrollmentSuspendReasonDialog.value = true
}
const onUpdateEnrollmentSuspendReasonSuccess = function () {
	ElMessage.success('Success to update')
	doListEnrollment()
}

// Arrange Enrollment
const showArrangeEnrollmentDialog = ref<boolean>(false)
const doShowArrangeEnrollmentDialog = function (enrollment: Enrollment) {
	selectedEnrollment.value = enrollment
	showArrangeEnrollmentDialog.value = true
}
const onArrangeEnrollmentSuccess = function () {
	ElMessage.success('Success to arrange')
	doListEnrollment()
}

// Suspend Enrollment
const showSuspendEnrollmentDialog = ref<boolean>(false)
const doShowSuspendEnrollmentDialog = function (enrollment: Enrollment) {
	selectedEnrollment.value = enrollment
	showSuspendEnrollmentDialog.value = true
}
const onSuspendEnrollmentSuccess = function () {
	ElMessage.success('Success to suspend')
	doListEnrollment()
}

// Start Enrollment
const doStartEnrollment = function (enrollment: Enrollment) {
	loading.value = true
	const startEnrollment: StartEnrollment = new StartEnrollmentImpl(enrollment.id)
	enrollmentAPI.start(startEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to start')
			doListEnrollment()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Restart Enrollment
const doRestartEnrollment = function (enrollment: Enrollment) {
	loading.value = true
	const restartEnrollment: RestartEnrollment = new RestartEnrollmentImpl(enrollment.id)
	enrollmentAPI.restart(restartEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
		const result = response.data
		if (result.success) {
			selectedEnrollment.value = enrollment
			showArrangeEnrollmentDialog.value = true
			doListEnrollment()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Recreate Enrollment
const doRecreateEnrollment = function (enrollment: Enrollment) {
	loading.value = true
	const recreateEnrollment: RecreateEnrollment = new RecreateEnrollmentImpl(enrollment.id)
	enrollmentAPI.recreate(recreateEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to recreate')
			doListEnrollment()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Rearrange Enrollment
const doRearrangeEnrollment = function (enrollment: Enrollment) {
	loading.value = true
	const rearrangeEnrollment = new RearrangeEnrollmentImpl(enrollment.id)
	enrollmentAPI.rearrange(rearrangeEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
		const result = response.data
		if (result.success) {
			selectedEnrollment.value = enrollment
			showArrangeEnrollmentDialog.value = true
			doListEnrollment()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Cancel Enrollment
const doCancelEnrollment = function (enrollment: Enrollment) {
	loading.value = true
	const cancelEnrollment = new CancelEnrollmentImpl(enrollment.id)
	enrollmentAPI.cancel(cancelEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to cancel')
			doListEnrollment()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Finish Enrollment
const doFinishEnrollment = function (enrollment: Enrollment) {
	loading.value = true
	const finishEnrollment = new FinishEnrollmentImpl(enrollment.id)
	enrollmentAPI.finish(finishEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to finish')
			doListEnrollment()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

// Delete Enrollment
const doDeleteEnrollment = function (enrollment: Enrollment) {
	loading.value = true
	enrollmentAPI.deleteById(enrollment.id).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete')
			doListEnrollment()
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

const doBookThisWeekArrangementByEnrollmentId = function (enrollmentId: string) {
	loading.value = true
	arrangementAPI.bookThisWeekByEnrollmentId(enrollmentId).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('success to arrange')
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

const doBookNextWeekArrangementByEnrollmentId = function (enrollmentId: string) {
	loading.value = true
	arrangementAPI.bookNextWeekByEnrollmentId(enrollmentId).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('success to arrange')
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}
</script>

<style scoped>
.time {
	display: flex;
	justify-content: space-between;
}
</style>