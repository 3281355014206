<template>
  <el-dialog v-model="show" title="Edit Student Status">
    <el-form ref="updateStudentStatusForm" :model="updateStudentStatus" label-width="100px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Status" prop="status" required>
				<el-select v-model="updateStudentStatus.status" style="width: 100%;">
						<el-option v-for="option in StudentStatusOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateStudentStatus">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import studentAPI from '../../api/student'
import { StudentStatusOptions } from '../../types/common/option/studentOptions'
import { Result } from '../../types/common/result'
import { Student, UpdateStudentStatus, UpdateStudentStatusImpl } from '../../types/student'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	student: Student
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

let updateStudentStatus = reactive<UpdateStudentStatus>(new UpdateStudentStatusImpl(props.student))

const updateStudentStatusForm = ref<InstanceType<typeof ElForm>>()

const doUpdateStudentStatus = function () {
	updateStudentStatusForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			studentAPI.updateStatus(updateStudentStatus).then((response: AxiosResponse<Result<Student>>) => {
				const result: Result<Student> = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>