<template>
  <el-dialog v-model="show" title="Create Agent" @opened="onOpened">
    <el-form ref="createAgentForm" :model="createAgent" label-width="100px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Name" prop="name" required>
				<el-input v-model="createAgent.name"></el-input>
			</el-form-item>
			<el-form-item label="Mobile" prop="fullMobile" required>
				<el-input v-model="createAgent.mobile" class="input-with-select" @blur="onMobileInputBlur">
					<template #prepend>
						<el-select v-model="createAgent.region" placeholder="Country Code" style="width: 190px;" filterable @change="onRegionSelectChange">
							<el-option v-for="phoneCountryCode in phoneCountryCodes" :key="phoneCountryCode.region" :label="phoneCountryCode.country" :value="phoneCountryCode.region"></el-option>
						</el-select>
					</template>
				</el-input>
			</el-form-item>
			<el-form-item label="Locale" prop="locale" required>
				<el-select v-model="createAgent.locale" filterable>
					<el-option v-for="(option, index) in LocaleOptions.options" :key="index" :label="option.label" :value="option.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Time Zone" prop="zoneId" required>
				<el-select v-model="createAgent.zoneId" filterable>
					<el-option v-for="(availableZoneId, index) in availableZoneIds" :key="index" :label="availableZoneId" :value="availableZoneId"></el-option>
				</el-select>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doCreateAgent">Create</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import agentAPI from '../../api/agent'
import mobileAPI from '../../api/mobile'
import zoneIdAPI from '../../api/zoneId'
import { Agent, CreateAgent, CreateAgentImpl } from '../../types/agent'
import { LocaleOptions } from '../../types/common/option/localeOptions'
import { Result } from '../../types/common/result'
import { PhoneCountryCode } from '../../types/mobile'
import { getCountryCodeFromRegion, getFullMobile } from '../../utils/mobileUtils'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const createAgent = reactive<CreateAgent>(new CreateAgentImpl())

const createAgentForm = ref<InstanceType<typeof ElForm>>()

const doCreateAgent = function () {
	createAgentForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			agentAPI.create(createAgent).then((response: AxiosResponse<Result<Agent>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					createAgentForm.value?.resetFields()
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				message.value = error.message
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

// time zone
const availableZoneIds = ref<string[]>()
const doFindAvailableZoneIds = function () {
	zoneIdAPI.findAvailable().then((response: AxiosResponse<Result<string[]>>) => {
		const result = response.data
		if (result.success) {
			availableZoneIds.value = result.data
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// phone country code
const phoneCountryCodes = ref<PhoneCountryCode[]>()
const doFindAllPhoneCountryCodes = function () {
	mobileAPI.findAllCountryCode().then((response: AxiosResponse<Result<PhoneCountryCode[]>>) => {
		const result = response.data
		if (result.success) {
			phoneCountryCodes.value = result.data
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const onRegionSelectChange = function () {
	setFullMobile()
}

const onMobileInputBlur = function () {
	setFullMobile()
}

const setFullMobile = function () {
	createAgent.countryCode = getCountryCodeFromRegion(phoneCountryCodes.value, createAgent.region)
	createAgent.fullMobile = getFullMobile(createAgent.countryCode, createAgent.mobile)
}

const onOpened = function () {
	doFindAvailableZoneIds()
	doFindAllPhoneCountryCodes()
}
</script>