import { getFullMobile } from "../utils/mobileUtils"
import { Certificate } from "./certificate"
import { Accent, Country } from "./enums"

export enum Level {
	BRONZE = 'BRONZE', // 铜牌
  SILVER = 'SILVER', // 银牌
  GOLD = 'GOLD', // 金牌
	PLATINUM = 'PLATINUM' // 白金
}

export interface Teacher {
	id: string
	level?: Level
	email?: string
	latestPayPalEmail?: string
	region?: string
	countryCode?: string
	mobile?: string
	fullMobile?: string
	zoneId?: string
	firstName?: string
	lastName?: string
	nickname?: string
	avatar?: string
	introduction?: string
	show?: string
	promoteCode?: string
	referrerId?: string
	referrerName?: string
	country?: string
	displayCountry?: Country
	accent?: Accent
	certificates?: Certificate[]
	certified?: boolean
	vendor?: boolean
	support?: boolean

	fee?: number
	classFee?: number

	base?: number
  addition?: number
  wage?: number
	classWage?: number

  balance?: number
  totalEarning?: number
  totalWithdraw?: number

  totalTeachingMinutes?: number
  rank?: number

	ready?: boolean
	locked?: boolean
	createdDateTime?: Date
	basicInfoComplete?: boolean
	academicInfoComplete?: boolean
	complete?: boolean

	hidden?: boolean

	zoomAccount?: string
	zoomPassword?: string
}

export class TeacherImpl implements Teacher {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface SimpleTeacher {
	id: string
	level?: Level
	email: string
	countryCode?: string
	mobile?: string
	fullMobile?: string
	firstName: string
	lastName: string
	nickname: string
	country: string
	displayCountry: Country
	accent: Accent
	certificates: Certificate[]
	rank: number
	certified: boolean
	vendor: boolean
	ready: boolean
	locked: boolean
	complete: boolean
}

export interface NanoTeacher {
	id: string
	fullName: string
	nickname: string
}

export interface CreateTeacher {
	email?: string
	region?: string
	countryCode?: string
	mobile?: string
	fullMobile?: string
	zoneId?: string
	vendor: boolean
	avatar?: string
	firstName?: string
	lastName?: string
	nickname?: string
	country?: string
	displayCountry?: Country
	accent?: Accent
	introduction?: string
	show?: string
	certificates?: Certificate[]
}

export class CreateTeacherImpl implements CreateTeacher {
	vendor: boolean

	constructor() {
		this.vendor = true
	}
}

export interface CreateSupportTeacher {
	email?: string
	avatar?: string
	firstName?: string
	lastName?: string
	nickname?: string
	zoneId?: string
}

export class CreateSupportTeacherImpl implements CreateSupportTeacher {

}

export interface ToggleTeacherReady {
	id: string
}

export class ToggleTeacherReadyImpl implements ToggleTeacherReady {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface ToggleTeacherHidden {
	id: string
}

export class ToggleTeacherHiddenImpl implements ToggleTeacherHidden {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface ToggleTeacherLocked {
	id: string
}

export class ToggleTeacherLockedImpl implements ToggleTeacherLocked {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface ToggleTeacherCertified {
	id: string
}

export class ToggleTeacherCertifiedImpl implements ToggleTeacherCertified {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface ToggleTeacherVendor {
	id: string
}

export class ToggleTeacherVendorImpl implements ToggleTeacherVendor {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface UpdateTeacherDetailInfo {
	id: string
	firstName?: string
	lastName?: string
	nickname?: string
	country?: string
	displayCountry?: Country
	accent?: Accent
	email?: string
	region?: string
	countryCode?: string
	mobile?: string
	fullMobile?: string
	zoneId?: string
}

export class UpdateTeacherDetailInfoImpl implements UpdateTeacherDetailInfo {
	id: string
	firstName?: string
	lastName?: string
	nickname?: string
	country?: string
	displayCountry?: Country
	accent?: Accent
	email?: string
	region?: string
	countryCode?: string
	mobile?: string
	fullMobile?: string
	zoneId?: string

	constructor(teacher: Teacher) {
		this.id = teacher.id
		this.firstName = teacher.firstName
		this.lastName = teacher.lastName
		this.nickname = teacher.nickname
		this.country = teacher.country
		this.displayCountry = teacher.displayCountry
		this.accent = teacher.accent
		this.email = teacher.email
		this.region = teacher.region
		this.countryCode = teacher.countryCode
		this.mobile = teacher.mobile
		this.fullMobile = getFullMobile(teacher.countryCode, teacher.mobile)
		this.zoneId = teacher.zoneId
	}
}

export interface UpdateTeacherWageBase {
	id: string
	base?: number
}

export class UpdateTeacherWageBaseImpl implements UpdateTeacherWageBase {
	id: string
	base?: number

	constructor(teacher: Teacher) {
		this.id = teacher.id
		this.base = teacher.base
	}
}

export interface UpdateTeacherFee {
	id: string
	fee?: number
}

export class UpdateTeacherFeeImpl implements UpdateTeacherFee {
	id: string
	fee?: number

	constructor(teacher: Teacher) {
		this.id = teacher.id
		this.fee = teacher.fee
	}
}

export interface UpdateTeacherZoomAccount {
	id: string
	zoomAccount?: string
	zoomPassword?: string
}

export class UpdateTeacherZoomAccountImpl implements UpdateTeacherZoomAccount {
	id: string
	zoomAccount?: string
	zoomPassword?: string

	constructor(teacher: Teacher) {
		this.id = teacher.id
		this.zoomAccount = teacher.zoomAccount
		this.zoomPassword = teacher.zoomPassword
	}
}