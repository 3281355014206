import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { CreateSerie, Serie, ToggleSerieHidden, UpdateSerieInfo, MoveSerieForward, MoveSerieBackward } from "../types/serie"
import service from "./api-service"

const api = '/serie'

const serieAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Serie>>>(api + '/list', query)
  },

  findAll () {
    return service.get<Result<Serie[]>>(api + '/findAll')
  },
  
  findById (id: string) {
    return service.get<Result<Serie>>(api + '/findById', {
      params: {
        id: id
      }
    })
  },

  create (createSerie: CreateSerie) {
    return service.post<Result<Serie>>(api, createSerie)
  },

  updateInfo (updateSerieInfo: UpdateSerieInfo) {
    return service.put<Result<Serie>>(api + '/updateInfo', updateSerieInfo)
  },

  toggleHidden (toggleSerieHidden: ToggleSerieHidden) {
    return service.put<Result<Serie>>(api + '/toggleHidden', toggleSerieHidden)
  },

  moveForward (moveSerieForward: MoveSerieForward) {
    return service.put<Result<Serie>>(api + '/moveForward', moveSerieForward)
  },

  moveBackward (moveSerieBackward: MoveSerieBackward) {
    return service.put<Result<Serie>>(api + '/moveBackward', moveSerieBackward)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default serieAPI