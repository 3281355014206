<template>
  <div class="content">
    <div class="actionbar">
			<div>Invitation Poster</div>
			<el-button-group>
				<el-button text type="primary" :loading="generating" @click="doGenerateParentPoster">Generate Poster</el-button>
			</el-button-group>
		</div>

    <el-divider />

    <el-image v-if="parent.poster" v-loading="generating" style="width: 100%" :src="parent.poster" fit="contain" />
    <el-empty v-else description="No poster yet">
      <el-button type="primary" :loading="generating" @click="doGenerateParentPoster">Generate</el-button>
    </el-empty>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios';
import { ElMessage } from 'element-plus';
import { onMounted, reactive, ref } from 'vue';
import parentAPI from '../../api/parent';
import { Result } from '../../types/common/result';
import { GenerateParentPosterImpl, Parent, ParentImpl } from '../../types/parent';
import { cloneFrom } from '../../utils/clone';

const props = defineProps<{
  parentId: string
}>()

// 查询代理
const parent = reactive<Parent>(new ParentImpl(props.parentId))
const doFindParent = function () {
  parentAPI.findById(props.parentId).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(parent, result.data)
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  })
}

// 生成海报
const generating = ref<boolean>(false)
const doGenerateParentPoster = function () {
  const generateParentPoster = new GenerateParentPosterImpl(parent.id)
  generating.value = true
  parentAPI.generatePoster(generateParentPoster).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      parent.poster = result.data.poster
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() =>  {
    generating.value = false
  })
}

onMounted(() => {
  doFindParent()
})
</script>