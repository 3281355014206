import { Role } from "../types/staff"

export class StaffFilter {
    static role (role?: Role): string {
        switch (role) {
            case Role.ADMIN:
                return 'Admin'
            case Role.CXO:
                return 'CXO'
            case Role.CS:
                return 'CS'
            default:
                return 'Undefined' 
        }
    }

    static locked (locked: boolean | undefined): string {
        if (locked == undefined) return ''
        else return locked? 'Locked' : 'Normal'
    }
}