<template>
  <el-dialog v-model="show" title="Rebook Arrangement" @opened="onOpened">
    <el-form ref="rebookArrangementForm" :model="rebookArrangement" label-width="100px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Parent">
				<span style="width: 300px;">{{ arrangement?.parentNickname }}</span>
				<span style="margin-left: 10px;">Time Zone: {{arrangement?.parentZoneId}}</span>
			</el-form-item>
			<el-form-item label="Student">
				<span style="width: 300px;">{{ arrangement?.studentNickname }}</span>
			</el-form-item>
			<el-form-item label="Enrollment">
				<span style="width: 300px;">{{ arrangement?.courseFullName }}</span>
			</el-form-item>
			<el-form-item label="Lesson" prop="lessonId" required>
				<el-select v-model="rebookArrangement.lessonId" filterable style="width: 300px;">
					<el-option v-for="lesson in lessons" :key="lesson.id" :label="lesson.fullName" :value="lesson.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Teacher" prop="teacherId" required>
				<el-select v-model="rebookArrangement.teacherId" filterable style="width: 300px;" @change="onSelectTeacher">
					<el-option v-for="teacher in teachers" :key="teacher.id" :label="teacher.nickname" :value="teacher.id"></el-option>
				</el-select>
				<span v-if="teacher" style="margin-left: 10px;">Time Zone: {{teacher.zoneId}}</span>
			</el-form-item>
			<el-form-item label="Start Time" prop="startDateTime" required>
				<it-date-time-picker v-model:datetime="rebookArrangement.startDateTime"></it-date-time-picker>
				<span style="margin-left: 10px;">(Student)</span>
			</el-form-item>
			<el-form-item v-if="teacher" label="Date Time">
				<span style="width: 300px;">{{ teacherDateTimeString }}</span>
				<span style="margin-left: 10px;">(Teacher)</span>
			</el-form-item>
			<el-form-item label="Trial" required>
				<el-switch v-model="rebookArrangement.trial" />
				<span style="margin-left: 10px;">Is trial class for student</span>
			</el-form-item>
			<el-form-item label="Demo" required>
				<el-switch v-model="rebookArrangement.demo" />
				<span style="margin-left: 10px;">Is demo class for teacher</span>
			</el-form-item>
			<el-form-item label="Urgent" required>
				<el-switch v-model="rebookArrangement.urgent" />
				<span style="margin-left: 10px;">Is urgent class within 24h</span>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doRebookArrangement">Rebook</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { computed, reactive, ref } from 'vue'
import arrangementAPI from '../../api/arrangement'
import lessonAPI from '../../api/lesson'
import teacherAPI from '../../api/teacher'
import { Arrangement, RebookArrangement, RebookArrangementImpl } from '../../types/arrangement'
import { Result } from '../../types/common/result'
import { Lesson } from '../../types/lesson'
import { Teacher } from '../../types/teacher'
import { useModelWrapper } from '../../utils/modelWrapper'
import ItDateTimePicker from '../ui/ItDateTimePicker.vue'

const props = defineProps<{
  visible: boolean,
	arrangement: Arrangement
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

// Find lessons
const lessons = ref<Lesson[]>()
const doFindLessons = function (courseId: string) {
	lessonAPI.findByCourseId(courseId).then((response: AxiosResponse<Result<Lesson[]>>) => {
		const result = response.data
		if (result.success) {
			lessons.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// Find Teacher
const teacher = ref<Teacher>()
const doFindTeacher = function (id: string) {
	teacherAPI.findById(id).then((response: AxiosResponse<Result<Teacher>>) => {
		const result = response.data
		if (result.success) {
			teacher.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

// Find Teachers
const teachers = ref<Teacher[]>()
const doFindTeachers = function () {
	teacherAPI.findReady().then((response: AxiosResponse<Result<Teacher[]>>) => {
		const result = response.data
		if (result.success) {
			teachers.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}
const onSelectTeacher = function () {
	teacher.value = teachers.value?.filter(teacher => teacher.id === rebookArrangement.teacherId)[0]
}

const teacherDateTimeString = computed(() => {
	return rebookArrangement.startDateTime.setZone(teacher.value?.zoneId).set({minute: 0, second: 0, millisecond: 0}).toFormat('yyyy-MM-dd HH:mm')
})

const rebookArrangement = reactive<RebookArrangement>(new RebookArrangementImpl(props.arrangement))

const rebookArrangementForm = ref<InstanceType<typeof ElForm>>()

const doRebookArrangement = function () {
	rebookArrangementForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			arrangementAPI.rebook(rebookArrangement).then((response: AxiosResponse<Result<Arrangement>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				message.value = error.message
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const onOpened = function () {
	doFindTeachers()
	
	if(props.arrangement.courseId) doFindLessons(props.arrangement.courseId)
	if(props.arrangement.teacherId) doFindTeacher(props.arrangement.teacherId)
}
</script>