export class LocaleFilter {
  static locale (locale?: string): string {
    switch (locale) {
      case 'zh-CN':
        return 'Simplified Chinese'
      case 'zh-TW':
        return 'Traditional Chinese'
      case 'en-US':
        return 'US English'
      case 'en':
        return 'English'
      default:
        return '' 
    }
  }
}