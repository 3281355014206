<template>
  <div class="content">
		<div class="actionbar">
			<div>Arrangement Statistic</div>
			<el-button-group>
				<el-button text type="primary">Export</el-button>
			</el-button-group>
		</div>

    <el-divider />

		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item v-if="!date">
					<el-date-picker v-model="filters.startDateTime" type="date" placeholder="Date" />
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.status" placeholder="Status" style="width: 150px;">
						<el-option v-for="option in ArrangementStatusOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.cancelReason" placeholder="Cancel Reason" style="width: 150px;">
						<el-option v-for="option in ArrangementCancelReasonOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<it-placeholder></it-placeholder>
		</div>

		<el-table :data="arrangements" v-loading="loading"  border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column label="Status" width="120">
				<template #default="scope">
					<div>{{ArrangementFilter.status(scope.row.status)}}</div>
					<div>{{ArrangementFilter.cancelReason(scope.row.cancelReason)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="GMT Time" width="120">
				<template #default="scope">
					<div>{{DateTimeFilter.date(scope.row.startDateTime, 'GMT')}}</div>
					<div>{{DateTimeFilter.time(scope.row.startDateTime, 'GMT')}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Student Time" width="120">
				<template #default="scope">
					<div>{{DateTimeFilter.date(scope.row.startDateTime, scope.row.parentZoneId)}}</div>
					<div>{{DateTimeFilter.time(scope.row.startDateTime, scope.row.parentZoneId)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Teacher Time" width="120">
				<template #default="scope">
					<div>{{DateTimeFilter.date(scope.row.startDateTime, scope.row.teacherZoneId)}}</div>
					<div>{{DateTimeFilter.time(scope.row.startDateTime, scope.row.teacherZoneId)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Parent">
				<template #default="scope">{{scope.row.parentName}}</template>
			</el-table-column>
			<el-table-column label="Student">
				<template #default="scope">{{scope.row.studentNickname}}</template>
			</el-table-column>      
			<el-table-column label="Teacher">
				<template #default="scope">{{scope.row.teacherFullName}}</template>
			</el-table-column>
			<el-table-column label="Course">
				<template #default="scope">{{scope.row.courseFullName}}</template>
			</el-table-column>
			<el-table-column label="Lesson">
				<template #default="scope">{{scope.row.lessonFullName}}</template>
			</el-table-column>			
		</el-table>
		
		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import arrangementAPI from '../../api/arrangement'
import ItPlaceholder from '../../components/ui/ItPlaceholder.vue'
import { ArrangementFilter } from '../../filters/arrangementFilter'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { Arrangement } from '../../types/arrangement'
import { ArrangementCancelReasonOptions, ArrangementStatusOptions } from '../../types/common/option/arrangementOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { ArrangementFilters, ArrangementFiltersImpl } from '../../types/common/query/filters/arrangementFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { ArrangementSearcherImpl } from '../../types/common/query/searcher/arrangementSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'

const props = defineProps<{
  date?: Date,
  parentId?: string,
	teacherId?: string
}>()

const loading = ref<boolean>(false)

const arrangements = ref<Arrangement[]>()

onMounted(() => {
	doListArrangement()
})

// Query arrangement
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<ArrangementFilters>(new ArrangementFiltersImpl())
const searcher = reactive<Searcher>(new ArrangementSearcherImpl())
const sorts = [new SortImpl('startDateTime', Direction.DESC), new SortImpl('bookedDateTime', Direction.DESC)]

const doListArrangement = function () {
	if (props.date) filters.startDateTime = props.date
	if (props.parentId) filters.parentId = props.parentId
	if (props.teacherId) filters.teacherId = props.teacherId

	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	arrangementAPI.list(query).then((response: AxiosResponse<Result<ListObject<Arrangement>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			arrangements.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListArrangement()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListArrangement()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListArrangement()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListArrangement()
}
</script>