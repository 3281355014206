import storage from "../../storage"
import { Staff } from "../staff"

export interface State {
	staff?: Staff
	accessToken?: string
	refreshToken?: string
	menuCollapse?: boolean
}

export class StateImpl implements State {
	staff = storage.getStaff()
	accessToken = storage.getAccessToken()
	refreshToken = storage.getRefreshToken()
	menuCollapse = storage.isMenuCollapse()
}