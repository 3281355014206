import { Subject } from "../types/serie"
import { toFirstUpperCase } from "../utils/stringUtils"

export class SerieFilter {
  static subject (subject?: Subject): string {
    return toFirstUpperCase(subject?.toString())
  }

  static major (major: boolean | undefined): string {
    if (major == undefined) return ''
    else return major? 'Major' : 'Normal'
  }

  static hidden (hidden?: boolean): string {
    if (hidden == undefined) return ''
    else return hidden? 'Hidden' : 'Visible'
  }
}