import { Arrangement, BookArrangement, CancelArrangement, ConfirmCancelArrangement, CreateArrangementMeeting, DeleteArrangementMeeting, FinishArrangement, RebookArrangement, UnFinishArrangement, UpdateArrangementInfo } from "../types/arrangement"
import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import service from "./api-service"

const api = '/arrangement'

const arrangementAPI = {
  
  list (query: Query) {
    return service.post<Result<ListObject<Arrangement>>>(api + '/list', query)
  },
  
  findById (id: string) {
    return service.get<Result<Arrangement>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  updateInfo (updateArrangementInfo: UpdateArrangementInfo) {
    return service.put<Result<Arrangement>>(api + '/updateInfo', updateArrangementInfo)
  },

  book (bookArrangement: BookArrangement) {
    return service.post<Result<Arrangement>>(api + '/book', bookArrangement)
  },

  rebook (rebookArrangement: RebookArrangement) {
    return service.put<Result<Arrangement>>(api + '/rebook', rebookArrangement)
  },

  cancel (cancelArrangement: CancelArrangement) {
    return service.put<Result<Arrangement>>(api + '/cancel', cancelArrangement)
  },

  confirmCancel (confirmCancelArrangement: ConfirmCancelArrangement) {
    return service.put<Result<Arrangement>>(api + '/confirmCancel', confirmCancelArrangement)
  },

  finish (finishArrangement: FinishArrangement) {
    return service.put<Result<Arrangement>>(api + '/finish', finishArrangement)
  },

  unFinish (unFinishArrangement: UnFinishArrangement) {
    return service.put<Result<Arrangement>>(api + '/unFinish', unFinishArrangement)
  },

  createMeeting (createArrangementMeeting: CreateArrangementMeeting) {
    return service.put<Result<Arrangement>>(api + '/createMeeting', createArrangementMeeting)
  },

  deleteMeeting (deleteArrangementMeeting: DeleteArrangementMeeting) {
    return service.put<Result<Arrangement>>(api + '/deleteMeeting', deleteArrangementMeeting)
  },

  bookThisWeek () {
    return service.post<Result<void>>(api + '/book/thisWeek')
  },

  bookNextWeek () {
    return service.post<Result<void>>(api + '/book/nextWeek')
  },

  bookThisWeekByEnrollmentId (enrollmentId: string) {
    return service.post<Result<void>>(api + '/book/thisWeekByEnrollmentId', null, { 
      params: {
        enrollmentId: enrollmentId
      }
    })
  },

  bookNextWeekByEnrollmentId (enrollmentId: string) {
    return service.post<Result<void>>(api + '/book/nextWeekByEnrollmentId', null, { 
      params: {
        enrollmentId: enrollmentId
      }
    })
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
      params: {
        id: id
      }
    })
  }
}

export default arrangementAPI