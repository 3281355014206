<template>
  <el-dialog v-model="show" title="Arrange Enrollment" @opened="onOpened">
		<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
		
		<el-select v-model="teacherId" filterable style="width: 100%;" placeholder="Teacher">
			<el-option v-for="teacher in teachers" :key="teacher.id" :label="teacher.nickname" :value="teacher.id"></el-option>
		</el-select>

		<time-slots v-if="teacherId" :teacherId="teacherId" :enrollment-id="enrollment.id" style="margin-top: 10px;"></time-slots>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="doCancelArrangeEnrollment">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doFinishArrangeEnrollment">Finish</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { ref } from 'vue'
import teacherAPI from '../../api/teacher'
import { Result } from '../../types/common/result'
import { Enrollment } from '../../types/enrollment'
import { Teacher } from '../../types/teacher'
import { useModelWrapper } from '../../utils/modelWrapper'
import TimeSlots from '../teacher/TimeSlots.vue'

const props = defineProps<{
  visible: boolean,
	enrollment: Enrollment
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

// Find Teachers
const teachers = ref<Teacher[]>()
const teacherId = ref<string>('')
const doFindAllTeacher = function () {
	teacherAPI.findReady().then((response: AxiosResponse<Result<Teacher[]>>) => {
		const result = response.data
		if (result.success) {
			teachers.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const doCancelArrangeEnrollment = function () {
	show.value = false
}

const doFinishArrangeEnrollment = function () {
	emit('success')
	show.value = false
}

const onOpened = function () {
	doFindAllTeacher()
}
</script>