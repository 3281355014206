import { Method, Status } from "../../../refund"
import { Filters } from "./index"

export interface RefundFilters extends Filters {
	parentId?: string
	status?: Status
	method?: Method
}

export class RefundFiltersImpl implements RefundFilters {
	parentId?: string
	status?: Status
	method?: Method
	rechargeCardId?: string

	reset(): void {
		this.parentId = undefined
		this.status = undefined
		this.method = undefined
		this.rechargeCardId = undefined
	}
}