import { Status, SuspendReason } from "../types/enrollment"
import { toFirstUpperCase } from "../utils/stringUtils"

export class EnrollmentFilter {
  static status (status?: Status): string {
    return toFirstUpperCase(status?.toString())
  }

  static suspendReason (suspendReason?: SuspendReason): string {
    return toFirstUpperCase(suspendReason?.toString())
  }

  static lesson (number: number, name: string): string {
    if (name) return number + ' - ' + name
    else return ''
  }
}