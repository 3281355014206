import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { ApproveWithdraw, ManualWithdraw, RejectWithdraw, RetrieveWithdrawTransactionStatus, RetrieveWithdrawTransactionStatusImpl, Withdraw } from "../types/withdraw"
import service from "./api-service"

const api = '/withdraw'

const withdrawAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Withdraw>>>(api + '/list', query)
  },

  manual (manualWithdraw: ManualWithdraw) {
    return service.post<Result<Withdraw>>(api + '/manual', manualWithdraw)
  },

  approve (approveWithdraw: ApproveWithdraw) {
    return service.put<Result<Withdraw>>(api + '/approve', approveWithdraw)
  },

  reject (rejectWithdraw: RejectWithdraw) {
    return service.put<Result<Withdraw>>(api + '/reject', rejectWithdraw)
  },

  retrieveTransactionStatus (retrieveWithdrawTransactionStatus: RetrieveWithdrawTransactionStatus) {
    return service.put<Result<Withdraw>>(api + '/retrieveTransactionStatus', retrieveWithdrawTransactionStatus)
  },

  retrieveAllTransactionStatus () {
    return service.put<Result<Withdraw[]>>(api + '/retrieveAllTransactionStatus')
  }
}

export default withdrawAPI