<template>
  <el-dialog v-model="show" title="Create Staff">
    <el-form ref="createStaffForm" :model="createStaff" label-width="80px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Name" prop="name" required>
				<el-input v-model="createStaff.name"></el-input>
			</el-form-item>
			<el-form-item label="Email" prop="email" :rules="[{required: true, validator: emailValidator, trigger: 'blur'}]">
				<el-input v-model="createStaff.email"></el-input>
			</el-form-item>
			<el-form-item label="Role" prop="role" required>
				<el-radio-group v-model="createStaff.role">
					<el-radio v-for="option in StaffRoleOptions.options" :key="option.label" :label="option.value">{{ option.label }}</el-radio>
				</el-radio-group>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doCreateStaff">Create</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm } from 'element-plus'
import { reactive, ref } from 'vue'
import staffAPI from '../../api/staff'
import { StaffRoleOptions } from '../../types/common/option/staffOptions'
import { Result } from '../../types/common/result'
import { CreateStaff, CreateStaffImpl, Staff } from '../../types/staff'
import { useModelWrapper } from '../../utils/modelWrapper'
import { emailValidator } from '../../validators'

const props = defineProps<{
  visible: boolean
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const createStaff = reactive<CreateStaff>(new CreateStaffImpl())

const createStaffForm = ref<InstanceType<typeof ElForm>>()

const doCreateStaff = function () {
	createStaffForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			staffAPI.create(createStaff).then((response: AxiosResponse<Result<Staff>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					createStaffForm.value?.resetFields()
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				message.value = error.message
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>