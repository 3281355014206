import { Status, TransactionStatus } from "../types/withdraw"
import { toFirstUpperCase } from "../utils/stringUtils"

export class WithdrawFilter {
  static status (status?: Status): string {
    return toFirstUpperCase(status?.toString())
  }

  static transactionStatus (transactionStatus?: TransactionStatus): string {
    return toFirstUpperCase(transactionStatus?.toString())
  }
}