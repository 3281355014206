<template>
  <el-dialog v-model="show" title="Edit Course" @opened="onOpened">
    <el-form ref="updateCourseInfoForm" :model="updateCourseInfo" label-width="150px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Serie" prop="serieId" required>
				<el-select v-model="updateCourseInfo.serieId" filterable>
					<el-option v-for="serie in series" :key="serie.id" :label="serie.name" :value="serie.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Name" prop="name" required>
				<el-input v-model="updateCourseInfo.name"></el-input>
			</el-form-item>
			<el-form-item label="Type" prop="type" required>
				<el-radio-group v-model="updateCourseInfo.type">
					<el-radio v-for="option in CourseTypeOptions.options" :key="option.label" :label="option.value">{{ option.label }}</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="Lesson Duration (m)" prop="lessonDuration" required>
				<el-input-number v-model="updateCourseInfo.lessonDuration" :precision="0" :step="5" :min="20" :max="60" />
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateCourseInfo">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import courseAPI from '../../api/course'
import serieAPI from '../../api/serie'
import { CourseTypeOptions } from '../../types/common/option/courseOptions'
import { Result } from '../../types/common/result'
import { Course, UpdateCourseInfo, UpdateCourseInfoImpl } from '../../types/course'
import { Serie } from '../../types/serie'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	course: Course
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const series = ref<Serie[]>()
const doFindAllSerie = function () {
	serieAPI.findAll().then((response: AxiosResponse<Result<Serie[]>>) => {
		const result = response.data
		if (result.success) {
			series.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const updateCourseInfo = reactive<UpdateCourseInfo>(new UpdateCourseInfoImpl(props.course))

const updateCourseInfoForm = ref<InstanceType<typeof ElForm>>()

const doUpdateCourseInfo = function () {
	updateCourseInfoForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			courseAPI.updateInfo(updateCourseInfo).then((response: AxiosResponse<Result<Course>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const onOpened = function () {
	doFindAllSerie()
}
</script>