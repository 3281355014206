<template>
  <div class="content">
		<slot></slot>

		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item>
					<el-select v-model="filters.type" placeholder="Type">
						<el-option v-for="option in RechargeTypeOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.category" placeholder="Category">
						<el-option v-for="option in RechargeCategoryOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.method" placeholder="Method">
						<el-option v-for="option in RechargeMethodOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.status" placeholder="Status">
						<el-option v-for="option in RechargeStatusOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-button-group v-if="parentId">
				<el-button text type="primary" @click="showStaffCheckoutDialog = true">Create Recharge</el-button>
			</el-button-group>
			<it-placeholder v-else></it-placeholder>
		</div>

		<el-table :data="recharges" v-loading="loading"  border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column v-if="!parentId" label="Parent" prop="parentName" width="100"></el-table-column>
			<el-table-column label="Type" prop="type" width="100">
				<template #default="scope">{{RechargeFilter.type(scope.row.type)}}</template>
			</el-table-column>
			<el-table-column label="Category" prop="category" width="120">
				<template #default="scope">{{RechargeFilter.category(scope.row.category)}}</template>
			</el-table-column>
			<el-table-column label="Method" prop="method" width="100">
				<template #default="scope">{{RechargeFilter.method(scope.row.method)}}</template>
			</el-table-column>
			<el-table-column label="Status" prop="status" width="110">
				<template #default="scope">{{RechargeFilter.status(scope.row.status)}}</template>
			</el-table-column>
			<el-table-column label="Lessons" prop="lessons" width="100" />
			<el-table-column label="Paid" prop="paid" width="150">
				<template #default="scope">
					<div>{{EnumFilter.currency(scope.row.currency)}} {{NumberFilter.money(scope.row.paid)}}</div>
				</template>
			</el-table-column>
			<el-table-column label="Recharge Card" prop="rechargeCardName" width="120" />
			<el-table-column label="Coupon" prop="couponName" width="120" />
			<el-table-column label="Remark" prop="remark" />
			<el-table-column label="Times">
				<template #default="scope">
					<el-button-group>
						<div v-if="scope.row.createdDateTime" class="time">
							<span>Created:</span>
							<span>{{DateTimeFilter.datetime(scope.row.createdDateTime)}}</span>
						</div>
						<div v-if="scope.row.completedDateTime" class="time">
							<span>Completed: </span>
							<span>{{DateTimeFilter.datetime(scope.row.completedDateTime)}}</span>
						</div>	
						<div v-if="scope.row.voidedDateTime" class="time">
							<span>Voided:</span>
							<span>{{DateTimeFilter.datetime(scope.row.voidedDateTime)}}</span>
						</div>
					</el-button-group>
				</template>
			</el-table-column>
			<el-table-column label="Actions" fixed="right" width="100">
				<template #default="scope">
					<el-button-group>
						<el-button text type="primary" @click="doShowUpdateRechargeInfoDialog(scope.row)">Edit</el-button>
						<el-popconfirm title="Are you sure to delete the recharge?" @confirm="doDeleteRecharge(scope.row.id)">
							<template #reference>
								<el-button text type="primary">Delete</el-button>
							</template>
						</el-popconfirm>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />
	
		<staff-checkout-dialog v-if="parentId && showStaffCheckoutDialog" v-model:visible="showStaffCheckoutDialog" :parent-id="parentId" @success="onStaffCheckoutSuccess"></staff-checkout-dialog>

		<update-recharge-info-dialog v-if="showUpdateRechargeInfoDialog" v-model:visible="showUpdateRechargeInfoDialog" :recharge="selectedRecharge" @success="onUpdateRechargeInfoSuccess"></update-recharge-info-dialog>
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import rechargeAPI from '../../api/recharge'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { EnumFilter } from '../../filters/enumFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { RechargeFilter } from '../../filters/rechargeFilter'
import { RechargeCategoryOptions, RechargeMethodOptions, RechargeStatusOptions, RechargeTypeOptions } from '../../types/common/option/rechargeOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { RechargeFilters, RechargeFiltersImpl } from '../../types/common/query/filters/rechargeFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { RechargeSearcherImpl } from '../../types/common/query/searcher/rechargeSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Recharge, RechargeImpl } from '../../types/recharge'
import ItPlaceholder from '../ui/ItPlaceholder.vue'
import StaffCheckoutDialog from './StaffCheckoutDialog.vue'
import UpdateRechargeInfoDialog from './UpdateRechargeInfoDialog.vue'

const props = defineProps<{
	parentId?: string
}>()

const emit = defineEmits<{
  (e: 'deleted'): void
}>()

const loading = ref<boolean>(false)

const recharges = ref<Recharge[]>()

onMounted(() => {
	doListRecharge()
})

// Query recharge
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<RechargeFilters>(new RechargeFiltersImpl())
const searcher = reactive<Searcher>(new RechargeSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const doListRecharge = function () {
	if (props.parentId) filters.parentId = props.parentId

	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	rechargeAPI.list(query).then((response: AxiosResponse<Result<ListObject<Recharge>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			recharges.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListRecharge()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListRecharge()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListRecharge()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListRecharge()
}

// Staff checkout
const showStaffCheckoutDialog = ref<boolean>(false)
const onStaffCheckoutSuccess = function () {
	ElMessage.success('Success to checkout')
	doListRecharge()
}

const selectedRecharge = ref<Recharge>(new RechargeImpl(''))

// Edit recharge info
const showUpdateRechargeInfoDialog = ref<boolean>(false)
const doShowUpdateRechargeInfoDialog = function (recharge: Recharge) {
	selectedRecharge.value = recharge
	showUpdateRechargeInfoDialog.value = true
}
const onUpdateRechargeInfoSuccess = function () {
	ElMessage.success('Success to edit info')
	doListRecharge()
}

// Delete recharge
const doDeleteRecharge = function (id: string) {
	loading.value = true
	rechargeAPI.deleteById(id).then((response: AxiosResponse<Result<void>>) => {
		const result = response.data
		if (result.success) {
			ElMessage.success('Success to delete')
			doListRecharge()
			emit('deleted')
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}
</script>

<style scoped>
.time {
	width: 200px;
	display: flex;
	justify-content: space-between;
}
</style>