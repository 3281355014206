<template>
  <el-container class="info">
    <el-aside v-loading="loading" class="aside">
      <el-image v-if="course.cover" :src="course.cover" fit="contain" style="width: 100%;" @click="showUpdateCourseCoverDialog = true"></el-image>
      
      <it-info-items title="Basic Info">
        <template #actions>
          <el-button text type="primary" class="action-button" @click="showUpdateCourseInfoDialog = true">Edit</el-button>
        </template>
        <it-info-item label="Serie">{{ course.serieName }}</it-info-item>
        <it-info-item label="Name">{{ course.name }}</it-info-item>
        <it-info-item label="Type">{{ CourseFilter.type(course.type) }}</it-info-item>
        <it-info-item label="Lesson Duration">{{ course.lessonDuration }} Min</it-info-item>
        <it-info-item label="Lesson Count">{{ course.lessonCount }}</it-info-item>
      </it-info-items>

      <it-info-items title="Other Info">
        <it-info-item label="Readiness">
          <div>{{ CourseFilter.ready(course.ready) }}</div>
          <el-popconfirm :title="course.ready? 'Are you sure to set the course as preparing?' : 'Are you sure to set the course as ready?'" @confirm="doToggleCourseReady(course.id)">
            <template #reference>
              <el-button text type="primary" class="action-button">{{ course.ready? 'Set as Preparing' : 'Set as Ready' }}</el-button>
            </template>
          </el-popconfirm>
        </it-info-item>
        <it-info-item label="Visibility">
          <div>{{ CourseFilter.hidden(course.hidden) }}</div>
          <el-popconfirm :title="course.hidden? 'Are you sure to set the course as visible?' : 'Are you sure to set the course as hidden?'" @confirm="doToggleCourseHidden(course.id)">
            <template #reference>
              <el-button text type="primary" class="action-button">{{ course.hidden? 'Set as Visible' : 'Set as Hidden' }}</el-button>
            </template>
          </el-popconfirm>
        </it-info-item>
      </it-info-items>

      <it-info-items title="Introduction">
        <template #actions>
          <el-button text type="primary" class="action-button" @click="showUpdateCourseIntroductionDialog = true">Edit</el-button>
        </template>
        <it-info-item>
          <div style="font-size: 0.9em;" v-html="course.introduction"></div>
        </it-info-item>
      </it-info-items>
    </el-aside>
    <el-main class="main">
      <el-tabs v-model="tab">
        <el-tab-pane label="Lessons" name="lessons" lazy>
          <lessons :courseId="courseId"></lessons>
        </el-tab-pane>
        <el-tab-pane label="Modules" name="modules" lazy>
          <modules :courseId="courseId"></modules>
        </el-tab-pane>
        <el-tab-pane label="Introductions" name="introductions" lazy>
          <course-locale-introductions :courseId="courseId"></course-locale-introductions>
        </el-tab-pane>
        <el-tab-pane label="Guide" name="guide" lazy>
          <course-guide :course="course" @updated="onCourseGuideUpdated"></course-guide>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>

  <update-course-cover-dialog v-if="showUpdateCourseCoverDialog" v-model:visible="showUpdateCourseCoverDialog" :course="course" @success="onUpdateCourseCoverSuccess"></update-course-cover-dialog>

  <update-course-info-dialog v-if="showUpdateCourseInfoDialog" v-model:visible="showUpdateCourseInfoDialog" :course="course" @success="onUpdateCourseInfoSuccess"></update-course-info-dialog>

  <update-course-introduction-dialog v-if="showUpdateCourseIntroductionDialog" v-model:visible="showUpdateCourseIntroductionDialog" :course="course" @success="onUpdateCourseIntroductionSuccess"></update-course-introduction-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import courseAPI from '../../api/course'
import CourseGuide from '../../components/course/CourseGuide.vue'
import CourseLocaleIntroductions from '../../components/course/CourseLocaleIntroductions.vue'
import UpdateCourseCoverDialog from '../../components/course/UpdateCourseCoverDialog.vue'
import UpdateCourseInfoDialog from '../../components/course/UpdateCourseInfoDialog.vue'
import UpdateCourseIntroductionDialog from '../../components/course/UpdateCourseLocaleIntroductionDialog.vue'
import Lessons from '../../components/lesson/Lessons.vue'
import Modules from '../../components/module/Modules.vue'
import ItInfoItem from '../../components/ui/ItInfoItem.vue'
import ItInfoItems from '../../components/ui/ItInfoItems.vue'
import { CourseFilter } from '../../filters/courseFilter'
import { Result } from '../../types/common/result'
import { Course, CourseImpl, ToggleCourseHidden, ToggleCourseHiddenImpl, ToggleCourseReady, ToggleCourseReadyImpl } from '../../types/course'
import { cloneFrom } from '../../utils/clone'

const route = useRoute()
const courseId = route.params.id as string

const loading = ref<boolean>(false)

const tab = ref<string>('lessons')

const course = reactive<Course>(new CourseImpl(courseId))
const doFindCourse = function() {
  loading.value = true
  courseAPI.findById(courseId).then((response: AxiosResponse<Result<Course>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(course, result.data)
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

const onOpen = function () {
  doFindCourse()
}

// Edit course cover
const showUpdateCourseCoverDialog = ref<boolean>(false)
const onUpdateCourseCoverSuccess = function () {
  ElMessage.success('Success to edit')
  doFindCourse()
}

// Edit course info
const showUpdateCourseInfoDialog = ref<boolean>(false)
const onUpdateCourseInfoSuccess = function () {
  ElMessage.success('Success to edit')
  doFindCourse()
}

// Edit course introduction
const showUpdateCourseIntroductionDialog = ref<boolean>(false)
const onUpdateCourseIntroductionSuccess = function () {
  ElMessage.success('Success to edit')
  doFindCourse()
}

// Set course as Preparing/Ready
const doToggleCourseReady = function (id: string) {
  const toggleCourseReady: ToggleCourseReady = new ToggleCourseReadyImpl(id)
  loading.value = true
  courseAPI.toggleReady(toggleCourseReady).then((response: AxiosResponse<Result<Course>>) => {
    const result = response.data
    if (result.success) {
      const course = result.data
      ElMessage.success(course.ready? 'Success to set as ready' : 'Success to set as preparing')
      doFindCourse()
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Set course as Hidden/Visible
const doToggleCourseHidden = function (id: string) {
  const toggleCourseHidden: ToggleCourseHidden = new ToggleCourseHiddenImpl(id)
  loading.value = true
  courseAPI.toggleHidden(toggleCourseHidden).then((response: AxiosResponse<Result<Course>>) => {
    const result = response.data
    if (result.success) {
      const course = result.data
      ElMessage.success(course.hidden? 'Success to set course as hidden' : 'Success to set course as visible')
      doFindCourse()
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Handle course guide updated
const onCourseGuideUpdated = function () {
  doFindCourse()
}

onMounted(() => {
  doFindCourse()
})
</script>