import { Gender } from "../../../enums"
import { Status } from "../../../student"
import { Filters } from "./index"

export interface StudentFilters extends Filters {
	parentId?: string
	status?: Status
	gender?: Gender
}

export class StudentFiltersImpl implements StudentFilters {
	parentId?: string
	status?: Status
	gender?: Gender

	constructor(parentId?: string) {
		this.parentId = parentId
	}

	reset(): void {
		this.status = undefined
		this.gender = undefined
	}
}