<template>
  <el-dialog v-model="show" title="Update Arrangement Info" @opened="onOpened">
    <el-form ref="updateArrangementInfoForm" :model="updateArrangementInfo" label-width="100px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Parent">
				<span style="width: 300px;">{{ arrangement?.parentNickname }}</span>
				<span style="margin-left: 10px;">Time Zone: {{arrangement?.parentZoneId}}</span>
			</el-form-item>
			<el-form-item label="Student">
				<span style="width: 300px;">{{ arrangement?.studentNickname }}</span>
			</el-form-item>
			<el-form-item label="Teacher">
				<span style="width: 300px;">{{ arrangement?.teacherNickname }}</span>
			</el-form-item>
			<el-form-item label="Start At">
				<span style="width: 300px;">
					{{DateTimeFilter.date(arrangement.startDateTime, arrangement.parentZoneId)}}
					{{DateTimeFilter.time(arrangement.startDateTime, arrangement.parentZoneId)}}
				</span>
			</el-form-item>
			<el-form-item label="Enrollment">
				<span style="width: 300px;">{{ arrangement?.courseFullName }}</span>
			</el-form-item>
			<el-form-item label="Lesson" prop="lessonId" required>
				<el-select v-model="updateArrangementInfo.lessonId" filterable style="width: 300px;">
					<el-option v-for="lesson in lessons" :key="lesson.id" :label="lesson.fullName" :value="lesson.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Trial" required>
				<el-switch v-model="updateArrangementInfo.trial" />
				<span style="margin-left: 10px;">Is trial class for student</span>
			</el-form-item>
			<el-form-item label="Demo" required>
				<el-switch v-model="updateArrangementInfo.demo" />
				<span style="margin-left: 10px;">Is demo class for teacher</span>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateArrangementInfo">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import arrangementAPI from '../../api/arrangement'
import lessonAPI from '../../api/lesson'
import { Arrangement, UpdateArrangementInfo, UpdateArrangementInfoImpl } from '../../types/arrangement'
import { Result } from '../../types/common/result'
import { Lesson } from '../../types/lesson'
import { useModelWrapper } from '../../utils/modelWrapper'
import { DateTimeFilter } from '../../filters/dateTimeFilter'

const props = defineProps<{
  visible: boolean,
	arrangement: Arrangement
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

// Find lessons
const lessons = ref<Lesson[]>()
const doFindLessons = function (courseId: string) {
	lessonAPI.findByCourseId(courseId).then((response: AxiosResponse<Result<Lesson[]>>) => {
		const result = response.data
		if (result.success) {
			lessons.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	})
}

const updateArrangementInfo = reactive<UpdateArrangementInfo>(new UpdateArrangementInfoImpl(props.arrangement))

const updateArrangementInfoForm = ref<InstanceType<typeof ElForm>>()

const doUpdateArrangementInfo = function () {
	updateArrangementInfoForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			arrangementAPI.updateInfo(updateArrangementInfo).then((response: AxiosResponse<Result<Arrangement>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				message.value = error.message
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const onOpened = function () {
	if(props.arrangement.courseId) doFindLessons(props.arrangement.courseId)
}
</script>