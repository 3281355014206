import { NanoParent, Type } from "../types/parent"

export class ParentFilter {
    static type (type?: Type): string {
        switch (type) {
            case Type.NORMAL:
                return 'Normal'
            case Type.KOC:
                return 'KOC'
            case Type.AGENT:
                return 'Agent'
            default:
                return 'Undefined' 
        }
    }

    static locked (locked: boolean | undefined): string {
        if (locked == undefined) return ''
        else return locked? 'Locked' : 'Normal'
    }

    static completed (completed: boolean | undefined): string {
        if (completed == undefined) return ''
        else return completed? 'Completed' : 'Uncompleted'
    }

    static fullName(parent: NanoParent): string {
        return parent.name + ' - ' + parent.nickname
    }
}