import { Status } from "../types/recruitment"

export class RecruitmentFilter {
    static status (status?: Status): string {
        switch (status) {
            case Status.SIGNUP:
                return 'Signuped'
            case Status.RECHARGE:
                return 'Recharged'
            default:
                return 'Undefined' 
        }
    }
}