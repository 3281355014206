import { Staff } from "./staff"

export interface Login {
	grant_type: string
	username: string
	password: string
}

export class LoginImpl implements Login {
	grant_type: string = 'password'
	username: string = ''
	password: string = ''
}

export interface Logined {
	readonly access_token: string
	readonly refresh_token: string
	staff: Staff
}

export interface RefreshToken {
	grant_type: string
	refresh_token: string
}

export class RefreshTokenImpl implements RefreshToken {
	grant_type: string = 'refresh_token'
	refresh_token: string

	constructor(refreshToken: string) {
		this.refresh_token = refreshToken
	}
}