<template>
  <el-container style="min-height: 100vh;">
    <el-aside class="aside-menu d-flex flex-column justify-content-between" :style="{width: (isMenuCollapse ? '65px' : '200px')}">
      <div>
        <!-- logo -->
        <div style="line-height: 60px; color: white; text-align: center;">
          <span v-if="isMenuCollapse">{{brandForShort}}</span>
          <span v-else>{{brand}}</span>
        </div>
        <!-- menu -->
        <el-menu style="border-right: none;" router :collapse="isMenuCollapse" default-active="parents" :default-openeds="defaultOpenedSubMenus">
          <el-sub-menu v-if="permit('market')" index="market">
            <template #title>
              <el-icon><data-line /></el-icon>
              <span>Marketing</span>
            </template>
            <el-menu-item v-if="permit('parents')" index="parents" :route="{name: 'parents'}">Parents</el-menu-item>
            <el-menu-item v-if="permit('agents')" index="agents" :route="{name: 'agents'}">Agents</el-menu-item>
            <el-menu-item v-if="permit('rechargeCards')" index="rechargeCards" :route="{name: 'rechargeCards'}">Recharge Cards</el-menu-item>
            <el-menu-item v-if="permit('coupons')" index="coupons" :route="{name: 'coupons'}">Coupons</el-menu-item>
          </el-sub-menu>
          <el-sub-menu v-if="permit('service')" index="service">
            <template #title>
              <el-icon><tickets /></el-icon>
              <span>Service</span>
            </template>
            <el-menu-item v-if="permit('series')" index="series" :route="{name: 'series'}">Series</el-menu-item>
            <el-menu-item v-if="permit('courses')" index="courses" :route="{name: 'courses'}">Courses</el-menu-item>
            <el-menu-item v-if="permit('teachers')" index="teachers" :route="{name: 'teachers'}">Teachers</el-menu-item>
            <!-- TODO 待实现 -->
            <!-- <el-menu-item v-if="permit('vacations')" index="vacations" :route="{name: 'vacations'}">Vacations</el-menu-item> -->
          </el-sub-menu>
          <el-sub-menu v-if="permit('operation')" index="operation">
            <template #title>
              <el-icon><notebook /></el-icon>
              <span>Operation</span>
            </template>
            <el-menu-item v-if="permit('enrollments')" index="enrollments" :route="{name: 'enrollments'}">Enrollments</el-menu-item>
            <el-menu-item v-if="permit('arrangements')" index="arrangements" :route="{name: 'arrangements'}">Arrangements</el-menu-item>
            <!-- TODO 待实现 -->
            <!-- <el-menu-item v-if="permit('monitor')" index="monitor" :route="{name: 'monitor'}">Monitor</el-menu-item> -->
          </el-sub-menu>
          <el-sub-menu v-if="permit('finance')" index="finance">
            <template #title>
              <el-icon><money /></el-icon>
              <span>Finance</span>
            </template>
            <el-menu-item v-if="permit('recharges')" index="recharges" :route="{name: 'recharges'}">Recharges</el-menu-item>
            <el-menu-item v-if="permit('refunds')" index="refunds" :route="{name: 'refunds'}">Refunds</el-menu-item>
            <el-menu-item v-if="permit('withdraws')" index="withdraws" :route="{name: 'withdraws'}">Withdraws</el-menu-item>
          </el-sub-menu>
          <!-- TODO 待实现 -->
          <!-- <el-sub-menu v-if="permit('statistic')" index="statistic">
            <template #title>
              <data-analysis class="icon" />
              <span>Statistic</span>
            </template>
            <el-menu-item index="arrangementStatistic" :route="{name: 'arrangementStatistic'}">Arrangements</el-menu-item>
          </el-sub-menu> -->
          <el-sub-menu v-if="permit('settings')" index="settings">
            <template #title>
              <el-icon><setting /></el-icon>
              <span>Settings</span>
            </template>
            <el-menu-item v-if="permit('staffs')" index="staffs" :route="{name: 'staffs'}">Staffs</el-menu-item>
            <el-menu-item v-if="permit('studentAvatars')" index="studentAvatars" :route="{name: 'studentAvatars'}">Student Avatars</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </div>
      <div v-if="version" style="width: 100%; text-align: center; font-size: 10px; color: gainsboro; margin-bottom: 20px;">
        - {{version}} -
      </div>
    </el-aside>

    <el-container>
      <el-header style="background-color: white; height: 60px; display: flex; justify-content: space-between;">
        <div style="line-height: 60px; display: flex;">
          <el-button text type="primary" @click="doToggleMenuCollapse" style="margin: 20px 20px 10px 0; height: 20px; padding: 0;">
            <el-icon size="20" v-if="isMenuCollapse"><expand /></el-icon>
            <el-icon size="20" v-else><fold /></el-icon>
          </el-button>
          <div>
            <el-page-header v-if="route.meta.back" :icon="ArrowLeft" :content="route.meta.title as string" @back="router.go(-1)" style="margin: 18px 0px; align-items: center;" />
            <div v-else>{{ route.meta.title }}</div>
          </div>
        </div>

        <div>
          <el-dropdown trigger="click" @command="doHandleCommand" style="padding: 23px 0; margin-left: 10px;">
             <span class="el-dropdown-link">
               {{staff.name}} | {{StaffFilter.role(staff.role)}}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <div style="display: flex; align-items: center; flex-direction: column;">
                  <!-- <el-dropdown-item command="doGotoProfile">Profile</el-dropdown-item> -->
                  <el-dropdown-item command="doLogout">Logout</el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>

      <el-main style="margin: 10px; padding: 0;">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script setup lang="ts">
import { ArrowLeft, DataLine, Expand, Fold, Money, Notebook, Setting, Tickets } from '@element-plus/icons-vue'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import config from '../../package.json'
import { StaffFilter } from '../filters/staffFilter'
import { Actions, useStore } from '../store'
import { Role } from '../types/staff'

const brand = import.meta.env.VITE_APP_BRAND
const brandForShort = import.meta.env.VITE_APP_BRAND_FOR_SHORT
const version = computed(() => config.version)

const isMenuCollapse = computed(() => store.state.menuCollapse)
const defaultOpenedSubMenus = ['market', 'service', 'operation', 'finance', 'settings']

const router = useRouter()
const route = useRoute()

const store = useStore()
const staff = store.state.staff!

const doToggleMenuCollapse = function () {
    store.dispatch(Actions.TOGGLE_MENU_COLLAPSE)
}

const doHandleCommand = function (command: string) {
  if (command === 'doGotoProfile') {
    router.push({
      name: 'profile'
    })
  } else if (command === 'doLogout') {
    store.dispatch('logout').then(() => {
      router.push({
        name: 'login'
      })
    })
  }
}

const permit = function (menuItem: string): boolean {
  const role = staff.role

  switch(menuItem) {
    case 'market': return role === Role.ADMIN || role === Role.CXO || role === Role.CS
    case 'parents': return role === Role.ADMIN || role === Role.CXO || role === Role.CS
    case 'agents': return role === Role.ADMIN || role === Role.CXO
    case 'rechargeCards': return role === Role.ADMIN || role === Role.CXO
    case 'coupons': return role === Role.ADMIN || role === Role.CXO

    case 'service': return role === Role.ADMIN || role === Role.CXO
    case 'series': return role === Role.ADMIN || role === Role.CXO
    case 'courses': return role === Role.ADMIN || role === Role.CXO
    case 'teachers': return role === Role.ADMIN || role === Role.CXO

    case 'operation': return role === Role.ADMIN || role === Role.CXO || role === Role.CS
    case 'enrollments': return role === Role.ADMIN || role === Role.CXO || role === Role.CS
    case 'arrangements': return role === Role.ADMIN || role === Role.CXO || role === Role.CS

    case 'finance': return role === Role.ADMIN || role === Role.CXO || role === Role.CS
    case 'recharges': return role === Role.ADMIN || role === Role.CXO || role === Role.CS
    case 'refunds': return role === Role.ADMIN || role === Role.CXO || role === Role.CS
    case 'withdraws': return role === Role.ADMIN || role === Role.CXO

    case 'settings': return role === Role.ADMIN
    case 'staffs': return role === Role.ADMIN
    case 'studentAvatars': return role === Role.ADMIN

    default: return false
  }
}
</script>

<style>
.actionbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.query {
  display: flex;
  justify-content: space-between;
}

.pagination {
  margin-top: 10px;
}

.el-divider--horizontal {
  margin: 10px 0;
}

.el-form-item--small.el-form-item {
  margin-bottom: 0;
}

.el-drawer__body {
  overflow: auto;
}

.el-drawer__container ::-webkit-scrollbar {
  display: none;
}

.el-page-header__content {
  font-size: 1em !important;
}
</style>
