<template>
  <el-container class="info">
    <el-aside v-loading="loading" class="aside">
      <el-avatar shape="square" :size="80" fit="fill" :src="teacher.avatar" style="margin-bottom: 10px;"></el-avatar>
      <it-info-items title="Basic Info">
        <template #actions>
          <el-button text type="primary" class="action-button" @click="showUpdateTeacherDetailInfoDialog = true">Edit</el-button>
        </template>
        <it-info-item label="Name">{{ TeacherFilter.fullName(teacher.firstName, teacher.lastName) }}</it-info-item>
        <it-info-item label="Nickname">{{ teacher.nickname }}</it-info-item>
        <it-info-item label="Email">{{ teacher.email }}</it-info-item>
        <it-info-item label="Mobile">{{ teacher.fullMobile }}</it-info-item>
        <it-info-item label="Country">{{ TeacherFilter.country(teacher.country) }}</it-info-item>
        <it-info-item label="Display Country">{{ EnumFilter.country(teacher.displayCountry) }}</it-info-item>
        <it-info-item label="Accent">{{ EnumFilter.accent(teacher.accent) }}</it-info-item>
        <it-info-item label="Time Zone">{{ teacher.zoneId }}</it-info-item>
      </it-info-items>
      <it-info-items title="Teaching Info">
        <it-info-item label="Level">{{ TeacherFilter.level(teacher.level) }}</it-info-item>
        <it-info-item label="Teaching Minutes">{{ teacher.totalTeachingMinutes }}</it-info-item>
        <it-info-item label="Rank">{{ NumberFilter.rank(teacher.rank) }}</it-info-item>
      </it-info-items>
      <it-info-items title="Salary Info">
        <it-info-item label="Fee">
          <div>{{ NumberFilter.money(teacher.fee) }}</div>
          <el-button text type="primary" class="action-button" :disabled="teacher.support" @click="showUpdateTeacherFeeDialog = true">Change</el-button>
        </it-info-item>
        <it-info-item label="Class Fee">{{ NumberFilter.money(teacher.classFee) }}</it-info-item>
        <it-info-item label="Wage">{{ NumberFilter.money(teacher.wage) }}</it-info-item>
        <it-info-item label="Class Wage">{{ NumberFilter.money(teacher.classWage) }}</it-info-item>
        <it-info-item label="Base">
          <div>{{ NumberFilter.money(teacher.base) }}</div>
          <el-button text type="primary" class="action-button" :disabled="teacher.support" @click="showUpdateTeacherWageBaseDialog = true">Change</el-button>
        </it-info-item>
        <it-info-item label="Addition">{{ NumberFilter.money(teacher.addition) }}</it-info-item>
      </it-info-items>
      <it-info-items title="Finance Info">
        <it-info-item label="Balance">{{ NumberFilter.money(teacher.balance) }}</it-info-item>
        <it-info-item label="Earning">{{ NumberFilter.money(teacher.totalEarning) }}</it-info-item>
        <it-info-item label="Withdraw">{{ NumberFilter.money(teacher.totalWithdraw) }}</it-info-item>
        <it-info-item label="Paypal Email">{{ teacher.latestPayPalEmail }}</it-info-item>
      </it-info-items>
      <it-info-items title="Other Info">
        <it-info-item label="Certified">
          <div>{{ TeacherFilter.certified(teacher.certified) }}</div>
          <el-popconfirm :title="teacher.certified? 'Are you sure to uncertify the teacher for teaching?' : 'Are you sure to certify the teacher for teaching?'" @confirm="doToggleTeacherCertified(teacher.id)">
            <template #reference>
              <el-button text type="primary" class="action-button">{{ teacher.certified? 'Uncertify' : 'Certify' }}</el-button>
            </template>
          </el-popconfirm>
        </it-info-item>
        <it-info-item label="Identity">
          <div>{{ TeacherFilter.vendor(teacher.vendor) }}</div>
          <el-popconfirm :title="teacher.vendor? 'Are you sure to set the teacher as common?' : 'Are you sure to set the teacher as vendor?'" @confirm="doToggleTeacherVendor(teacher.id)">
            <template #reference>
              <el-button text type="primary" class="action-button">{{ teacher.vendor? 'Set as Common' : 'Set as Vendor' }}</el-button>
            </template>
          </el-popconfirm>
        </it-info-item>
        <it-info-item label="Readiness">
          <div>{{ TeacherFilter.ready(teacher.ready) }}</div>
          <el-popconfirm :title="teacher.ready? 'Are you sure to set the teacher as preparing?' : 'Are you sure to set the teacher as ready?'" @confirm="doToggleTeacherReady(teacher.id)">
            <template #reference>
              <el-button text type="primary" class="action-button">{{ teacher.ready? 'Set as Preparing' : 'Set as Ready' }}</el-button>
            </template>
          </el-popconfirm>
        </it-info-item>
        <it-info-item label="Visibility">
          <div>{{ TeacherFilter.hidden(teacher.hidden) }}</div>
          <el-popconfirm :title="teacher.hidden? 'Are you sure to set the teacher as visible?' : 'Are you sure to set the teacher as hidden?'" @confirm="doToggleTeacherHidden(teacher.id)">
            <template #reference>
              <el-button text type="primary" class="action-button">{{ teacher.hidden? 'Set as Visible' : 'Set as Hidden' }}</el-button>
            </template>
          </el-popconfirm>
        </it-info-item>
        <it-info-item label="Status">
          <div>{{ TeacherFilter.locked(teacher.locked) }}</div>
          <el-popconfirm :title="teacher.locked? 'Are you sure to unlock the teacher?' : 'Are you sure to lock the teacher?'" @confirm="doToggleTeacherLocked(teacher.id)">
            <template #reference>
              <el-button text type="primary" class="action-button">{{ teacher.locked? 'Unlock' : 'Lock' }}</el-button>
            </template>
          </el-popconfirm>
        </it-info-item>
        <it-info-item label="Zoom Account">
          <div>{{ teacher.zoomAccount }}</div>
          <el-button text type="primary" class="action-button" @click="showUpdateTeacherZoomAccountDialog = true">Change</el-button>
        </it-info-item>
        <it-info-item label="Zoom Password">{{ teacher.zoomPassword }}</it-info-item>
        <it-info-item label="Promote Code">{{ teacher.promoteCode }}</it-info-item>
        <it-info-item label="Referrer">{{ teacher.referrerName }}</it-info-item>
        <it-info-item label="Created">{{ DateTimeFilter.datetime(teacher.createdDateTime) }}</it-info-item>
      </it-info-items>
      <it-info-items title="Introduction">
        <it-info-item>{{ teacher.introduction }}</it-info-item>
      </it-info-items>
    </el-aside>
    <el-main class="main">
      <el-tabs v-model="tab">
        <el-tab-pane label="Certificates" name="certificates" lazy>
          <div v-if="teacher.certificates && teacher.certificates.length > 0">
            <div v-for="(certificate, index) in teacher.certificates" :key="index">
              <el-image :src="certificate.photo" style="width: 50%; margin-bottom: 10px;"></el-image>
            </div>
          </div>
          <div v-else>No Certificates</div> 
        </el-tab-pane>
        <el-tab-pane label="Show" name="show" lazy>
          <video :src="teacher.show" controls preload="auto" style="width: 50%;"></video>
        </el-tab-pane>
        <el-tab-pane label="Time Slots" name="timeSlots" lazy>
          <time-slots :teacherId="teacherId"></time-slots>
        </el-tab-pane>
        <el-tab-pane label="Arrangements" name="arrangements" lazy>
          <arrangements :teacherId="teacherId"></arrangements>
        </el-tab-pane>
        <el-tab-pane label="Earnings" name="earnings" lazy>
          <earnings :teacherId="teacherId"></earnings>
        </el-tab-pane>
        <el-tab-pane label="Withdraws" name="withdraws" lazy>
          <withdraws :teacher="teacher"></withdraws>
        </el-tab-pane>
        <el-tab-pane label="Vacations" name="vacations" lazy>
          <vacations :teacher="teacher"></vacations>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>

  <update-teacher-detail-info-dialog v-if="showUpdateTeacherDetailInfoDialog" v-model:visible="showUpdateTeacherDetailInfoDialog" :teacher="teacher" @success="onUpdateTeacherDetailInfoSuccess"></update-teacher-detail-info-dialog>

  <update-teacher-zoom-account-dialog v-if="showUpdateTeacherZoomAccountDialog" v-model:visible="showUpdateTeacherZoomAccountDialog" :teacher="teacher" @success="onUpdateTeacherZoomAccountSuccess"></update-teacher-zoom-account-dialog>

  <update-teacher-fee-dialog v-if="showUpdateTeacherFeeDialog" v-model:visible="showUpdateTeacherFeeDialog" :teacher="teacher" @success="onUpdateTeacherFeeSuccess"></update-teacher-fee-dialog>

  <update-teacher-wage-base-dialog v-if="showUpdateTeacherWageBaseDialog" v-model:visible="showUpdateTeacherWageBaseDialog" :teacher="teacher" @success="onUpdateTeacherWageBaseSuccess"></update-teacher-wage-base-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import teacherAPI from '../../api/teacher'
import Earnings from '../../components/earning/Earnings.vue'
import Arrangements from '../../components/schedule/Arrangements.vue'
import TimeSlots from '../../components/teacher/TimeSlots.vue'
import UpdateTeacherDetailInfoDialog from '../../components/teacher/UpdateTeacherDetailInfoDialog.vue'
import UpdateTeacherFeeDialog from '../../components/teacher/UpdateTeacherFeeDialog.vue'
import UpdateTeacherWageBaseDialog from '../../components/teacher/UpdateTeacherWageBaseDialog.vue'
import UpdateTeacherZoomAccountDialog from '../../components/teacher/UpdateTeacherZoomAccountDialog.vue'
import ItInfoItem from '../../components/ui/ItInfoItem.vue'
import ItInfoItems from '../../components/ui/ItInfoItems.vue'
import Vacations from '../../components/vacation/Vacations.vue'
import Withdraws from '../../components/withdraw/Withdraws.vue'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { EnumFilter } from '../../filters/enumFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { TeacherFilter } from '../../filters/teacherFilter'
import { Result } from '../../types/common/result'
import { Teacher, TeacherImpl, ToggleTeacherCertified, ToggleTeacherCertifiedImpl, ToggleTeacherHidden, ToggleTeacherHiddenImpl, ToggleTeacherLocked, ToggleTeacherLockedImpl, ToggleTeacherReady, ToggleTeacherReadyImpl, ToggleTeacherVendor, ToggleTeacherVendorImpl } from '../../types/teacher'
import { cloneFrom } from '../../utils/clone'

const route = useRoute()
const teacherId = route.params.id as string

const loading = ref<boolean>(false)

const tab = ref<string>('certificates')

const teacher = reactive<Teacher>(new TeacherImpl(teacherId))
const doFindTeacher = function() {
  loading.value = true
  teacherAPI.findById(teacherId).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(teacher, result.data)
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Approve/Disapprove teacher
const doToggleTeacherCertified = function (id: string) {
  const toggleTeacherCertified: ToggleTeacherCertified = new ToggleTeacherCertifiedImpl(id)
  loading.value = true
  teacherAPI.toggleCertified(toggleTeacherCertified).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      const teacher = result.data
      ElMessage.success(teacher.certified? 'Success to certified' : 'Success to uncertified')
      doFindTeacher()
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Set teacher as Vendor/Common
const doToggleTeacherVendor = function (id: string) {
  const toggleTeacherVendor: ToggleTeacherVendor = new ToggleTeacherVendorImpl(id)
  loading.value = true
  teacherAPI.toggleVendor(toggleTeacherVendor).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      const teacher = result.data
      ElMessage.success(teacher.vendor? 'Success to set teacher as vendor' : 'Success to set teacher as common')
      doFindTeacher()
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Set teacher as Ready/Preparing
const doToggleTeacherReady = function (id: string) {
  const toggleTeacherReady: ToggleTeacherReady = new ToggleTeacherReadyImpl(id)
  loading.value = true
  teacherAPI.toggleReady(toggleTeacherReady).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      const teacher = result.data
      ElMessage.success(teacher.vendor? 'Success to set teacher as ready' : 'Success to set teacher as preparing')
      doFindTeacher()
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Set teacher as Hidden/Visible
const doToggleTeacherHidden = function (id: string) {
  const toggleTeacherHidden: ToggleTeacherHidden = new ToggleTeacherHiddenImpl(id)
  loading.value = true
  teacherAPI.toggleHidden(toggleTeacherHidden).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      const teacher = result.data
      ElMessage.success(teacher.hidden? 'Success to set teacher as hidden' : 'Success to set teacher as visible')
      doFindTeacher()
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Lock/Unlock teacher
const doToggleTeacherLocked = function (id: string) {
  const toggleTeacherLocked: ToggleTeacherLocked = new ToggleTeacherLockedImpl(id)
  loading.value = true
  teacherAPI.toggleLocked(toggleTeacherLocked).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      const teacher = result.data
      ElMessage.success(teacher.locked? 'Success to lock' : 'Success to unlock')
      doFindTeacher()
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Edit teacher detail info
const showUpdateTeacherDetailInfoDialog = ref<boolean>(false)
const onUpdateTeacherDetailInfoSuccess = function () {
  ElMessage.success('Success to edit')
  doFindTeacher()
}

// Edit teacher zoom account
const showUpdateTeacherZoomAccountDialog = ref<boolean>(false)
const onUpdateTeacherZoomAccountSuccess = function () {
  ElMessage.success('Success to edit')
  doFindTeacher()
}

// Edit teacher wage base
const showUpdateTeacherWageBaseDialog = ref<boolean>(false)
const onUpdateTeacherWageBaseSuccess = function () {
  ElMessage.success('Success to edit')
  doFindTeacher()
}

// Edit teacher fee
const showUpdateTeacherFeeDialog = ref<boolean>(false)
const onUpdateTeacherFeeSuccess = function () {
  ElMessage.success('Success to edit')
  doFindTeacher()
}


onMounted(() => {
  doFindTeacher()
})
</script>