import { Filters } from "./index"
import { Subject } from "../../../serie"

export interface SerieFilters extends Filters {
	major?: boolean
	subject?: Subject
}

export class SerieFiltersImpl implements SerieFilters {
	major?: boolean
	subject?: Subject

	reset(): void {
		this.major = undefined
		this.subject = undefined
	}
}