import { Option, OptionImpl } from "."
import { toFirstUpperCase } from "../../../utils/stringUtils"
import { Accent, Country, Currency, Gender } from "../../enums"

export class GenderOptions {
	static options: Option[] = [
		new OptionImpl('Boy', Gender.BOY),
		new OptionImpl('Girl', Gender.GIRL),
		new OptionImpl('Unknown', Gender.UNKNOWN)
	]
}

export class CountryOptions {
	static options: Option[] = [
		new OptionImpl(Country.US.toUpperCase(), Country.US),
		new OptionImpl(Country.UK.toUpperCase(), Country.UK),
		new OptionImpl(Country.CANADA.toUpperCase(), Country.CANADA)
	]
}

export class AccentOptions {
	static options: Option[] = [
		new OptionImpl(toFirstUpperCase(Accent.AMERICAN), Accent.AMERICAN),
		new OptionImpl(toFirstUpperCase(Accent.BRITISH), Accent.BRITISH)
	]
}

export class CurrencyOptions {
	static options: Option[] = [
		new OptionImpl('USD', Currency.USD),
		new OptionImpl('CNY', Currency.CNY)
	]
}