<template>
  <el-dialog v-model="show" title="Update Enrollment Suspend Reason">
    <el-form ref="updateEnrollmentSuspendReasonForm" :model="updateEnrollmentSuspendReason">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Suspend Reason" prop="suspendReason" required>
				<el-select v-model="updateEnrollmentSuspendReason.suspendReason" filterable style="width: 100%">
					<el-option v-for="option in EnrollmentSuspendReasonOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
				</el-select>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doUpdateEnrollmentSuspendReason">Update</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import enrollmentAPI from '../../api/enrollment'
import { EnrollmentSuspendReasonOptions } from '../../types/common/option/enrollmentOptions'
import { Result } from '../../types/common/result'
import { Enrollment, UpdateEnrollmentSuspendReason, UpdateEnrollmentSuspendReasonImpl } from '../../types/enrollment'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	enrollment: Enrollment
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const updateEnrollmentSuspendReason = reactive<UpdateEnrollmentSuspendReason>(new UpdateEnrollmentSuspendReasonImpl(props.enrollment))
const updateEnrollmentSuspendReasonForm = ref<InstanceType<typeof ElForm>>()

const doUpdateEnrollmentSuspendReason = function () {
	updateEnrollmentSuspendReasonForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			enrollmentAPI.updateSuspendReason(updateEnrollmentSuspendReason).then((response: AxiosResponse<Result<Enrollment>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}
</script>