import { Currency } from "./enums"

export enum Type {
	USER = 'USER',
	SYSTEM = 'SYSTEM',
	STAFF = 'STAFF'
}

export enum Category {
	TUITION = 'TUITION',
	REWARD = 'REWARD',
	COMPENSATION = 'COMPENSATION'
}

export enum Method {
	CARD = 'CARD',
	ALIPAY = 'ALIPAY',
	WECHAT_PAY = 'WECHAT_PAY',
	CASH = 'CASH',
	OTHERS = 'OTHERS'
}

export enum Status {
	CREATED = 'CREATED',
	COMPLETED = 'COMPLETED',
	VOIDED = 'VOIDED'
}

export interface Recharge {
	id: string
	status?: Status
	type?: Type
	category?: Category
	method?: Method

	lessons?: number
	currency?: Currency
	paid?: number
	remark?: string

	createdDateTime?: Date
	completedDateTime?: Date
	voidedDateTime?: Date

	parentId?: string
	parentNickname?: string
	parentMobile?: string
	parentEmail?: string

	rechargeCardId?: string
	rechargeCardName?: string

	acquiredCouponId?: string
	couponId?: string
	couponName?: string
	couponValue?: number
}

export class RechargeImpl implements Recharge {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface StaffCheckout {
	category: Category
	parentId: string
	lessons?: number
	currency?: Currency
	paid?: number
	remark?: string
}

export class StaffCheckoutImpl implements StaffCheckout {
	category: Category = Category.TUITION
	parentId: string

	constructor(parentId: string) {
		this.parentId = parentId
	}
}

export interface UpdateRechargeInfo {
	id: string
	remark?: string
}

export class UpdateRechargeInfoImpl implements UpdateRechargeInfo {
	id: string
	remark?: string

	constructor(recharge: Recharge) {
		this.id = recharge.id
		this.remark = recharge.remark
	}
}