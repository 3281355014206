import { Option, OptionImpl } from "."
import { Type } from "../../parent"

export class ParentTypeOptions {
	static options: Option[] = [
		new OptionImpl('Normal', Type.NORMAL),
		new OptionImpl('KOC', Type.KOC),
		new OptionImpl('Agent', Type.AGENT),
	]
}

export class ParentLockedOptions {
	static options: Option[] = [
		new OptionImpl('Locked', true),
		new OptionImpl('Normal', false)
	]
}