<template>
  <el-container class="info">
    <el-aside v-loading="loading" class="aside">
      <it-info-items title="Basic Info">
        <template #actions>
          <el-button text type="primary" class="action-button" @click="showUpdateCouponInfoDialog = true">Edit</el-button>
        </template>
        <it-info-item label="Name">{{ coupon.name }}</it-info-item>
        <it-info-item label="Type">{{ CouponFilter.type(coupon.type) }}</it-info-item>
        <it-info-item label="Recharge Card" v-if="coupon.type === Type.SPECIFIC">{{ coupon.rechargeCardName }}</it-info-item>
        <it-info-item label="Value">{{ NumberFilter.money(coupon.value) }}</it-info-item>
        <it-info-item label="Period (Day)">{{ coupon.period }}</it-info-item>
        <it-info-item label="Status">
          <div>{{ CouponFilter.published(coupon.published) }}</div>
          <el-popconfirm :title="coupon.published? 'Are you sure to unpublish the coupon?' : 'Are you sure to publish the coupon?'" @confirm="doToggleCouponPublished">
            <template #reference>
              <el-button text type="primary" class="action-button">{{ coupon.published? 'Unpublish' : 'Publish' }}</el-button>
            </template>
          </el-popconfirm>
        </it-info-item>
        <it-info-item label="Description">{{ coupon.description }}</it-info-item>
      </it-info-items>
      <el-popconfirm title="Are you sure to delete the coupon?" @confirm="doDeleteCoupon">
        <template #reference>
          <el-button type="danger" style="width: 100%;">Delete</el-button>
        </template>
      </el-popconfirm>
    </el-aside>
    <el-main class="main">
      <el-tabs v-model="tab">
        <el-tab-pane label="Parents" name="parents" lazy>
          <acquired-coupons :coupon-id="couponId"></acquired-coupons>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>

  <update-coupon-info-dialog v-if="showUpdateCouponInfoDialog" v-model:visible="showUpdateCouponInfoDialog" :coupon="coupon" @success="onUpdateCouponInfoSuccess"></update-coupon-info-dialog>
  
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import couponAPI from '../../api/coupon'
import AcquiredCoupons from '../../components/acquiredCoupon/AcquiredCoupons.vue'
import UpdateCouponInfoDialog from '../../components/coupon/UpdateCouponInfoDialog.vue'
import ItInfoItem from '../../components/ui/ItInfoItem.vue'
import ItInfoItems from '../../components/ui/ItInfoItems.vue'
import { CouponFilter } from '../../filters/couponFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { Result } from '../../types/common/result'
import { Coupon, CouponImpl, ToggleCouponPublished, ToggleCouponPublishedImpl, Type } from '../../types/coupon'
import { cloneFrom } from '../../utils/clone'

const route = useRoute()
const couponId = route.params.id as string
const router = useRouter()

const loading = ref<boolean>(false)

const tab = ref<string>('parents')

const coupon = reactive<Coupon>(new CouponImpl(couponId))

const doFindCoupon = function() {
  loading.value = true
  couponAPI.findById(couponId).then((response: AxiosResponse<Result<Coupon>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(coupon, result.data)
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Edit coupon info
const showUpdateCouponInfoDialog = ref<boolean>(false)
const onUpdateCouponInfoSuccess = function () {
  ElMessage.success('Success to edit')
  doFindCoupon()
}

// Lock/Unlock coupon
const doToggleCouponPublished = function () {
  const toggleCouponPublished: ToggleCouponPublished = new ToggleCouponPublishedImpl(couponId)
  loading.value = true
  couponAPI.togglePublished(toggleCouponPublished).then((response: AxiosResponse<Result<Coupon>>) => {
    const result = response.data
    if (result.success) {
      const coupon = result.data
      ElMessage.success(coupon.published? 'Success to publish' : 'Success to unpublish')
      doFindCoupon()
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Delete coupon
const doDeleteCoupon = function () {
  loading.value = true
  couponAPI.deleteById(couponId).then((response: AxiosResponse<Result<void>>) => {
    const result = response.data
    if (result.success) {
      ElMessage.success('Success to delete')
      router.go(-1)
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  doFindCoupon()
})
</script>