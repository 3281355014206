import { Option, OptionImpl } from "."

export class TeacherVendorOptions {
	static options: Option[] = [
		new OptionImpl('Vendor', true),
		new OptionImpl('Common', false)
	]
}

export class TeacherLockedOptions {
	static options: Option[] = [
		new OptionImpl('Locked', true),
		new OptionImpl('Normal', false)
	]
}

export class TeacherCompleteOptions {
	static options: Option[] = [
		new OptionImpl('Complete', true),
		new OptionImpl('Uncomplete', false)
	]
}

export class TeacherQualifiedOptions {
	static options: Option[] = [
		new OptionImpl('Qualified', true),
		new OptionImpl('Unqualified', false)
	]
}