<template>
  <el-container class="info">
    <el-aside v-loading="loading" class="aside">
      <it-info-items title="Basic Info">
        <template #actions>
          <el-button text type="primary" class="action-button" @click="showUpdateAgentDetailInfoDialog = true">Edit</el-button>
        </template>
        <it-info-item label="Name">{{ agent.name }}</it-info-item>
        <it-info-item label="Locale">{{ agent.locale }}</it-info-item>
        <it-info-item label="Time Zone">{{ agent.zoneId }}</it-info-item>
        <it-info-item label="Mobile">{{ agent.fullMobile }}</it-info-item>
        <it-info-item label="Balance">{{ NumberFilter.money(agent.balance) }}</it-info-item>
        <it-info-item label="Total Earning">{{ NumberFilter.money(agent.totalEarning) }}</it-info-item>
        <it-info-item label="Total Withdraw">{{ NumberFilter.money(agent.totalWithdraw) }}</it-info-item>
      </it-info-items>
      <it-info-items title="Other Info">
        <it-info-item label="Parent">{{ agent.parentAgentName }}</it-info-item>
        <it-info-item label="Promote Code">{{ agent.promoteCode }}</it-info-item>
        <it-info-item label="Status">
          <div>{{ AgentFilter.locked(agent.locked) }}</div>
          <el-popconfirm :title="agent.locked? 'Are you sure to unlock the agent?' : 'Are you sure to lock the agent?'" @confirm="doToggleAgentLocked">
            <template #reference>
              <el-button text type="primary" class="action-button">{{ agent.locked? 'Unlock' : 'Lock' }}</el-button>
            </template>
          </el-popconfirm>
        </it-info-item>
        <it-info-item label="Created">{{ DateTimeFilter.datetime(agent.createdDateTime) }}</it-info-item>
        <it-info-item label="Remark">
          <div>{{ agent.remark }}</div>
          <el-button text type="primary" class="action-button" @click="showUpdateAgentRemarkDialog = true">Edit</el-button>
        </it-info-item>
      </it-info-items>
      <el-popconfirm title="Are you sure to delete the agent?" @confirm="doDeleteAgent">
        <template #reference>
          <el-button type="danger" style="width: 100%;">Delete</el-button>
        </template>
      </el-popconfirm>
    </el-aside>
    <el-main class="main">
      <el-tabs v-model="tab">
        <el-tab-pane label="Poster" name="poster" lazy>
          <poster :agent-id="agent.id"></poster>
        </el-tab-pane>
        <el-tab-pane label="Team" name="team" lazy>
          <child-agents :parent-agent-id="agentId"></child-agents>
        </el-tab-pane>
        <el-tab-pane label="Self Recruitments" name="selfRecruitments" lazy>
          <recruitments :agent-id="agentId"></recruitments>
        </el-tab-pane>
        <el-tab-pane label="Team Recruitments" name="teamRecruitments" lazy>
          <recruitments :parent-agent-id="agentId"></recruitments>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>

  <update-agent-detail-info-dialog v-if="showUpdateAgentDetailInfoDialog" v-model:visible="showUpdateAgentDetailInfoDialog" :agent="agent" @success="onUpdateAgentDetailInfoSuccess"></update-agent-detail-info-dialog>
  <update-agent-remark-dialog v-if="showUpdateAgentRemarkDialog" v-model:visible="showUpdateAgentRemarkDialog" :agent="agent" @success="onUpdateAgentRemarkSuccess"></update-agent-remark-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import agentAPI from '../../api/agent'
import ChildAgents from '../../components/agent/ChildAgents.vue'
import Poster from '../../components/agent/Poster.vue'
import UpdateAgentDetailInfoDialog from '../../components/agent/UpdateAgentDetailInfoDialog.vue'
import UpdateAgentRemarkDialog from '../../components/agent/UpdateAgentRemarkDialog.vue'
import Recruitments from '../../components/recruitment/Recruitments.vue'
import ItInfoItem from '../../components/ui/ItInfoItem.vue'
import ItInfoItems from '../../components/ui/ItInfoItems.vue'
import { AgentFilter } from '../../filters/agentFilter'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { Agent, AgentImpl, ToggleAgentLocked, ToggleAgentLockedImpl } from '../../types/agent'
import { Result } from '../../types/common/result'
import { cloneFrom } from '../../utils/clone'

const route = useRoute()
const agentId = route.params.id as string
const router = useRouter()

const loading = ref<boolean>(false)

const tab = ref<string>('poster')

const agent = reactive<Agent>(new AgentImpl(agentId))

const doFindAgent = function() {
  loading.value = true
  agentAPI.findById(agentId).then((response: AxiosResponse<Result<Agent>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(agent, result.data)
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Edit agent info
const showUpdateAgentDetailInfoDialog = ref<boolean>(false)
const onUpdateAgentDetailInfoSuccess = function () {
  ElMessage.success('Success to edit')
  doFindAgent()
}

// Edit agent remark
const showUpdateAgentRemarkDialog = ref<boolean>(false)
const onUpdateAgentRemarkSuccess = function () {
  ElMessage.success('Success to edit remark')
  doFindAgent()
}

// Lock/Unlock agent
const doToggleAgentLocked = function () {
  const toggleAgentLocked: ToggleAgentLocked = new ToggleAgentLockedImpl(agentId)
  loading.value = true
  agentAPI.toggleLocked(toggleAgentLocked).then((response: AxiosResponse<Result<Agent>>) => {
    const result = response.data
    if (result.success) {
      const agent = result.data
      ElMessage.success(agent.locked? 'Success to lock' : 'Success to unlock')
      doFindAgent()
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Delete agent
const doDeleteAgent = function () {
  loading.value = true
  agentAPI.deleteById(agentId).then((response: AxiosResponse<Result<void>>) => {
    const result = response.data
    if (result.success) {
      ElMessage.success('Success to delete')
      router.go(-1)
    } else {
      ElMessage.error(result.message)
    }
  }).catch((error: AxiosError) => {
    ElMessage.error(error.message)
  }).finally(() => {
    loading.value = false
  })
}

const onRechargeDeleted = function () {
  doFindAgent()
}

onMounted(() => {
  doFindAgent()
})
</script>