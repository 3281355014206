import { Type } from "../types/lesson"

export class LessonFilter {
  static type (type?: Type): string {
    switch (type) {
      case Type.LECTURE:
        return 'Lecture'
      case Type.TEST:
        return 'Test'
      default:
        return '' 
    }
  }

  static totalTestScore (totalTestScore?: number): string {
    if (totalTestScore) {
      return totalTestScore == 0? '' : totalTestScore.toString()
    } else {
      return ''
    }
  }
}