import { Status, SuspendReason } from "../../../enrollment"
import { Filters } from "./index"

export interface EnrollmentFilters extends Filters {
	parentId?: string
	studentId?: string
	teacherId?: string
	status?: Status
	suspendReason?: SuspendReason
}

export class EnrollmentFiltersImpl implements EnrollmentFilters {
	parentId?: string
	studentId?: string
	teacherId?: string
	status?: Status
	suspendReason?: SuspendReason

	reset(): void {
		this.parentId = undefined
		this.teacherId = undefined
		this.status = undefined
		this.status = undefined
	}
}