import { Filters } from "./index"

export interface VacationFilters extends Filters {
	teacherId?: string
	date?: Date
}

export class VacationFiltersImpl implements VacationFilters {
	teacherId?: string
	date?: Date

	reset(): void {
		this.teacherId = undefined
		this.date = undefined
	}
}