export class RechargeCardFilter {
  
  static recommended (recommended: boolean | undefined): string {
    if (recommended == undefined) return ''
    else return recommended? 'Recommended' : 'Normal'
  }

  static hot (hot: boolean | undefined): string {
    if (hot == undefined) return ''
    else return hot? 'Hot' : 'Normal'
  }

  static published (published: boolean | undefined): string {
    if (published == undefined) return ''
    else return published? 'Published' : 'Draft'
  }
}