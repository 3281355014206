import { Gender } from "./enums"

export enum Status {
  REGISTERED = 'REGISTERED', // 已注册
  TRIALING = 'TRIALING', // 试听中
  HESITATING = 'HESITATING', // 犹豫中
  UNINTERESTED = 'UNINTERESTED', // 不感兴趣
  LEARNING = 'LEARNING', // 在读中
  QUITED = 'QUITED', // 已退学
  GRADUATED = 'GRADUATED', // 已毕业
}

export interface Student {
  id: string
  status?: Status
  nickname?: string
  name?: string
  gender?: Gender
  birthday?: Date
  parentId?: string
}

export class StudentImpl implements Student {
  id: string

  constructor(id: string) {
    this.id = id
  }
}

export interface NanoStudent {
	id: string
	name: string
	nickname: string
}

export interface CreateStudent {
  parentId: string
  avatar: string
  nickname?: string
  name?: string
  gender?: Gender
  birthday?: Date
}

export class CreateStudentImpl implements CreateStudent {
  parentId: string
  avatar: string
  nickname?: string
  name?: string
  gender?: Gender
  birthday?: Date

  constructor(parentId: string) {
    this.parentId = parentId
    this.avatar = '/images/avatar.jpg'
  }
}

export interface UpdateStudentInfo {
	id: string
  nickname?: string
  name?: string
  gender?: Gender
  birthday?: Date
}

export class UpdateStudentInfoImpl implements UpdateStudentInfo {
	id: string
  nickname?: string
  name?: string
  gender?: Gender
  birthday?: Date

	constructor(student: Student) {
		this.id = student.id
    this.nickname = student.nickname
		this.name = student.name
		this.gender = student.gender
		this.birthday = student.birthday
	}
}

export interface UpdateStudentStatus {
	id: string
  status?: Status 
}

export class UpdateStudentStatusImpl implements UpdateStudentStatus {
	id: string
  status?: Status

	constructor(student: Student) {
		this.id = student.id
    this.status = student.status
	}
}