<template>
  <el-dialog v-model="show" title="Assign Parent Course Service" @opened="onOpened">
    <el-form ref="assignParentCourseServiceForm" :model="assignParentCourseService">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Course Service" prop="courseServiceId">
				<el-select v-model="assignParentCourseService.courseServiceId" filterable clearable style="width: 100%;">
					<el-option v-for="courseService in courseServices" :key="courseService.id" :label="courseService.name" :value="courseService.id"></el-option>
				</el-select>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="show = false">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doAssignParentCourseService">Assign</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import parentAPI from '../../api/parent'
import staffAPI from '../../api/staff'
import { Result } from '../../types/common/result'
import { AssignParentCourseService, AssignParentCourseServiceImpl, Parent } from '../../types/parent'
import { NanoStaff, Role } from '../../types/staff'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	parent: Parent
}>()

const emit = defineEmits<{
	(e: 'assign:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

// Find Course Service
const courseServices = ref<NanoStaff[]>()
const doFindAllCourseService = function () {
	loading.value = true
	staffAPI.findByRole(Role.CS).then((response: AxiosResponse<Result<NanoStaff[]>>) => {
		const result = response.data
		if (result.success) {
			courseServices.value = result.data
		} else {
			message.value = result.message
		}						
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

let assignParentCourseService = reactive<AssignParentCourseService>(new AssignParentCourseServiceImpl(props.parent))

const assignParentCourseServiceForm = ref<InstanceType<typeof ElForm>>()

const doAssignParentCourseService = function () {
	assignParentCourseServiceForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true
			parentAPI.assignCourseService(assignParentCourseService).then((response: AxiosResponse<Result<Parent>>) => {
				const result: Result<Parent> = response.data
				if (result.success) {
					emit('success')
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const onOpened = function () {
	doFindAllCourseService()
}
</script>