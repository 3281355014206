import { Option, OptionImpl } from "."
import { toFirstUpperCase } from "../../../utils/stringUtils"
import { Status, CancelReason } from "../../arrangement"

export class ArrangementStatusOptions {
	static options: Option[] = [
		new OptionImpl(toFirstUpperCase(Status.BOOKED), Status.BOOKED),
		new OptionImpl(toFirstUpperCase(Status.FINISHED), Status.FINISHED),
		new OptionImpl(toFirstUpperCase(Status.CANCELED), Status.CANCELED)
	]
}

export class ArrangementCancelReasonOptions {
	static options: Option[] = [
		new OptionImpl("Student Leave", CancelReason.LEAVE_STUDENT),
		new OptionImpl("Teacher Leave", CancelReason.LEAVE_TEACHER),
		new OptionImpl("Student Absent", CancelReason.ABSENT_STUDENT),
		new OptionImpl("Teacher Absent", CancelReason.ABSENT_TEACHER),
		new OptionImpl("Others", CancelReason.OTHERS)
	]
}

export class ArrangementTrialOptions {
	static options: Option[] = [
		new OptionImpl('Trial', true),
		new OptionImpl('Formal', false)
	]
}