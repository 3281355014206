<template>
  <el-date-picker v-model="date" :clearable="false" type="date" style="width: 150px;"></el-date-picker>
	<el-time-select v-model="time" :clearable="false" start="00:00" step="00:30" end="23:30" format="HH:mm" style="width: 140px; margin-left: 10px;"></el-time-select>
</template>

<script setup lang="ts">
import { DateTime } from "luxon";
import { computed } from 'vue';
import { toHourMinuteString, toHourNumber, toMinuteNumber } from '../../utils/dateTimeUtils';
import { useModelWrapper } from '../../utils/modelWrapper';

const props = defineProps<{
  datetime: DateTime
}>()

const emit = defineEmits<{
  (e: 'update:datetime'): void
}>()

const dateTime = useModelWrapper(props, emit, 'datetime')

const date = computed({
  get: () => dateTime.value.toJSDate(),
  set: (date: Date) => {
    const hour = dateTime.value.hour
    dateTime.value = DateTime.fromJSDate(date).set({hour: hour, minute: 0, second: 0, millisecond: 0})
  } 
})

const time = computed({
  get: () => toHourMinuteString(dateTime.value.hour, dateTime.value.minute),
  set: (text: string) => dateTime.value = dateTime.value.set({hour: toHourNumber(text), minute: toMinuteNumber(text), second: 0, millisecond: 0})
})
</script>
