<template>
  <div class="content">
		<div class="actionbar">
			<div>Coupon List</div>
			<el-button-group>
				<el-button text type="primary" @click="showCreateCouponDialog = true">Create Coupon</el-button>
			</el-button-group>
		</div>

    <el-divider />

		<div class="query">
			<!-- filter -->
			<el-form :inline="true" :model="filters">
				<el-form-item>
					<el-select v-model="filters.type" placeholder="Type">
						<el-option v-for="option in CouponTypeOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="filters.published" placeholder="Status">
						<el-option v-for="option in CouponPublishedOptions.options" :key="option.label" :label="option.label" :value="option.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="doFilter">Filter</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
			<!-- search -->
			<el-form :inline="true" :model="searcher">
				<el-form-item>
					<el-input v-model="searcher.keyword" class="input-with-select">
						<template #prepend>
							<el-select v-model="searcher.field" placeholder="Field" style="width: 120px;">
								<el-option label="Name" value="name"></el-option>
							</el-select>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item style="margin-right: 0;">
					<el-button type="primary" @click="doSearch">Search</el-button>
					<el-button @click="doClean">Clean</el-button>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="coupons" v-loading="loading"  border class="table" row-key="id">
			<el-table-column label="#" type="index" align="center" width="40" />
			<el-table-column label="Name" prop="name" width="200" />
			<el-table-column label="Type" prop="type" width="120">
				<template #default="scope">{{CouponFilter.type(scope.row.type)}}</template>
			</el-table-column>
			<el-table-column label="Recharge Card" prop="rechargeCardName" width="120" />
			<el-table-column label="Value ($)" prop="value" width="120">
				<template #default="scope">{{NumberFilter.money(scope.row.value)}}</template>
			</el-table-column>
			<el-table-column label="Period (Day)" prop="period" width="120" />
			<el-table-column label="Status" prop="published" width="120">
				<template #default="scope">{{CouponFilter.published(scope.row.published)}}</template>
			</el-table-column> 
			<el-table-column label="Description" prop="description" />
			<el-table-column label="Actions" fixed="right" width="90">
				<template #default="scope">
					<el-button-group>
						<el-button text type="primary" @click="doViewCoupon(scope.row.id)">View</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="pagination" background :page-size="pagination.pageSize" :total="pagination.total" v-model:current-page="pagination.currentPage" layout="total, prev, pager, next" />
  
		<create-coupon-dialog v-if="showCreateCouponDialog" v-model:visible="showCreateCouponDialog" @success="onCreateCouponSuccess"></create-coupon-dialog>
	</div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import couponAPI from '../../api/coupon'
import CreateCouponDialog from '../../components/coupon/CreateCouponDialog.vue'
import { CouponFilter } from '../../filters/couponFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { CouponPublishedOptions, CouponTypeOptions } from '../../types/common/option/couponOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { CouponFilters, CouponFiltersImpl } from '../../types/common/query/filters/couponFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { CouponSearcherImpl } from '../../types/common/query/searcher/couponSearcher'
import { Searcher } from '../../types/common/query/searcher/index'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Coupon } from '../../types/coupon'

const loading = ref<boolean>(false)
const router = useRouter()

const coupons = ref<Coupon[]>()

onMounted(() => {
	doListCoupon()
})

// Query coupon
const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<CouponFilters>(new CouponFiltersImpl())
const searcher = reactive<Searcher>(new CouponSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const doListCoupon = function () {
	const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
	loading.value = true
	couponAPI.list(query).then((response: AxiosResponse<Result<ListObject<Coupon>>>) => {
		const result = response.data
		if (result.success) {
			const listObject = result.data
			coupons.value = listObject.objects
			pagination.total = listObject.total
		} else {
			ElMessage.error(result.message)
		}
	}).catch((error: AxiosError) => {
		ElMessage.error(error.message)
	}).finally(() => {
		loading.value = false
	})
}

watch(() => pagination.currentPage, () => {
	doListCoupon()
})

const doFilter = function () {
	pagination.reset()
	searcher.reset()

	doListCoupon()
}

const doSearch = function () {
	pagination.reset()
	filters.reset()

	doListCoupon()
}

const doClean = function () {
	pagination.reset()
	filters.reset()
	searcher.reset()

	doListCoupon()
}

// Create coupon
const showCreateCouponDialog = ref<boolean>(false)
const onCreateCouponSuccess = function () {
	ElMessage.success('Success to create')
	doListCoupon()
}

// View coupon
const doViewCoupon = function(id: string) {
	router.push({name: 'coupon', params: {id: id}})
}
</script>