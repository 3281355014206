import { Option, OptionImpl } from "."
import { Role } from "../../staff"

export class StaffLockedOptions {
	static options: Option[] = [
		new OptionImpl('Locked', true),
		new OptionImpl('Normal', false)
	]
}

export class StaffRoleOptions {
	static options: Option[] = [
		new OptionImpl('Admin', Role.ADMIN),
		new OptionImpl('CXO', Role.CXO),
		new OptionImpl('CS', Role.CS)
	]
}