export enum Type {
	VIP = 'VIP',
	GROUP = 'GROUP'
}

export interface LocaleIntroduction {
	locale: string
	introduction: string
}

export interface LocaleCourse {
	id: string
	localeIntroductions?: [LocaleIntroduction]
}

export class LocaleCourseImpl implements LocaleCourse {
	id: string

	constructor(id: string) {
		this.id = id
	}

}

export interface Course {
	id: string
	name?: string
	type?: Type
	cover?: string
	guide?: string
	introduction?: string
	lessonDuration?: number
	lessonCount?: number
	ready?: boolean
	serieId?: string
	serieName?: string
	hidden?: boolean
}

export class CourseImpl implements Course {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface SimpleCourse {
	id: string
	name?: string
	type?: Type
	cover?: string
	lessonDuration?: number
	lessonCount?: number
	serieId?: string
	serieName?: string
}

export interface CreateCourse {
	name?: string
	type?: Type
	cover?: string
	lessonDuration?: number
	serieId?: string
}

export class CreateCourseImpl implements CreateCourse {
	type?: Type
	lessonDuration?: number

	constructor() {
		this.type = Type.VIP
		this.lessonDuration = 30
	}
}

export interface UpdateCourseInfo {
	id: string
	name?: string
	type?: Type
	lessonDuration?: number
	serieId?: string
}

export class UpdateCourseInfoImpl implements UpdateCourseInfo {
	id: string
	name?: string
	type?: Type
	lessonDuration?: number
	serieId?: string

	constructor(course: Course) {
		this.id = course.id
		this.name = course.name
		this.type = course.type
		this.lessonDuration = course.lessonDuration
		this.serieId = course.serieId
	}
}

export interface CreateCourseLocaleIntroduction {
	id: string
	locale?: string
	introduction?: string

	reset(): void
}

export class CreateCourseLocaleIntroductionImpl implements CreateCourseLocaleIntroduction {
	id: string
	locale?: string
	introduction?: string

	constructor(id: string) {
		this.id = id
	}

	reset() {
		this.locale = undefined
		this.introduction = undefined
	}
}

export interface UpdateCourseLocaleIntroduction {
	id: string
	locale?: string
	introduction?: string
}

export class UpdateCourseLocaleIntroductionImpl implements UpdateCourseLocaleIntroduction {
	id: string
	locale?: string
	introduction?: string

	constructor(id: string) {
		this.id = id
	}
}

export interface DeleteCourseLocaleIntroduction {
	id: string
}

export class DeleteCourseLocaleIntroductionImpl implements DeleteCourseLocaleIntroduction {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface UpdateCourseCover {
	id: string
	cover?: string
}

export class UpdateCourseCoverImpl implements UpdateCourseCover {
	id: string
	cover?: string

	constructor(course: Course) {
		this.id = course.id
		this.cover = course.cover
	}
}

export interface UpdateCourseGuide {
	id: string
	guide?: string
}

export class UpdateCourseGuideImpl implements UpdateCourseGuide {
	id: string
	guide?: string

	constructor(course: Course) {
		this.id = course.id
		this.guide = course.guide
	}
}

export interface ToggleCourseReady {
	id: string
}

export class ToggleCourseReadyImpl implements ToggleCourseReady {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface ToggleCourseHidden {
	id: string
}

export class ToggleCourseHiddenImpl implements ToggleCourseHidden {
	id: string

	constructor(id: string) {
		this.id = id
	}
}