<template>
  <el-dialog v-model="show" title="Create Course Locale Introduction" @open="onOpen" @close="onClose">
    <el-form ref="createCourseLocaleIntroductionForm" :model="createCourseLocaleIntroduction" label-width="100px">
			<el-alert type="error" class="form-message" v-if="message" :title="message" @close="message = ''"></el-alert>
			<el-form-item label="Locale" prop="locale" required>
				<el-select v-model="createCourseLocaleIntroduction.locale" filterable style="width: 100%;">
					<el-option v-for="(option, index) in LocaleOptions.options" :key="index" :label="option.label" :value="option.value"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="Introduction" prop="introduction">
				<div style="width: 100%; height: 300px; line-height: normal;">
					<quill-editor v-model:content="createCourseLocaleIntroduction.introduction" theme="snow" contentType="html"></quill-editor>
				</div>
			</el-form-item>
    </el-form>

    <template #footer>
			<span class="dialog-footer">
				<el-button @click="doCancelCreateCourseLocaleIntroduction">Cancel</el-button>
				<el-button type="primary" :loading="loading" @click="doCreateCourseLocaleIntroduction">Create</el-button>
			</span>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { ElForm, ElMessage } from 'element-plus'
import { reactive, ref } from 'vue'
import courseAPI from '../../api/course'
import { LocaleOptions } from '../../types/common/option/localeOptions'
import { Result } from '../../types/common/result'
import { Course, CreateCourseLocaleIntroduction, CreateCourseLocaleIntroductionImpl } from '../../types/course'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  visible: boolean,
	courseId: string
}>()

const emit = defineEmits<{
	(e: 'update:visible'): void,
  (e: 'success'): void
}>()

const show = useModelWrapper(props, emit, 'visible')

const loading = ref<boolean>(false)
const message = ref<string>('')

const createCourseLocaleIntroduction = reactive<CreateCourseLocaleIntroduction>(new CreateCourseLocaleIntroductionImpl(props.courseId))
const createCourseLocaleIntroductionForm = ref<InstanceType<typeof ElForm>>()

const doCreateCourseLocaleIntroduction = function () {
	createCourseLocaleIntroductionForm.value?.validate((valid: any) => {
		if (valid) {
			loading.value = true

			courseAPI.createLocaleIntroduction(createCourseLocaleIntroduction).then((response: AxiosResponse<Result<Course>>) => {
				const result = response.data
				if (result.success) {
					emit('success')
					createCourseLocaleIntroductionForm.value?.resetFields()
					show.value = false
				} else {
					message.value = result.message
				}						
			}).catch((error: AxiosError) => {
				ElMessage.error(error.message)
			}).finally(() => {
				loading.value = false
			})
		}	else {
			return false
		}
	})
}

const doCancelCreateCourseLocaleIntroduction = function () {
	createCourseLocaleIntroductionForm.value?.resetFields()
	show.value = false
}

const onOpen = function () {
	createCourseLocaleIntroduction.reset()
}

const onClose = function () {
	createCourseLocaleIntroductionForm.value?.resetFields()
}
</script>